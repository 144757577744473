import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createAlert } from '../../store/alertSlice';
import { useFormik } from 'formik';
import { showLoader, hideLoader } from '../../store/loaderSlice';
import { ERROR_500 } from '../../utils/errors';
import * as yup from 'yup';
import { Avatar } from '@material-ui/core';
import SignUpIcon from '../../../assets/images/header-signup.png';
import { useDispatch } from 'react-redux';
import Loader from '../Loader';
import { apiRequest } from '../../utils/general';

const SignupForm = (props) => {
  useEffect(() => {
    dispatch(showLoader());
  }, []);
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
    },
    validationSchema: yup.object({
      first_name: yup.string('Enter your first name').required('First Name is required'),
      last_name: yup.string('Enter your last name').required('Last Name is required'),
      email: yup.string('Enter your email').email('Enter a valid email').required('Email is required'),
    }),
    onSubmit: (values) => {
      handleSignup(values);
    },
  });

  const handleSignup = (data) => {
    dispatch(showLoader());
    apiRequest('coming-soon/sign-up', 'post', data)
      .then((user) => {
        dispatch(hideLoader());
        dispatch(createAlert({ message: 'Registered successfully!!', type: 'success' }));
        formik.resetForm();
      })
      .catch((e) => {
        dispatch(hideLoader());
        if (e.response.status == 422) {
          dispatch(createAlert({ message: 'Email already taken!!', type: 'error' }));
        } else {
          dispatch(createAlert({ message: ERROR_500, type: 'error' }));
        }
      });
  };

  return (
    <>
      <Loader />
      <Typography variant='h1' component='h1' className='text-white text-center shadow-text'>
        Sign up for Updates
      </Typography>
      <form noValidate onSubmit={formik.handleSubmit} className='format-errors'>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete='given-name'
              name='first_name'
              required
              fullWidth
              id={(props.idAddon + '_' ?? '') + 'first_name'}
              label='First Name'
              value={formik.values.first_name}
              onChange={formik.handleChange}
              error={formik.touched.first_name && Boolean(formik.errors.first_name)}
              helperText={formik.touched.first_name && formik.errors.first_name}
              variant='outlined'
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete='last-name'
              name='last_name'
              required
              fullWidth
              id={(props.idAddon + '_' ?? '') + 'last_name'}
              label='Last Name'
              value={formik.values.last_name}
              onChange={formik.handleChange}
              error={formik.touched.last_name && Boolean(formik.errors.last_name)}
              helperText={formik.touched.last_name && formik.errors.last_name}
            />
          </Grid>
          <Grid item xs={12} sm={8} className='form-right-padding'>
            <TextField
              name='email'
              id={(props.idAddon + '_' ?? '') + 'email'}
              variant='outlined'
              placeholder='Email Address'
              fullWidth
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              label='Email'
              required
            />
          </Grid>
          <Grid item xs={12} sm={4} className='form-left-padding'>
            <Button
              aria-label='Add'
              edge='end'
              variant='contained'
              type='submit'
              className='button-add-cs'
              endIcon={
                <Avatar
                  variant='square'
                  src={SignUpIcon}
                  imgProps={{
                    style: {
                      maxHeight: '50%',
                      maxWidth: '100%',
                      objectFit: 'cover',
                    },
                  }}
                />
              }
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default SignupForm;
