import React from 'react';
import { useInView } from 'react-intersection-observer';
import {
  COWBOY_MOMENTS_SERIES_ID,
  WILD_RIDES_SERIES_ID,
  WINNING_WAYS_SERIES_ID,
  PURE_GRIT_SERIES_ID,
  RODEO_SONS_SERIES_ID,
  RAMBLIN_SERIES_ID,
  FLINT_UNEDITED_SERIES_ID,
  WESTERN_JUSTICE_SERIES_ID,
  CATTLE_INDUSTRY_TODAY_SERIES_ID,
  BREAKING_THE_SOD_SERIES_ID,
  HOW_COUPLES_MET_SERIES_ID,
  THE_REVEAL_SERIES_ID,
  PRESERVING_AMERICA_SERIES_ID,
  FIRST_STEP_SERIES_ID,
} from '../../utils/constants';
import FeaturedSeriesBanner from '../home/FeaturedSeriesBanner';
import Skeleton from '@mui/material/Skeleton';

const OPTIONS = {
  threshold: 0,
  triggerOnce: true,
};

const PlaceHolderBox = () => {
  return (
    <Skeleton
      variation='rectangular'
      className='--no-scaling'
      width='100%'
      height='90vh'
      sx={{ bgcolor: 'grey.900' }}
    />
  );
};

const VideoCardList = (props) => {
  const { comingSoon } = props;
  const [group1, group1Visible] = useInView(OPTIONS);
  const [group2, group2Visible] = useInView(OPTIONS);
  const [group3, group3Visible] = useInView(OPTIONS);
  const [group4, group4Visible] = useInView(OPTIONS);
  const [group5, group5Visible] = useInView(OPTIONS);
  const [group6, group6Visible] = useInView(OPTIONS);

  // The first 2 groups are one card because they both load when group 1 is visible
  // once 2 is visible the next loads, and then the next etc.
  // Each group after 2 should have 3 videos max
  return (
    <>
      <div ref={group1}>
        {group1Visible ? (
          <>
            <FeaturedSeriesBanner
              sideClass='--video-left'
              comingSoon={comingSoon}
              textDescriptionClass='text-white'
              seriesId={WILD_RIDES_SERIES_ID}
            />
          </>
        ) : (
          <PlaceHolderBox />
        )}
      </div>
      <div ref={group2}>
        {group1Visible ? (
          <>
            <FeaturedSeriesBanner
              sideClass='--video-right'
              comingSoon={comingSoon}
              textDescriptionClass='text-white'
              seriesId={PURE_GRIT_SERIES_ID}
            />
          </>
        ) : (
          <PlaceHolderBox />
        )}
      </div>

      <div ref={group3}>
        {group2Visible ? (
          <>
            <FeaturedSeriesBanner
              sideClass='--video-left'
              comingSoon={comingSoon}
              textDescriptionClass='text-white'
              seriesId={RODEO_SONS_SERIES_ID}
            />
            <FeaturedSeriesBanner
              sideClass='--video-right'
              comingSoon={comingSoon}
              textDescriptionClass='text-white'
              seriesId={WINNING_WAYS_SERIES_ID}
            />
            <FeaturedSeriesBanner
              sideClass='--video-left'
              comingSoon={comingSoon}
              textDescriptionClass='text-white'
              seriesId={FLINT_UNEDITED_SERIES_ID}
            />
          </>
        ) : (
          <>
            <PlaceHolderBox />
            <PlaceHolderBox />
            <PlaceHolderBox />
          </>
        )}
      </div>
      <div ref={group4}>
        {group3Visible ? (
          <>
          <FeaturedSeriesBanner
            sideClass='--video-right'
            comingSoon={comingSoon}
            textDescriptionClass='text-white'
            seriesId={COWBOY_MOMENTS_SERIES_ID}
          />
            <FeaturedSeriesBanner
              sideClass='--video-left'
              comingSoon={comingSoon}
              textDescriptionClass='text-white'
              seriesId={FIRST_STEP_SERIES_ID}
            />
            <FeaturedSeriesBanner
              sideClass='--video-right'
              comingSoon={comingSoon}
              textDescriptionClass='text-white'
              seriesId={HOW_COUPLES_MET_SERIES_ID}
            />
          </>
        ) : (
          <>
            <PlaceHolderBox />
            <PlaceHolderBox />
            <PlaceHolderBox />
          </>
        )}
      </div>
      <div ref={group5}>
        {group4Visible ? (
          <>
          <FeaturedSeriesBanner
            sideClass='--video-left'
            comingSoon={comingSoon}
            textDescriptionClass='text-white'
            seriesId={PRESERVING_AMERICA_SERIES_ID}
          />
            <FeaturedSeriesBanner
              sideClass='--video-right'
              comingSoon={comingSoon}
              textDescriptionClass='text-white'
              seriesId={THE_REVEAL_SERIES_ID}
            />
            <FeaturedSeriesBanner
              sideClass='--video-left'
              comingSoon={comingSoon}
              textDescriptionClass='text-white'
              seriesId={CATTLE_INDUSTRY_TODAY_SERIES_ID}
            />
          </>
        ) : (
          <>
            <PlaceHolderBox />
            <PlaceHolderBox />
            <PlaceHolderBox />
          </>
        )}
      </div>
      <div ref={group6}>
        {group5Visible ? (
          <>
          <FeaturedSeriesBanner
            sideClass='--video-right'
            comingSoon={comingSoon}
            textDescriptionClass='text-white'
            seriesId={WESTERN_JUSTICE_SERIES_ID}
          />
          <FeaturedSeriesBanner
            sideClass='--video-left'
            comingSoon={comingSoon}
            textDescriptionClass='text-white'
            seriesId={BREAKING_THE_SOD_SERIES_ID}
          />
          </>
        ) : (
          <>
            <PlaceHolderBox />
            <PlaceHolderBox />
            <PlaceHolderBox />
          </>
        )}
      </div>
    </>
  );
};

export default VideoCardList;
