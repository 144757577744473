import React, { useState, useEffect } from 'react';
import horseImage from '../../../assets/images/gift-background.png';
import { useFormik } from 'formik';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { apiRequest } from '../../utils/general';
import { showLoader, hideLoader } from '../../store/loaderSlice';
import { useDispatch } from 'react-redux';
import { createAlert } from '../../store/alertSlice';
import { makeStyles } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { STRIPE_MONTHLY_GIFT_CARD_ID, STRIPE_YEARLY_GIFT_CARD_ID } from '../../utils/constants';

const useStyles = makeStyles({
  root: {
    color: 'white !important',
    '&.Mui-checked': {
      color: 'orange !important',
    },
  },
});

const GiftPlan = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [planMonthly, setPlanMonthly] = useState({});
  const [planYearly, setPlanYearly] = useState({});
  const [planMonthlyPrice, setPlanMonthlyPrice] = useState([]);
  const [planYearlyPrice, setPlanYearlyPrice] = useState([]);

  useEffect(() => {
    dispatch(showLoader());
    const fetchSubscription = async () => {
      try {
        const subscriptionRes = await apiRequest('subscriptions/plans');
        const [planMonthly, planYearly] = subscriptionRes.data.response;
        formik.setFieldValue('plan_id', `${STRIPE_MONTHLY_GIFT_CARD_ID}`);
        setPlanMonthly(planMonthly);
        setPlanYearly(planYearly);
        setPlanMonthlyPrice(planMonthly.amount.split('.'));
        setPlanYearlyPrice(planYearly.amount.split('.'));
      } catch (err) {
        console.error(err);
        dispatch(createAlert({ message: 'An error occured!', type: 'error' }));
      }
      dispatch(hideLoader());
    };
    fetchSubscription();
  }, []);

  const formik = useFormik({
    initialValues: {
      plan_id: '',
    },
    onSubmit: (values) => {
      props.onPlanSubmit(values.plan_id);
    },
  });

  const handlePlanChange = (event) => {
    formik.setFieldValue('plan_id', event.target.value);
  };

  return (
    <>
      <div className='img-background'>
        <img src={horseImage} alt='horse-sunset' />
        <div className='fade-bottom' />
      </div>
      <div className='gift-info-container'>
        <div className='flex-center'>
          <img className='signup-logo' src={props.logo} alt='signup logo' width='80%' />
        </div>
        <h1>
          Let’s get you started!
          <br />
          Choose which plan you’d like to gift.
        </h1>
        {planMonthlyPrice.length && planYearlyPrice.length && (
          <RadioGroup value={formik.values.plan_id} onChange={handlePlanChange}>
            <div className='price-grid'>
              <div
                className='price-grid__price-flex'
                onClick={() => {
                  formik.setFieldValue('plan_id', STRIPE_MONTHLY_GIFT_CARD_ID);
                }}
              >
                <span className='alignment-span'></span>
                <div>
                  <span>${planMonthlyPrice[0] + '.'}</span>
                  <span>{planMonthlyPrice[1]}</span>
                </div>
                <div>1 {planMonthly.name}</div>
                <FormControlLabel
                  value={STRIPE_MONTHLY_GIFT_CARD_ID}
                  control={
                    <Radio
                      className={classes.root}
                      sx={{
                        color: 'white',
                        '&.Mui-checked': {
                          color: 'orange',
                        },
                      }}
                    />
                  }
                />
              </div>
              <div className='vertical-line-grid'>
                <div>
                  <div className='vertical-line' />
                </div>
                <div className='text-white flex-center'>or</div>
                <div>
                  <div className='vertical-line' />
                </div>
              </div>
              <div
                className='price-grid__price-flex'
                onClick={() => {
                  formik.setFieldValue('plan_id', STRIPE_YEARLY_GIFT_CARD_ID);
                }}
              >
                <span className='price-grid__price-value-text'>BEST VALUE!</span>
                <div>
                  <span>${planYearlyPrice[0] + '.'}</span>
                  <span>{planYearlyPrice[1]}</span>
                </div>
                <div>12 {`${planMonthly.name}s`}</div>
                <FormControlLabel
                  value={STRIPE_YEARLY_GIFT_CARD_ID}
                  control={
                    <Radio
                      className={classes.root}
                      sx={{
                        color: 'white',
                        '&.Mui-checked': {
                          color: 'orange',
                        },
                      }}
                    />
                  }
                />
              </div>
            </div>
          </RadioGroup>
        )}
        <button type='submit' className='wild-rides-button --gift-flow' onClick={formik.handleSubmit}>
          Next
        </button>
        <div className='auth-bottom-text text-white'>
          <a onClick={() => navigate('/gifting-wild-rides')}>Cancel</a>
        </div>
      </div>
    </>
  );
};
export default GiftPlan;
