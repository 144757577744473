import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  subscription_expired: true,
  subscription_expires_date: null,
  payment_failed: false,
  token: null,
  userInitial: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    updateUser: (state, action) => {
      const { payload } = action;
      state.subscription_expired = payload.subscription_expired;
      state.subscription_expires_date = payload.subscription_expires_date;
      state.payment_failed = payload.payment_failed;
      state.token = payload.access_token;
      state.userInitial = payload.initial;
    },
    updateUserSubscription: (state, action) => {
      const { payload } = action;
      state.subscription_expired = payload.subscription_expired;
      state.subscription_expires_date = payload.subscription_expires_date;
    },
    clearUser: (state, action) => {
      state.subscription_expired = true;
      state.subscription_expires_date = null;
      state.payment_failed = false;
      state.token = null;
      state.userInitial = null;
    },
    storeToken: (state, action) => {
      state.token = action.payload;
    },
    clearToken: (state, action) => {
      state.token = null;
    },
  },
});
export const { updateUser, clearUser, storeToken, clearToken, updateUserSubscription } = userSlice.actions;
const { reducer } = userSlice;
export default reducer;
