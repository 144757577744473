import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Typography } from "@material-ui/core";
import { Box } from "@mui/material";

const Loader = () => {
  const { loader } = useSelector((state) => state.loader);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(loader.show);
    setMessage(loader.message);
  }, [loader]);

  return (
    <Backdrop sx={{ color: "#fff", zIndex: 2000 }} open={loading}>
      <Box display="flex" justifyContent="center" alignItems="center">
        <CircularProgress color="inherit" size={100} />
        <Typography className="loading-text">{message}</Typography>
      </Box>
    </Backdrop>
  );
};

export default Loader;
