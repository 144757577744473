import React, { useEffect, useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import { useTheme, useMediaQuery } from '@material-ui/core';
import UserMenu from './UserMenu';
import Skeleton from '@mui/material/Skeleton';
import { apiRequest, checkForToken } from '../../utils/general';
import MobileMenu from './MobileMenu';

export default function Navbar() {
  const currentUser = useSelector((state) => state.user);

  const [logoLoading, setLogoLoading] = useState(true);
  const [logoUrl, setLogoUrl] = useState('');
  const [navbar, setNavbar] = useState(false);
  const [pages, setPages] = useState([]);
  const isLoggedIn = useSelector((state) => !!state.user?.token);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    apiRequest('assets/header-assets')
      .then((res) => {
        setLogoUrl(res.data.logo_url);
        setLogoLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });
    setupNavPages();
  }, []);

  useEffect(() => {
    setupNavPages();
  }, [isLoggedIn, currentUser]);

  const setupNavPages = () => {
    const showPricing =
      !currentUser ||
      currentUser?.subscription_expired ||
      Date.parse(currentUser.subscription_expires_date) - Date.parse(new Date()) < 0;
    const tempPages = [];
    if (isLoggedIn) {
      tempPages.push({
        label: 'Home',
        url: '/browse',
      });
      if (showPricing) {
        tempPages.push({
          label: 'Pricing',
          url: '/pricing',
        });
      }
    } else {
      tempPages.push({
        label: 'Home',
        url: '/',
      });
      tempPages.push({
        label: 'Browse',
        url: '/browse',
      });
      if (showPricing) {
        tempPages.push({
          label: 'Pricing',
          url: '/pricing',
        });
      }
    }
    tempPages.push({
      label: 'Gifting Wild Rides',
      url: '/gifting-wild-rides',
    });
    tempPages.push({
      label: 'Help Center',
      url: 'https://news.wildridestv.com/help-center',
      external: true,
    });

    setPages(tempPages);
  };

  const changeBackground = () => {
    if (window.scrollY > 180) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  window.addEventListener('scroll', changeBackground);

  return (
    <div className={'header-nav background ' + (navbar ? 'active' : '')}>
      <div className=''>
        {isMobile ? (
          <MobileMenu pages={pages} isLoggedIn={isLoggedIn} />
        ) : (
          <AppBar position='fixed'>
            <Toolbar>
              <a href='/' className='header-logo-div'>
                {logoLoading && (
                  <Skeleton sx={{ bgcolor: 'grey.950000' }} variant='rectangular' width={300} height={150} />
                )}
                {!logoLoading && (
                  <img src={logoUrl} alt='Wildrides Logo' className={'nav-logo ' + (navbar ? 'active' : '')} />
                )}
              </a>
              <Box className='header-menu-box'>
                {pages.map((page) => (
                  <Button
                    key={page.label}
                    className={window.location.pathname == page.url ? 'menu-active' : ''}
                    component={page.external ? 'a' : Link}
                    href={page.external && page.url}
                    to={!page.external && page.url}
                    target={page.external && '_blank'}
                    sx={{ my: 2, display: 'block' }}
                  >
                    <span className='text-shadow'>{page.label}</span>
                  </Button>
                ))}
              </Box>

              <Box className='header-right-div'>
                {!isLoggedIn && (
                  <Button className='header-login-button text-shadow' component={Link} to='/sign-in'>
                    Login
                  </Button>
                )}
                {isLoggedIn && <UserMenu />}
              </Box>
            </Toolbar>
          </AppBar>
        )}
      </div>
    </div>
  );
}
