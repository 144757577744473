import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useStripe, useElements, CardNumberElement, CardCvcElement, CardExpiryElement } from '@stripe/react-stripe-js';
import { useFormik } from 'formik';
import SignupPaymentRules from '../validation/SignupPaymentRules';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import { useParams } from 'react-router-dom';
import { apiRequest } from '../../utils/general';
import { showLoader, hideLoader } from '../../store/loaderSlice';
import { useDispatch, useSelector } from 'react-redux';
import { createAlert } from '../../store/alertSlice';
import { ERROR_500 } from '../../utils/errors';
import Alert from '../Alert';
import Loader from '../Loader';
import { makeStyles, Avatar } from '@material-ui/core';
import VideoBackground from '../common/VideoBackground';
import { updateUserSubscription } from '../../store/userSlice';
import SignUpIcon from '../../../assets/images/header-signup.png';

const useStyles = makeStyles({
  root: {
    color: 'white !important',
    '&.Mui-checked': {
      color: 'orange !important',
    },
  },
});
const cardStyle = {
  style: {
    base: {
      color: 'black',
      fontFamily: 'Arial, sans-serif',
      fontSmoothing: 'antialiased',
      '::placeholder': {
        color: '#969696',
      },
    },
    invalid: {
      fontFamily: 'Arial, sans-serif',
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
};

export default function SignUpPayment() {
  const classes = useStyles();
  const [logoUrl, setLogoUrl] = useState('');
  const [backgroundVideoUrl, setBackgroundVideoUrl] = useState('');
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const [planMonthly, setPlanMonthly] = useState({});
  const [planYearly, setPlanYearly] = useState({});
  const [planMonthlyPrice, setPlanMonthlyPrice] = useState([]);
  const [planYearlyPrice, setPlanYearlyPrice] = useState([]);
  const [videoDetail, setVideoDetail] = useState({});
  const [priceWidth, setPriceWidth] = useState(4);
  const [signupText, setSignupText] = useState("We're glad you've decided to join us!");
  const [buttonText, setButtonText] = useState('Subscribe');
  const [couponCode, setCouponCode] = useState(null);
  const [couponDuration, setCouponDuration] = useState('');
  const [couponError, setCouponError] = useState(null);
  const [couponApplied, setCouponApplied] = useState(false);
  const [cardNumberError, setCardNumberError] = useState(null);
  const [cardExpiryError, setCardExpiryError] = useState(null);
  const [cardCvcError, setCardCvcError] = useState(null);
  const [cardNumberIsEmpty, setCardNumberIsEmpty] = useState(true);
  const [cardExpiryIsEmpty, setCardExpiryIsEmpty] = useState(true);
  const [cardCvcIsEmpty, setCardCvcIsEmpty] = useState(true);
  const params = useParams();
  const videoId = params.video_id;
  const currentUser = useSelector((state) => state.user);

  const [referral, setReferral] = useState(null);

  useEffect(() => {
    dispatch(showLoader());
    window.addEventListener('Rewardful.initialized', function () {
      setReferral(window.Rewardful.referral);
    });
    console.log('referral', window.Rewardful?.referral);
    const fetchSubscription = async () => {
      try {
        const allPromises = [];
        allPromises.push(apiRequest('subscriptions/plans'));
        allPromises.push(apiRequest('users/sign-up-background')); // I must wonder. Why not just put a url?
        allPromises.push(apiRequest('users/sign-up-logo'));
        if (videoId) {
          allPromises.push(apiRequest(`videos/${videoId}`));
        }
        const responses = await Promise.all(allPromises);
        const subscriptionRes = responses[0];
        const backgroundVideoRes = responses[1];
        const signupLogoRes = responses[2];
        const [planMonthly, planYearly] = subscriptionRes.data.response;
        formik.setFieldValue('plan_id', planMonthly._id);
        setBackgroundVideoUrl(backgroundVideoRes.data.video_url);
        setLogoUrl(signupLogoRes.data.logo_url);
        setPlanMonthly(planMonthly);
        setPlanYearly(planYearly);
        setPlanMonthlyPrice(planMonthly.amount.split('.'));
        setPlanYearlyPrice(planYearly.amount.split('.'));
        if (videoId) {
          const videoRes = responses[3];
          const video = videoRes.data.video;
          setVideoDetail(video);
          if (video.price > 0) {
            setPriceWidth(3);
            setSignupText('Subscribe and unlock all episodes.');
          }
        }
      } catch (err) {
        console.error(err);
        dispatch(createAlert({ message: 'An error occured!', type: 'error' }));
      }
      dispatch(hideLoader());
    };
    fetchSubscription();
  }, []);

  const formik = useFormik({
    initialValues: {
      name: '',
      plan_id: '',
      coupon_code: '',
    },
    validationSchema: SignupPaymentRules,
    onSubmit: (values) => {
      if (formik.values.coupon_code && !couponApplied) {
        setCouponError('Please click Apply Discount');
        return;
      }
      dispatch(showLoader('Processing..'));
      getToken().then((response) => {
        dispatch(hideLoader());

        if (response.status) {
          const data = {
            ...values,
            stripe_token: response.token,
            user_token: currentUser.token,
          };
          if (couponCode) {
            data.coupon_code = couponCode;
          } else {
            delete data.coupon_code;
          }
          payment(data);
        }
      });
    },
  });

  const handlePlanChange = (event) => {
    formik.setFieldValue('plan_id', event.target.value);
    event.target.value == videoDetail.id ? setButtonText('Buy Episode') : setButtonText('Subscribe');
  };

  const getToken = async () => {
    if (!stripe || !elements) {
      return;
    }
    const card = elements.getElement(CardNumberElement);
    const result = await stripe.createToken(card, { name: formik.values.name });
    if (result.error) {
      return { status: false, error: result.error.message };
    } else {
      return { status: true, token: result.token.id };
    }
  };

  const handleBuyEpisode = (data) => {
    apiRequest(`users/videos/${videoId}/subscribe`, 'post', data)
      .then((res) => {
        dispatch(hideLoader());
        dispatch(createAlert({ message: res.data.message, type: 'success' }));
        window.location.replace(`/sign-in`);
      })
      .catch((err) => {
        console.error(err.response);
        dispatch(hideLoader());
        if (err.response?.status && [422, 401, 404].includes(err.response.status)) {
          dispatch(createAlert({ message: err.response.data.error, type: 'error' }));
        } else {
          dispatch(createAlert({ message: ERROR_500, type: 'error' }));
        }
      });
  };

  const handleSubscription = (data) => {
    if (data.plan_id === planMonthly._id) {
      data.plan_display = '$' + Number.parseFloat(planMonthly.amount).toFixed(2) + ' per month';
    } else if (data.plan_id === planYearly._id) {
      data.plan_display = '$' + Number.parseFloat(planYearly.amount).toFixed(2) + ' per year';
    } else {
      data.plan_display = '';
    }

    data.referral = referral ?? window?.Rewardful?.referral ?? 'none';
    console.log(data.referral);

    apiRequest('subscriptions/payment', 'post', data)
      .then((res) => {
        dispatch(hideLoader());
        dispatch(createAlert({ message: 'Signed up successfully!!', type: 'success' }));
        const dataResults = res.data;
        // TODO: do this better later we really need a bog standard "Check user status" - GL
        const { subscription_expired, subscription_expires_date } = dataResults;
        dispatch(updateUserSubscription({ subscription_expired, subscription_expires_date }));
        window.location.replace(`/browse`);
      })
      .catch((err) => {
        console.error(err);
        dispatch(hideLoader());
        if (err.response?.status && [422, 401, 404].includes(err.response.status)) {
          dispatch(createAlert({ message: err.response.data.error, type: 'error' }));
        } else {
          dispatch(createAlert({ message: ERROR_500, type: 'error' }));
        }
      });
  };

  const payment = async (data) => {
    dispatch(showLoader('Processing...'));
    if (formik.values.plan_id == videoDetail.id) {
      handleBuyEpisode(data);
    } else {
      handleSubscription(data);
    }
  };

  const checkForm = (event) => {
    if (!cardNumberError) {
      cardNumberIsEmpty ? setCardNumberError('Your card number is incomplete.') : setCardNumberError(false);
    }
    if (!cardExpiryError) {
      cardExpiryIsEmpty ? setCardExpiryError(`Your card's expiration date is incomplete.`) : setCardExpiryError(false);
    }
    if (!cardCvcError) {
      cardCvcIsEmpty ? setCardCvcError(`Your card's security code is incomplete.`) : setCardCvcError(false);
    }

    formik.handleSubmit(event);
  };

  const handleCardExpiryChange = (e) => {
    e.error ? setCardExpiryError(e.error.message) : setCardExpiryError(null);
    setCardExpiryIsEmpty(e.empty);
  };

  const handleCardCvcChange = (e) => {
    e.error ? setCardCvcError(e.error.message) : setCardCvcError(null);
    setCardCvcIsEmpty(e.empty);
  };

  const handleCardNumberChange = (e) => {
    e.error ? setCardNumberError(e.error.message) : setCardNumberError(null);
    setCardNumberIsEmpty(e.empty);
  };

  const handleCouponDuration = (stripeAmount) => {
    const amount = `${stripeAmount.toString().slice(0, -2)}.${stripeAmount.toString().slice(-2)}`;
    const priceMonthly = planMonthlyPrice.join('.');
    const priceYearly = planYearlyPrice.join('.');
    if (amount === priceMonthly) {
      setCouponDuration('Monthly');
      dispatch(createAlert({ message: 'Monthly Coupon Applied', type: 'success' }));

      formik.setFieldValue('plan_id', planMonthly._id);
    } else if (amount === priceYearly) {
      setCouponDuration('Yearly');
      dispatch(createAlert({ message: 'Yearly Coupon Applied', type: 'success' }));
      formik.setFieldValue('plan_id', planYearly._id);
    }
  };

  const handleCouponCodeSubmit = async () => {
    try {
      setCouponApplied(true);
      const couponRes = await apiRequest('/subscriptions/coupon-code', 'post', {
        coupon_code: formik.values.coupon_code.trim(),
      });
      const { coupon_info } = couponRes.data;
      if (coupon_info.valid) {
        handleCouponDuration(coupon_info.amount_off);
        setCouponCode(coupon_info.id);
        formik.setFieldValue('coupon_code', '');
      } else {
        setCouponError('This coupon is not valid');
      }
    } catch (err) {
      const {
        response: { status },
      } = err;
      if (status == 401) {
        setCouponError('Your account is not authorized');
      } else if (status == 404) {
        setCouponError('Coupon code not found');
      } else if (status == 422) {
        setCouponError('Coupon code has already been used or is unavailable');
      } else {
        setCouponError('Something went wrong');
      }
    }
  };

  return (
    <>
      <Alert />
      <Loader />
      <VideoBackground videoSrc={backgroundVideoUrl} />
      <div className='info-form signup-main-container'>
        <Container component='main' maxWidth={videoDetail.price > 0 ? 'md' : 'sm'} className='signup-inner-container'>
          <Box className='text-center' sx={{ mb: 3 }}>
            <img className='signup-logo' src={logoUrl} alt='signup logo' width='80%' />
            <Typography component='h1' variant='h5' className='text-white text-center shadow-text'>
              {signupText}
            </Typography>
            <form noValidate onSubmit={checkForm} className='format-errors' data-rewardful='true'>
              {planMonthly && (
                <RadioGroup value={formik.values.plan_id} onChange={handlePlanChange}>
                  <div className='price-grid center-error'>
                    {videoDetail.id && videoDetail.price > 0 && (
                      <>
                        <div className='price-grid'>
                          <div
                            className={`price-grid__price-flex ${
                              couponCode && formik.values.plan_id === planYearly._id && '--disabled-shadow'
                            }`}
                          >
                            <div>$ {videoDetail.price}</div>
                            <div>This episode</div>
                            <FormControlLabel value={videoDetail.id} control={<Radio className={classes.root} />} />
                          </div>
                        </div>

                        <Grid item xs={1} alignContent='center'>
                          <div className='vertical-line'></div>
                        </Grid>
                      </>
                    )}
                    {planMonthlyPrice.length && planYearlyPrice.length && (
                      <>
                        {' '}
                        <div
                          className={`price-grid__price-flex ${
                            couponCode && formik.values.plan_id === planYearly._id && '--disabled-shadow'
                          }`}
                          onClick={() => {
                            if (!couponCode) {
                              formik.setFieldValue('plan_id', planMonthly._id);
                            }
                          }}
                        >
                          <span className='alignment-span'></span>
                          <div>
                            <span>${planMonthlyPrice[0] + '.'}</span>
                            <span>{planMonthlyPrice[1]}</span>
                          </div>
                          <div>Per {planMonthly.name}</div>
                          <FormControlLabel
                            value={planMonthly._id}
                            control={
                              <Radio
                                disabled={couponDuration === 'Yearly'}
                                className={classes.root}
                                sx={{
                                  color: 'white',
                                  '&.Mui-checked': {
                                    color: 'orange',
                                  },
                                }}
                              />
                            }
                          />
                        </div>
                        <div className='vertical-line-grid'>
                          <div>
                            <div className='vertical-line' />
                          </div>
                          <div className='text-white flex-center'>or</div>
                          <div>
                            <div className='vertical-line' />
                          </div>
                        </div>
                        <div
                          className={`price-grid__price-flex ${
                            couponCode && formik.values.plan_id === planMonthly._id && '--disabled-shadow'
                          }`}
                          onClick={() => {
                            if (!couponCode) {
                              formik.setFieldValue('plan_id', planYearly._id);
                            }
                          }}
                        >
                          <span className='price-grid__price-value-text'>BEST VALUE!</span>
                          <div>
                            <span>${planYearlyPrice[0] + '.'}</span>
                            <span>{planYearlyPrice[1]}</span>
                          </div>
                          <div>Per {planYearly.name}</div>
                          <FormControlLabel
                            value={planYearly._id}
                            control={
                              <Radio
                                disabled={couponDuration === 'Monthly'}
                                className={classes.root}
                                sx={{
                                  color: 'white',
                                  '&.Mui-checked': {
                                    color: 'orange',
                                  },
                                }}
                              />
                            }
                          />
                        </div>
                      </>
                    )}
                    {formik.errors.plan_id && formik.touched.plan_id && (
                      <div className='price-grid__price-flex --justify'>
                        <FormHelperText error>
                          {formik.errors.plan_id && formik.touched.plan_id ? formik.errors.plan_id : ' '}
                        </FormHelperText>
                      </div>
                    )}
                  </div>
                </RadioGroup>
              )}
              <Grid container spacing={2}>
                <div className='coupon-code'>
                  <TextField
                    disabled={couponCode}
                    name='coupon_code'
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    id='coupon_code'
                    label='Coupon Code'
                    value={formik.values.coupon_code}
                    onChange={(e) => {
                      formik.handleChange(e);
                      setCouponError(null);
                      setCouponApplied(false);
                    }}
                    error={couponError}
                    helperText={couponError}
                    autoFocus
                    variant='outlined'
                    placeholder={couponCode ? `${couponDuration} Coupon Applied` : 'XXXXXXXX'}
                  />
                  <Button
                    aria-label='Add'
                    edge='end'
                    variant='outlined'
                    onClick={handleCouponCodeSubmit}
                    className={`button-add text-white ${couponCode ? 'hidden-button' : ''}`}
                    endIcon={
                      <Avatar
                        variant='square'
                        src={SignUpIcon}
                        imgProps={{
                          style: {
                            maxHeight: '50%',
                            maxWidth: '100%',
                            objectFit: 'cover',
                          },
                        }}
                      />
                    }
                  >
                    Apply Discount
                  </Button>
                </div>
                <Grid item xs={12}>
                  <TextField
                    autoComplete='given-name'
                    name='name'
                    InputLabelProps={{ shrink: true }}
                    required
                    fullWidth
                    id='name'
                    label='Name'
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                    autoFocus
                    variant='outlined'
                    placeholder="Cardholder's Name (as it appears on credit card)"
                  />
                </Grid>
                <Grid item xs={12} className='position-relative'>
                  <div className='card-element-subtext'>Credit Card Number *</div>
                  <CardNumberElement
                    options={cardStyle}
                    className={`card-element ${
                      cardNumberError ? 'StripeElement StripeElement--empty --error' : 'StripeElement StripeElement'
                    }`}
                    onChange={handleCardNumberChange}
                  />
                  {cardNumberError && <div className='error-formatting'>{cardNumberError}</div>}
                </Grid>
                <Grid item xs={6} className='position-relative'>
                  <div className='card-element-subtext'>Expiration Date *</div>
                  <CardExpiryElement
                    options={cardStyle}
                    className={`card-element ${
                      cardExpiryError ? 'StripeElement StripeElement--empty --error' : 'StripeElement StripeElement'
                    }`}
                    onChange={handleCardExpiryChange}
                  />
                  {cardExpiryError && <div className='error-formatting'>{cardExpiryError}</div>}
                </Grid>
                <Grid item xs={6} className='position-relative'>
                  <div className='card-element-subtext'>CVC *</div>
                  <CardCvcElement
                    options={cardStyle}
                    className={`card-element ${
                      cardCvcError ? 'StripeElement StripeElement--empty --error' : 'StripeElement StripeElement'
                    }`}
                    onChange={handleCardCvcChange}
                  />
                  {cardCvcError && <div className='error-formatting'>{cardCvcError}</div>}
                </Grid>
                <Grid item xs={12}>
                  <button className='wild-rides-button --half-width'>{buttonText}</button>
                  <div className='auth-bottom-text'>
                    <Link href='/browse' variant='body1'>
                      {'Cancel'}
                    </Link>
                  </div>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Container>
      </div>
    </>
  );
}
