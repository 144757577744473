import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import SeriesCard from './SeriesCard';
import Skeleton from '@mui/material/Skeleton';
import { apiRequest } from '../../utils/general';

const CategoryRow = (props) => {
  const [seriesList, setSeriesList] = useState(null);
  const [showLeftButton, setShowLeftButton] = useState(false);
  const [showRightButton, setShowRightButton] = useState(true);

  const ref = useRef(null);
  const outerRef = useRef(null);

  useEffect(() => {
    const getData = async () => {
      try {
        if (props.url) {
          const response = await apiRequest(props.url);
          setSeriesList(response.data);
        }
      } catch (err) {
        console.error(err);
      }
    };
    getData();
  }, []);

  useEffect(() => {
    const outerWidth = outerRef?.current?.clientWidth;
    const innerWidth = ref?.current?.scrollWidth;

    if(outerWidth && innerWidth && innerWidth < outerWidth){
      setShowRightButton(false);
    }
  }, [seriesList]);

  const scrollHandler = (e) => {
    const currentScroll = ref.current.scrollLeft;
    const maxScroll = ref.current.scrollWidth - ref.current.clientWidth;
    if (Math.floor(currentScroll) === 0) {
      setShowLeftButton(false);
    } else if (!showLeftButton) {
      setShowLeftButton(true);
    }

    if (Math.ceil(currentScroll) >= maxScroll) {
      setShowRightButton(false);
    } else if (!showRightButton) {
      setShowRightButton(true);
    }
  };

  const scrollLeft = () => {
    if (outerRef?.current && ref?.current) {
      const scrollDistance = outerRef.current.clientWidth / 2;
      ref.current.scrollLeft -= scrollDistance;
    }
  };

  const scrollRight = () => {
    if (outerRef?.current && ref?.current) {
      const scrollDistance = outerRef.current.clientWidth / 2;
      ref.current.scrollLeft += scrollDistance;
    }
  };

  return (
    <div className='category-row' ref={outerRef}>
      {seriesList ? (
        <>
          <h3 className='text-trocchi'>{props.title}</h3>
          <div className='button-wrapper'>
            <button className={`scroll-button ${showLeftButton ? '--visible' : '--hidden'}`} onClick={scrollLeft}>
              <div className='chevron left'></div>
            </button>
            <div className='category-row__video-list' onScroll={scrollHandler} ref={ref}>
              {seriesList.map((series) => {
                return (
                  <div key={series._id} className='outline-hover'>
                    <SeriesCard series={series} />
                  </div>
                );
              })}
            </div>

            <button className={`scroll-button ${showRightButton ? '--visible' : '--hidden'}`} onClick={scrollRight}>
              <div className='chevron right'></div>
            </button>
          </div>
        </>
      ) : (
        <>
          <Skeleton className='skeleton' width={'20%'} variant='text' animation='wave' />
          <div className='category-row__video-list'>
            <Skeleton variant='rectangular' animation='wave' className='skeleton outline-hover video-skeleton-md' />
            <Skeleton variant='rectangular' animation='wave' className='skeleton outline-hover video-skeleton-md' />
            <Skeleton variant='rectangular' animation='wave' className='skeleton outline-hover video-skeleton-md' />
            <Skeleton variant='rectangular' animation='wave' className='skeleton outline-hover video-skeleton-md' />
          </div>
        </>
      )}
    </div>
  );
};

export default CategoryRow;
