import React from 'react';

export function PrivacyPolicy() {
  return (
    <div className='info-page --wide'>
      <div className='terms-and-conditions-div'>
        <h1>Privacy Policy</h1>
        <p>
          This Privacy Statement explains our practices, including your choices, regarding the collection, use, and
          disclosure of certain information, including your personal information in connection with the Wild Rides
          Television Network service.
        </p>
        <h2>Collection of Information</h2>
        <p>We receive and store information about you such as:</p>
        <ul>
          <li>
            <p>
              <b>Information you provide to us:</b> We collect information you provide to us which includes:
            </p>
            <ul>
              <li>
                <p>
                  your name, email address, address or postal code, payment method(s), telephone number, and other
                  identifiers you might use. We collect this information in a number of ways, including when you enter
                  it while using our service, interact with our customer service, or participate in surveys or marketing
                  promotions;
                </p>
              </li>
              <li>
                <p>
                  information when you choose to provide ratings, taste preferences, account settings (including
                  preferences set in the "Account" section of our website), or otherwise provide information to us
                  through our service or elsewhere.
                </p>
              </li>
            </ul>
          </li>
          <li>
            <p>
              <b>Information we collect automatically:</b> We collect information about you and your use of our service,
              your interactions with us and our advertising, as well as information regarding your network, network
              devices, and your computer or other Wild Rides Television Network capable devices you might use to access
              our service (such as gaming systems, smart TVs, mobile devices, set top boxes, and other streaming media
              devices). This information includes:
            </p>
            <ul>
              <li>
                <p>
                  your activity on the Wild Rides Television Network service, such as title selections, shows you have
                  watched, and search queries;
                </p>
              </li>
              <li>
                <p>
                  your interactions with our emails and texts, and with our messages through push and online messaging
                  channels;
                </p>
              </li>
              <li>
                <p>
                  details of your interactions with our customer service, such as the date, time and reason for
                  contacting us, transcripts of any chat conversations, and if you call us, your phone number and call
                  recordings;
                </p>
              </li>
              <li>
                <p>
                  device IDs or other unique identifiers, including for your network devices, and devices that are Wild
                  Rides Television Network capable on your Wi-Fi network;
                </p>
              </li>
              <li>
                <p>
                  resettable device identifiers (also known as advertising identifiers), such as those on mobile
                  devices, tablets, and streaming media devices that include such identifiers (see the "Cookies and
                  Internet Advertising" section below for more information);
                </p>
              </li>
              <li>
                <p>
                  device and software characteristics (such as type and configuration), connection information including
                  type (wifi, cellular), statistics on page views, referring source (for example, referral URLs), IP
                  address (which may tell us your general location), browser and standard web server log information;
                </p>
              </li>
              <li>
                <p>
                  information collected via the use of cookies, web beacons and other technologies, including ad data
                  (such as information on the availability and delivery of ads, the site URL, as well as the date and
                  time). (See our "Cookies and Internet Advertising" section for more details.)
                </p>
              </li>
            </ul>
          </li>
          <li>
            <p>
              <b>Information from partners:</b> We collect information from other companies with whom you have a
              relationship (“Partners”). These Partners might include (depending on what services you use): your TV or
              internet service provider, or other streaming media device providers who make our service available on
              their device; mobile phone carriers or other companies who provide services to you and collect payment for
              the Wild Rides Television Network service for distribution to us or provide pre-paid promotions for the
              Wild Rides Television Network service; and voice assistant platform providers who enable interaction with
              our service through voice commands. The information Partners provide us varies depending on the nature of
              the Partner services, and may include:
            </p>
            <ul>
              <li>
                <p>service activation information such as your email address or other contact information;</p>
              </li>
              <li>
                <p>
                  IP addresses, device IDs or other unique identifiers, as well as associated pre-paid promotion,
                  billing and user interface information, that support user authentication, the Wild Rides Television
                  Network service registration experience, Partner payment processing, and the presentation of Wild
                  Rides Television Network content to you through portions of the Partner user interface.
                </p>
              </li>
            </ul>
          </li>
          <li>
            <p>
              <b>Information from other sources:</b> We also obtain information from other sources. We protect this
              information according to the practices described in this Privacy Statement, plus any additional
              restrictions imposed by the source of the data. These sources vary over time, but could include:
            </p>
            <ul>
              <li>
                <p>
                  service providers that help us determine a location based on your IP address in order to customize our
                  service and for other uses consistent with this Privacy Statement;
                </p>
              </li>
              <li>
                <p>
                  security service providers who provide us with information to secure our systems, prevent fraud and
                  help us protect the security of Wild Rides Television Network accounts;
                </p>
              </li>
              <li>
                <p>
                  payment service providers who provide us with payment or balance information, or updates to that
                  information, based on their relationship with you;
                </p>
              </li>
              <li>
                <p>
                  online and offline data providers, from which we obtain aggregated demographic, interest based and
                  online advertising related data;
                </p>
              </li>
              <li>
                <p>
                  publicly-available sources such as publicly available posts on social media platforms and information
                  available through public databases associating IP addresses with internet service providers (ISPs);
                </p>
              </li>
            </ul>
          </li>
        </ul>

        <h2>Use of Information</h2>
        <p>
          We use information to provide, analyze, administer, enhance and personalize our services and marketing
          efforts, to manage member referrals, to process your registration, your orders and your payments, and to
          communicate with you on these and other topics. For example, we use such information to:
        </p>
        <ul>
          <li>
            <p>
              determine your general geographic location, provide localized content, provide you with customized and
              personalized viewing recommendations for movies and TV shows we think will be of interest to you,
              determine your ISP to support network troubleshooting for you (we also use aggregated ISP information for
              operational and business purposes), and help us quickly and efficiently respond to inquiries and requests;
            </p>
          </li>
          <li>
            <p>
              coordinate with Partners on making the Wild Rides Television Network service available to members and
              providing information to non members about the availability of the Wild Rides Television Network service,
              based on the specific relationship you have with the Partner;
            </p>
          </li>
          <li>
            <p>
              secure our systems, prevent fraud and help us protect the security of Wild Rides Television Network
              accounts;
            </p>
          </li>
          <li>
            <p>
              prevent, detect and investigate potentially prohibited or illegal activities, including fraud, and to
              enforce our terms (such as determining whether and for which Wild Rides Television Network signup offers
              you are eligible and determining whether a particular device is permitted to use the account consistent
              with our Terms of Use);
            </p>
          </li>
          <li>
            <p>
              analyze and understand our audience, improve our service (including our user interface experiences and
              service performance) and optimize content selection, recommendation algorithms and delivery;
            </p>
          </li>
          <li>
            <p>
              communicate with you concerning our service so that we can send you news about Wild Rides Television
              Network, details about new features and content available on Wild Rides Television Network, special
              offers, promotional announcements, consumer surveys, and to assist you with operational requests such as
              password reset requests. These communications may be by various methods, such as email, push
              notifications, text message, online messaging channels, and matched identifier communications (described
              below).
            </p>
          </li>
        </ul>

        <h2>Disclosure of Information</h2>
        <p>We disclose your information for certain purposes and to third parties, as described below:</p>

        <ul>
          <li>
            <p>
              <b>Service Providers:</b> We use other companies, agents or contractors ("Service Providers") to perform
              services on our behalf or to assist us with the provision of services to you. For example, we engage
              Service Providers to provide marketing, advertising, communications, security, infrastructure and IT
              services, to customize, personalize and optimize our service, to provide bank account or balance
              information, to process credit card transactions or other payment methods, to provide customer service, to
              analyze and enhance data (including data about users' interactions with our service), and to process and
              administer consumer surveys. In the course of providing such services, these Service Providers may have
              access to your personal or other information. We do not authorize them to use or disclose your personal
              information except in connection with providing their services (which includes maintaining and improving
              their services).
            </p>
          </li>
          <li>
            <p>
              <b>Partners:</b> As described above, you may have a relationship with one or more of our Partners, in
              which case we may share certain information with them in order to coordinate with them on providing the
              Wild Rides Television Network service to members and providing information about the availability of the
              Wild Rides Television Network service. For example, depending on what Partner services you use, we may
              share information:
            </p>
            <ul>
              <li>
                <p>
                  in order to facilitate Partner pre-paid promotions or collection of payment for the Wild Rides
                  Television Network service for distribution to us;
                </p>
              </li>
              <li>
                <p>
                  with Partners who operate voice assistant platforms that allow you to interact with our service using
                  voice commands;
                </p>
              </li>
              <li>
                <p>
                  so that content and features available in the Wild Rides Television Network service can be suggested
                  to you in the Partner’s user interface. For members, these suggestions are part of the Wild Rides
                  Television Network service and may include customized and personalized viewing recommendations.
                </p>
              </li>
            </ul>
          </li>
          <li>
            <p>
              <b>Promotional offers:</b> We may offer joint promotions or programs that, in order for your
              participation, will require us to share your information with third parties. In fulfilling these types of
              promotions, we may share your name and other information in connection with fulfilling the incentive.
              Please note that these third parties are responsible for their own privacy practices.
            </p>
          </li>
          <li>
            <p>
              <b>Protection of Wild Rides Television Network and others:</b> Wild Rides Television Network and its
              Service Providers may disclose and otherwise use your personal and other information where we or they
              reasonably believe such disclosure is needed to (a) satisfy any applicable law, regulation, legal process,
              or governmental request, (b) enforce applicable terms of use, including investigation of potential
              violations thereof, (c) detect, prevent, or otherwise address illegal or suspected illegal activities
              (including payment fraud), security or technical issues, or (d) protect against harm to the rights,
              property or safety of Wild Rides Television Network, its users or the public, as required or permitted by
              law.
            </p>
          </li>
          <li>
            <p>
              <b>Business transfers:</b> In connection with any reorganization, restructuring, merger or sale, or other
              transfer of assets, we will transfer information, including personal information, provided that the
              receiving party agrees to respect your personal information in a manner that is consistent with our
              Privacy Statement.
            </p>
          </li>
        </ul>
        <p>
          Whenever in the course of sharing information we transfer personal information to other countries, we will
          ensure that the information is transferred in accordance with this Privacy Statement and as permitted by the
          applicable laws on data protection.
        </p>
        <p>You may also choose to disclose your information in the following ways:</p>
        <ul>
          <li>
            <p>
              certain portions of our service may contain a tool which gives you the option to share information by
              email, text message and social or other sharing applications, using the clients and applications on your
              smart device;
            </p>
          </li>
          <li>
            <p>social plugins and similar technologies allow you to share information</p>
          </li>
        </ul>
        <h2>Access to Account and Profiles</h2>
        <ul>
          <li>
            <p>
              <b>Giving others access to your account:</b> If you share or otherwise allow others to have access to your
              account, they will be able to see shows you have watched, ratings, and account information (including your
              email address or other information in the "Account" area of our website).{' '}
            </p>
          </li>
        </ul>
        <h2>Your Information and Rights</h2>
        <p>
          You can request access to your personal information, or correct or update out-of-date or inaccurate personal
          information we hold about you. You may also request that we delete personal information that we hold about
          you.
        </p>
        <p>
          When you visit the "My Account" portion of our website, you have the ability to access and update a broad
          range of information about your account, including your contact information, your Wild Rides Television
          Network payment information, and various related information about your account. You must be signed in to
          access the "My Account" section.
        </p>
        <p>
          For other requests, or if you have any other question regarding our privacy practices, please contact our Data
          Protection Officer/Privacy Office at <a href='mailto:support@wildridestv.com'>support@wildridestv.com</a>. We
          respond to all requests we receive from individuals wishing to exercise their data protection rights in
          accordance with applicable data protection laws.{' '}
        </p>
        <p>
          We may reject requests that are unreasonable or not required by law, including those that would be extremely
          impractical, could require disproportionate technical effort, or could expose us to operational risks such as
          free trial fraud. We may retain information as required or permitted by applicable laws and regulations,
          including to honor your choices, for our billing or records purposes and to fulfill the purposes described in
          this Privacy Statement. We take reasonable measures to destroy or de-identify personal information in a secure
          manner when it is no longer required.
        </p>
        <h2>Security</h2>
        <p>
          We use reasonable administrative, logical, physical and managerial measures to safeguard your personal
          information against loss, theft and unauthorized access, use and modification. These measures are designed to
          provide a level of security appropriate to the risks of processing your personal information. Unfortunately,
          no measures can be guaranteed to provide 100% security. Accordingly, we cannot guarantee the security of your
          personal information.
        </p>
        <h2>Other Websites, Platforms and Applications</h2>
        <p>
          The Wild Rides Television Network service may be provided through and/or utilize features operated by third
          party platforms, or contain links to sites operated by third parties whose policies regarding the handling of
          information may differ from ours. For example, you may be able to access the Wild Rides Television Network
          service through platforms such as gaming systems, smart TVs, mobile devices, set top boxes and a number of
          other Internet connected devices. These websites and platforms have separate and independent privacy or data
          policies, privacy statements, notices and terms of use, which we recommend you read carefully. In addition,
          you may encounter third party applications that interact with the Wild Rides Television Network service.
        </p>
        <h2>Children</h2>
        <p>
          You must be at least 18 years of age or older to subscribe to the Wild Rides Television Network service.
          Minors may only use the service with the involvement, supervision, and approval of a parent or legal guardian.
        </p>
        <h2>Changes to This Privacy Statement</h2>
        <p>
          We will update this Privacy Statement from time to time in response to changing legal, regulatory or
          operational requirements. We will provide notice of any such changes (including when they will take effect) in
          accordance with law. Your continued use of the Wild Rides Television Network service after any such updates
          take effect will constitute acknowledgement and (as applicable) acceptance of those changes. If you do not
          wish to acknowledge or accept any updates to this Privacy Statement, you may cancel your use of the Wild Rides
          Television Network service.{' '}
        </p>
        <h2>Cookies and Internet Advertising</h2>
        <p>
          We and our Service Providers use cookies and other technologies, as well as resettable device identifiers, for
          various reasons. We want you to be informed about our use of these technologies, so this section explains the
          types of technologies we use, what they do and your choices regarding their use.
        </p>
        <h3>Cookies and similar technologies</h3>
        <p>
          Cookies are small data files that are commonly stored on your device when you browse and use websites and
          online services.
        </p>
        <h2>California Consumer Privacy Act (CCPA) Privacy Notice</h2>
        <p>This Privacy Notice applies to California consumers and supplements the Privacy Statement.</p>
        <p style={{ textDecoration: 'underline' }}>Uses of CCPA personal information</p>
        <p>
          We collect information that identifies, relates to, describes, is reasonably capable of being associated with,
          or could reasonably be linked, directly or indirectly, with a particular consumer or household (“CCPA personal
          information”). We have collected the following categories of CCPA personal information from consumers within
          the last twelve (12) months:
        </p>
        <ul>
          <li>
            <p>
              <b>Identifiers:</b> “Identifiers” covers a broad range of information, including things like name, email
              address, postal address, telephone number, and IP address. We collect some CCPA personal information that
              falls into this category. For example, for streaming subscribers we’re going to have an email address, and
              payment information (like a credit card). We collect IP addresses, identifiers from the devices you use to
              connect, and characteristics about the networks you use when you connect to our service online. These
              sorts of identifiers are connected to your Wild Rides Television Network account. There are also
              identifiers we use for purposes like advertising (resettable device identifiers, third party advertising
              cookies on our website) that we do not associate with your account, but that can be used to reach you with
              promotional messages on other websites and applications.
            </p>
          </li>
          <li>
            <p>
              <b>Characteristics of protected classifications under California or federal law:</b> This covers a broad
              range of information like race, sex, marital status, among other protected classes. We will collect this
              sort of information about you if you choose to participate in our optional research surveys, or other
              research activity such as an in person interview or panel. We do not collect this information when you
              register for the Wild Rides Television Network service.
            </p>
          </li>
          <li>
            <p>
              <b>Commercial information:</b> This includes information about products or services considered, obtained
              or purchased.
            </p>
          </li>
          <li>
            <p>
              <b>Internet or other electronic network activity information:</b> We offer online services, so we collect
              CCPA personal information in this category, like your interactions with our service.
            </p>
          </li>
          <li>
            <p>
              <b>Geolocation data:</b> Our online services don't collect precise geolocation data from GPS sensors.
              However, we do collect IP addresses from which we can determine your general location.
            </p>
          </li>
          <li>
            <p>
              <b>Audio, electronic, visual, thermal, olfactory or similar information:</b> We may collect photos, audio
              and/or video of you in certain circumstances. For example if you contact customer service, we may record
              the call (voice recording).
            </p>
          </li>
        </ul>
        <p style={{ textDecoration: 'underline' }}>Personal Information We Collect</p>
        <p>
          We use categories of CCPA personal information listed above for the purposes noted in the Use of Information
          section of our Privacy Statement. For ease of reference, we repeat that section below:
        </p>
        <p>
          We use personal information to provide, analyze, administer, enhance and personalize our services and
          marketing efforts, to process your registration, your orders and your payments, and to communicate with you on
          these and other topics. For example, we use such information to:{' '}
        </p>
        <ul>
          <li>
            <p>
              to coordinate with Partners on making the Wild Rides Television Network service available to members and
              providing information to non members about the availability of the Wild Rides Television Network service,
              based on the specific relationship you have with the Partner;
            </p>
          </li>
          <li>
            <p>
              prevent, detect and investigate potentially prohibited or illegal activities, including fraud, and to
              enforce our terms (such as determining free trial eligibility);
            </p>
          </li>
          <li>
            <p>
              analyze and understand our audience, improve our service (including our user interface experiences) and
              optimize content selection, recommendation algorithms and delivery;
            </p>
          </li>
          <li>
            <p>
              communicate with you concerning our service so that we can send you news about Wild Rides Television
              Network, details about new features and content available on Wild Rides Television Network, special
              offers, promotional announcements, consumer surveys, and to assist you with operational requests such as
              password reset requests. These communications may be by various methods, such as email, push
              notifications, text message, online messaging channels, and matched identifier communications (described
              below).
            </p>
          </li>
        </ul>
        <p style={{ textDecoration: 'underline' }}>
          Categories of CCPA personal information disclosed for a business purpose
        </p>
        <p>
          We disclose the categories of CCPA personal information listed below for business purposes. (Please see the
          Disclosure of Information section of our Privacy Statement for additional details that may be of interest to
          you.)
        </p>
        <ul>
          <li>
            <p>
              <b>Identifiers:</b> We may disclose identifiers for business purposes with the following categories of
              third parties: Service Providers, Partners, an entity engaged in a business transfer, law enforcement,
              courts, governments and regulatory agencies.
            </p>
          </li>
          <li>
            <p>
              <b>Characteristics of protected classifications under California or federal law:</b> We may disclose these
              types of characteristics for business purposes with the following categories of third parties: Service
              Providers, an entity engaged in a business transfer/merger, law enforcement, courts, governments and
              regulatory agencies.
            </p>
          </li>
          <li>
            <p>
              <b>Commercial information:</b> We may disclose commercial information for business purposes with the
              following categories of third parties: Service Providers, Partners, an entity engaged in a business
              transfer/merger, law enforcement, courts, governments and regulatory agencies.
            </p>
          </li>
          <li>
            <p>
              <b>Internet or other electronic network activity information:</b> We may disclose these types of
              information for business purposes with the following categories of third parties: Service Providers,
              Partners, an entity engaged in a business transfer/merger, law enforcement, courts, governments and
              regulatory agencies.
            </p>
          </li>
          <li>
            <p>
              <b>Geolocation data:</b> We may disclose geolocation data for business purposes with the following
              categories of third parties: Service Providers, Partners, an entity engaged in a business transfer/merger,
              law enforcement, courts, governments and regulatory agencies.
            </p>
          </li>
          <li>
            <p>
              <b>Audio, electronic, visual, thermal, olfactory or similar information:</b> We may disclose these types
              of information for business purposes with the following categories of third parties: Service Providers,
              Partners, an entity engaged in a business transfer/merger, law enforcement, courts, governments and
              regulatory agencies.
            </p>
          </li>
          <li>
            <p>
              <b>Inferences:</b> We may disclose these types of data for business purposes with the following categories
              of third parties: an entity engaged in a business transfer/merger.
            </p>
          </li>
        </ul>
        <p style={{ textDecoration: 'underline' }}>Sources of CCPA personal information</p>
        <p>
          We explain our sources of information in the Collection of Information section of our Privacy Statement.
          (Please see that section for more information that may be of interest to you.) For ease of reference, in
          regard to CCPA personal information these are:
        </p>
        <ul>
          <li>
            <p>
              You (the consumer) when you provide us CCPA personal information directly, and when we collect it
              automatically based on your interactions with us (for example, through your computer or other Wild Rides
              Television Network capable device you use to access our service (such as smart TVs, mobile devices, set
              top boxes, and other streaming media devices);
            </p>
          </li>
          <li>
            <p>
              Partners (other companies with whom you have a relationship). These Partners might include (depending on
              what services you use): your TV or Internet service provider, or other streaming media device providers
              who make our service available on their device; mobile phone carriers or other companies who provide
              services to you and collect payment for the Wild Rides Television Network service for distribution to us;
              voice assistant platform providers who enable interaction with our service via voice commands; and
            </p>
          </li>
          <li>
            <p>
              Other sources: service providers who support our business including security and payment service
              providers, and publicly-available sources, specifically, public posts on social media platforms;
              information about Internet Service Providers (ISPs) used by members to access Wild Rides Television
              Network through public databases associating IP addresses with ISPs.
            </p>
          </li>
        </ul>
        <p style={{ textDecoration: 'underline' }}>Your rights under the CCPA</p>
        <ul>
          <li>
            <p>
              You have the right to request that we disclose: what categories and specific pieces of CCPA personal
              information have been collected about you; the categories of sources from which CCPA personal information
              are collected; our business or commercial purpose for collecting, using, or disclosing CCPA personal
              information; the categories of third parties with whom we share CCPA personal information; the categories
              of CCPA personal information we have disclosed about you for a business purpose. We do not sell personal
              information.
            </p>
          </li>
          <li>
            <p>
              You have a right to receive a copy of the specific CCPA personal information we have collected about you.
            </p>
          </li>
          <li>
            <p>You have a right to deletion of your CCPA personal information, subject to exceptions under the CCPA.</p>
          </li>
          <li>
            <p>
              You have a right not to receive discriminatory treatment for exercising any of your CCPA rights. We will
              not discriminate against you based on your exercise of any of your CCPA rights.
            </p>
          </li>
        </ul>
        <p>You can assert these rights only where we receive a verified request from you. </p>
        <p>
          If you are a consumer under the CCPA and wish to contact us through an authorized agent, the authorized agent
          can submit a request on your behalf at support@wildridestv.com along with a statement signed by you certifying
          that the agent is authorized to act on your behalf. In order to verify the request and your identity, we may
          ask you to verify your identity.
        </p>
        <p>
          If you do not own a Wild Rides Television Network account, we may not be able to respond to requests to
          exercise rights under CCPA, including the right to know or delete CCPA personal information. Because we only
          collect limited information about individuals without an account, we are unable to verify requests from
          non-account holders to the standard required by the CCPA.
        </p>
        <h2>Contacting Us</h2>
        <p>
          If you have general questions about your account or how to contact customer service for assistance, please
          visit our online help center <a href='/contact-us'>here</a>. For questions specifically about this Privacy
          Statement, or our use of your personal information, cookies or similar technologies, please contact our Data
          Protection Officer/Privacy Office by email at{' '}
          <a href='mailto:support@wildridestv.com'>support@wildridestv.com</a>. The data controller of your personal
          information is Wild Rides Television Network.
        </p>
      </div>
    </div>
  );
}
