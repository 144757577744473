import { Card, Divider, Grid } from '@mui/material';
import React, { useState, useEffect } from 'react';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import ChangePlan from './subscription/ChangePlan';
import { apiRequest } from '../../utils/general';
import AddPaymentMethodModal from './payment/AddPaymentMethodModal';
import { useNavigate } from 'react-router-dom';
import Notification from '../Notification';
import { useSelector } from 'react-redux';
import { NOTIFICATION_PRIORITIES } from '../../utils/constants';

const SubscriptionInfo = () => {
  const { notifications } = useSelector((state) => state.notification);
  const navigate = useNavigate();
  const [notification, setNotification] = useState(null);
  const [cardDetails, setCardDetails] = useState({});
  const [paymentInfo, setPaymentInfo] = useState({});
  const [subscriptionName, setSubscriptionName] = useState('');
  const [hasPayment, setHasPayment] = useState(false);
  const [loading, setLoading] = useState(true);
  const [cards, setCards] = useState([]);
  const [showAddPaymentModal, setShowAddPaymentModal] = useState(false);
  const [isChangePlanModelOpened, setIsChangePlanModelOpened] = useState(false);
  const avatarClasses = 'avatar-small';

  useEffect(() => {
    fetchSubscriptionDetails();
  }, []);
  
  useEffect(() => {
    if (notifications.length > 0) {
      setNotification(notifications[notifications.length - 1]);
    }
  }, [notifications]);

  const fetchSubscriptionDetails = async () => {
    try {
      const detailsRes = await apiRequest(`users/subscription-details`);
      let data = detailsRes.data;
      setPaymentInfo(data.payment_info);

      if (data.payment_info?.plan_name) {
        setSubscriptionName(`${data.payment_info.plan_name}ly Subscription`);
      }

      if (data?.cards?.length > 0) {
        setHasPayment(true);
        const primaryCard = data.cards[0];
        setCardDetails(primaryCard);
        setCards(data.cards);
      } else {
        setCards([]);
        setHasPayment(false);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };
  const handleDeleteCards = async () => {
    try {
      cards.forEach(async (card) => await apiRequest(`users/${card.customer}/cards/${card.id}`, 'delete'));
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className='subscription-section'>
      <ChangePlan
        isModalOpened={isChangePlanModelOpened}
        onCloseModal={() => setIsChangePlanModelOpened(false)}
        subscription={paymentInfo}
        cardDetails={cardDetails}
        onDeleteCards={handleDeleteCards}
      />
      <AddPaymentMethodModal
        isModalOpened={showAddPaymentModal}
        onCloseModal={() => setShowAddPaymentModal(false)}
        cards={cards}
        onDeleteCards={handleDeleteCards}
        onCardAdded={fetchSubscriptionDetails}
      />
      <Card className='profile-card'>
        <CardContent>
          <Typography variant='h6' component='div' sx={{ mb: 1.5 }} className='text-black'>
            Subscription
          </Typography>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant='body2' className='text-brown'>
                <div className='profile-card-header'>Payment Method</div>
              </Typography>
            </Grid>
            <Grid item xs={6} className='profile-card-menu'>
              {!loading ? (
              <Button
                type='button'
                onClick={() => (hasPayment ? setShowAddPaymentModal(true) : navigate('/user/subscribe'))}
                style={{ textAlign: 'right' }}
              >
                {hasPayment && 'Manage Payment Info'}
              </Button>):null}
            </Grid>
          </Grid>
          {loading && (
            <div className='profile-card-body'>
              <Skeleton width='20%' />
              <Skeleton width='50%' />
              <Skeleton width='20%' />
              <Skeleton width='60%' />
            </div>
          )}
          {!loading && hasPayment && (
            <div className='profile-card-body'>
              <Typography variant='body2'>{cardDetails.name}</Typography>
              <Typography variant='body2'>●●●● &nbsp; ●●●● &nbsp; ●●●● &nbsp; {cardDetails.last4}</Typography>
              <Typography variant='body2'>
                EXP. {cardDetails.exp_month}/{cardDetails.exp_year}
              </Typography>

              {loading && <Skeleton width='80%' />}
              {!loading && hasPayment && (
                <Typography variant='body2'>
                  Your next billing date is {paymentInfo.end_at}
                  {notification && (
                    <>
                      <Notification classNames={avatarClasses}>
                        <span>!</span>
                      </Notification>

                      <div
                        className={
                          notification.priority === NOTIFICATION_PRIORITIES.WARNING ? 'text-warning' : 'text-danger'
                        }
                      >
                        {notification.message}
                      </div>
                    </>
                  )}
                </Typography>
              )}
            </div>
          )}
          {!loading && !hasPayment && <div className='profile-card-body'>Please subscribe to add a payment method.</div>}
          <Divider className='section-divider' />
          <Grid container>
            <Grid item xs={6}>
              <Typography variant='body2' className='text-brown'>
                <div className='profile-card-header'>Plan Details</div>
              </Typography>
            </Grid>
            <Grid item xs={6} className='profile-card-menu'>
              {!loading && paymentInfo?.subscription_id && !paymentInfo?.cancel_at_period_end && (
                <Button type='button' onClick={() => setIsChangePlanModelOpened(true)}>
                  Change Plan
                </Button>
              )}
              {!loading && paymentInfo?.subscription_id && paymentInfo?.cancel_at_period_end && (
                <Button type='button' href={`/user/subscribe`}>
                  Resubscribe
                </Button>
              )}
              {!loading && !paymentInfo?.subscription_id && (
                <Button type='button' href={`/user/subscribe`}>
                  Subscribe
                </Button>
              )}
            </Grid>
          </Grid>
          <div className='profile-card-body'>
            {loading && <Skeleton width='80%' />}
            {!loading && paymentInfo?.subscription_id && (
              <Typography variant='body2'>
                {paymentInfo?.subscription_id && (
                  <span>
                    {subscriptionName} ${parseFloat(paymentInfo.amount).toFixed(2)}{' '}
                    <strong>
                      ({paymentInfo.cancel_at_period_end ? `Cancelled on ${paymentInfo.cancelled_at}` : 'Active'})
                    </strong>
                  </span>
                )}
                {paymentInfo?.cancel_at_period_end && (
                  <div>Your account subscription will expire {paymentInfo.end_at}</div>
                )}
                {!paymentInfo?.subscription_id && <span>No subscribed plans</span>}
              </Typography>
            )}
            {!loading && !paymentInfo?.subscription_id && <Typography variant='body2'>No Subscription</Typography>}
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default SubscriptionInfo;
