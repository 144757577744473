import { Skeleton } from '@mui/material';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiRequest } from '../../utils/general';

const Slide = ({ slideData, currentSlideIndex, thisSlideIndex, slidesLength }) => {
  const navigate = useNavigate();
  const [slideDetails, setSlideDetails] = useState(null);
  const [logo, setLogo] = useState('');
  const [slideImage, setSlideImage] = useState('');
  const [buttonText, setButtonText] = useState('Watch Now');
  const [buttonUrl, setButtonUrl] = useState('');

  const fetchSlideData = async () => {
    try {
      const response = await apiRequest(`/playlists/series/${slideData.data.id}`);
      if (response?.data?.length > 0) {
        setSlideDetails({
          ...response.data[0],
          position: slideData.data.position,
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    const isCurrentSlide = thisSlideIndex === currentSlideIndex;
    const isNextSlide = thisSlideIndex === currentSlideIndex + 1;
    const isPreviousSlide = thisSlideIndex === currentSlideIndex - 1;
    if (!slideDetails && (isCurrentSlide || isNextSlide || isPreviousSlide)) {
      fetchSlideData();
    }
  }, [thisSlideIndex, currentSlideIndex]);

  useEffect(() => {
    if (slideDetails) {
      const { images } = slideDetails;
      setButtonUrl(`/series/${slideDetails._id}`);
      for (const image of images) {
        const { title, url } = image;
        if (title === 'logo') {
          setLogo(url);
        } else if (title === 'carousel_image') {
          setSlideImage(url);
        }
      }
    }
  }, [slideDetails]);

  return slideDetails ? (
    <div className='carousel__slide' style={{ backgroundImage: `url(${slideImage})` }}>
      <div className='slide__gradient' />
      <div className={`slide__decorations --${slideDetails.position}`}>
        <div className='slide__logo' style={{ backgroundImage: `url(${logo})` }}></div>
        <button
          onClick={() => navigate(buttonUrl)}
          className='wild-rides-button --half-width'
          style={{ justifySelf: 'center' }}
        >
          {buttonText}
        </button>
      </div>
    </div>
  ) : (
    <Skeleton variant='rectangular' width='80vw' height='60vh' animation='wave' />
  );
};

export default Slide;
