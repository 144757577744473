import axios from 'axios';
import { store } from '../store/store';

export const apiRequest = (url, method = 'get', data = {}) => {
  const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
  const state = store.getState();
  const token = state?.user?.token;
  const headers = {
    'X-CSRF-Token': csrfToken,
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  const constructedUrl = (window.location.origin + '/api/' + url).split('//').join('/').replace(':/', '://');

  return axios({
    method: method,
    url: constructedUrl,
    data: data,
    headers: headers,
  });
};
