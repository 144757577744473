import React, { useEffect, useState } from 'react';
import { apiRequest } from '../../utils/general';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DisplayPrices from './DisplayPrices';
import VideoBackground from '../common/VideoBackground';
import { showLoader, hideLoader } from '../../store/loaderSlice';
import cloud from '../../../assets/images/mobile-cloud.svg';
import movie from '../../../assets/images/movie.svg';
import profiles from '../../../assets/images/add-profiles.svg';

const Pricing = () => {
  const [backgroundVideoUrl, setBackgroundVideoUrl] = useState('');
  const [planMonthly, setPlanMonthly] = useState(null);
  const [planYearly, setPlanYearly] = useState(null);
  const isLoggedIn = useSelector((state) => !!state.user?.token);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(showLoader());
    const fetchBackgroundVideo = async () => {
      try {
        const allPromises = [];
        allPromises.push(apiRequest('subscriptions/plans'));
        allPromises.push(apiRequest('users/sign-up-background'));
        const responses = await Promise.all(allPromises);
        const subscriptionRes = responses[0];
        const backgroundVideoRes = responses[1];
        const [planMonthly, planYearly] = subscriptionRes.data.response;
        setPlanMonthly(planMonthly);
        setPlanYearly(planYearly);
        setBackgroundVideoUrl(backgroundVideoRes.data.video_url);
      } catch (err) {
        console.error(err);
      }
      dispatch(hideLoader());
    };
    fetchBackgroundVideo();
  }, [isLoggedIn]);

  const handleButtonClick = () => {
    isLoggedIn ? navigate('/user/subscribe') : navigate('/sign-up');
  };

  return planYearly && planMonthly ? (
    <>
      <VideoBackground videoSrc={backgroundVideoUrl} />
      <div className='info-page --extra-wide'>
        <div className='info-page --no-padding'>
          <div className='info-page__header'>
            <h1>One Great Price.</h1>
            <h2>All your favorite series.</h2>
            <button className='wild-rides-button --inverse --half-width' onClick={handleButtonClick}>
              Try 7 Days for Free
            </button>
          </div>
          <DisplayPrices
            monthlyAmount={planMonthly.amount}
            monthlyName={planMonthly.name}
            yearlyAmount={planYearly.amount}
            yearlyName={planYearly.name}
          />
          <ul className='info-page__custom-list --pricing-page-font'>
            <li>Access to ALL Wild Rides TV (WRTV)</li>
            <li>With additional contemporary programing such as HOW COUPLES MET</li>
            <li>New episodes every week!</li>
            <li>
              Entertainment and interviews from top rodeo professionals, legends, and other celebrities from today and
              yesterday
            </li>
          </ul>
        </div>
        <div className='feature-grid'>
          <div className='flex-center-column'>
            <img src={cloud} alt='mobile-phone-cloud' />
            <p>You can cancel your subscription at any time. No hidden fees, ever.</p>
          </div>
          <div className='flex-center-column'>
            <img src={movie} alt='movie-icon' />
            <p>Watch full seasons of the exclusive Wild Ride TV series and tons more.</p>
          </div>
          <div className='flex-center-column'>
            <img src={profiles} alt='add-profiles-icon' />
            <p>Watch on up to 3 different screens at the same time.</p>
          </div>
        </div>
      </div>
    </>
  ) : (
    <div style={{ height: '110vh' }}></div>
  );
};
export default Pricing;
