import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { NOTIFICATION_PRIORITIES } from '../utils/constants';

const Notification = (props) => {
  const { notifications } = useSelector((state) => state.notification);
  const [notification, setNotification] = useState(null);

  useEffect(() => {
    if (notifications.length > 0) {
      setNotification(notifications[notifications.length - 1]);
    }
  }, [notifications]);
  return (
    <>
      {notification && (
        <div
          className={`${props.classNames}  ${
            notification.priority === NOTIFICATION_PRIORITIES.WARNING ? '--warning' : '--danger'
          }`}
        >
          {props.children}
        </div>
      )}
    </>
  );
};
export default Notification;
