import React, { useState } from 'react';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import Skeleton from '@mui/material/Skeleton';
import { useSelector } from 'react-redux';

const VideoCard = (props) => {
  const {video, hasSubscription} = props;
  const isLoggedIn = useSelector(state => !!state.user?.token);
  const videoUrl = !hasSubscription && video?.episode !== 0 ? '/user/subscribe' : `/video/${video.id}`;

  return video ? (
    <div className="series-video" key={video.id}>
      <a href={videoUrl} style={{ color: 'white' }}>
        <img src={video.thumbnails[0]?.url} alt={video.title + ' thumbnail'} />
      </a>
      <p className="series-video__episode-text">Episode {video.episode}</p>
      <h3>{video.title}</h3>
      <p>{video.short_description}</p>
      <a href={videoUrl} style={{ color: 'white' }}>
        { isLoggedIn && <PlayCircleFilledWhiteIcon fontSize="large" /> }
      </a>
    </div>
  ) : (
    <div className="series-video" key={Math.floor(Math.random() * 10000) + ' skeleton'}>
      <div className="widescreen-thumbnail">
        <Skeleton variant="rectangular" animation="wave" className="skeleton video-skeleton --series" />
      </div>
      <p className="series-video__episode-text">
        <Skeleton className="skeleton" width={'40%'} variant="text" animation="wave" />
      </p>
      <p>
        <Skeleton className="skeleton" width={'100%'} height={'2rem'} variant="text" animation="wave" />
      </p>
      <p>
        <Skeleton
          className="skeleton"
          width={70 + Math.floor(Math.random() * 20) + '%'}
          variant="text"
          animation="wave"
        />
        <Skeleton
          className="skeleton"
          width={70 + Math.floor(Math.random() * 20) + '%'}
          variant="text"
          animation="wave"
        />
        <Skeleton
          className="skeleton"
          width={70 + Math.floor(Math.random() * 20) + '%'}
          variant="text"
          animation="wave"
        />
        <Skeleton
          className="skeleton"
          width={70 + Math.floor(Math.random() * 20) + '%'}
          variant="text"
          animation="wave"
        />
      </p>
      { isLoggedIn && <Skeleton variant="rectangular" animation="wave" className="skeleton button-skeleton" /> }
    </div>
  );
};

export default VideoCard;
