import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { TextField } from '@material-ui/core';
import { apiRequest } from '../../utils/general';
import { useDispatch } from 'react-redux';
import { createAlert } from '../../store/alertSlice';
import { showLoader, hideLoader } from '../../store/loaderSlice';
import { ERROR_500 } from '../../utils/errors';

const NewsletterSignup = () => {
  const dispatch = useDispatch();

  const submitForm = async (formValues) => {
    try {
      dispatch(showLoader());
      await apiRequest('coming-soon/sign-up', 'post', formValues);
      dispatch(hideLoader());
      dispatch(createAlert({ message: 'Registered successfully!', type: 'success' }));
      formik.resetForm();
      onClose();
    } catch (e) {
      dispatch(hideLoader());
      console.error(err);
      if (e.response.status == 422) {
        dispatch(createAlert({ message: 'Email already signed up', type: 'error' }));
      } else {
        dispatch(createAlert({ message: ERROR_500, type: 'error' }));
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
    },
    validationSchema: Yup.object({
      first_name: Yup.string('Enter your first name').required('First Name is required'),
      last_name: Yup.string('Enter your last name').required('Last Name is required'),
      email: Yup.string('Enter your email address').email('Enter a valid email').required('Email is required'),
    }),
    onSubmit: (values) => {
      submitForm(values);
    },
  });
  return (
    <div className='info-page'>
      <h1> Wild Rides TV E-Newsletter</h1>
      <p>
        Sign up to receive our e-newsletter! Timely information about our custom produced series, exclusive interviews,
        and more!
      </p>
      <form noValidate onSubmit={formik.handleSubmit} className='contact-info-form info-form format-errors'>
        <TextField
          autoComplete='given-name'
          name='first_name'
          required
          id='first_name'
          label='First Name'
          value={formik.values.first_name}
          onChange={formik.handleChange}
          error={formik.touched.first_name && Boolean(formik.errors.first_name)}
          helperText={formik.touched.first_name && formik.errors.first_name}
          autoFocus
          variant='outlined'
        />
        <TextField
          autoComplete='last-name'
          name='last_name'
          required
          id='last_name'
          label='Last Name'
          value={formik.values.last_name}
          onChange={formik.handleChange}
          error={formik.touched.last_name && Boolean(formik.errors.last_name)}
          helperText={formik.touched.last_name && formik.errors.last_name}
          variant='outlined'
        />
        <TextField
          className='grid-item-full-width'
          autoComplete='email'
          name='email'
          required
          fullWidth
          id='email'
          label='Email'
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          autoFocus
          variant='outlined'
        />
        <div className='text-center grid-item-full-width'>
          <button type='submit' className='wild-rides-button --half-width'>
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default NewsletterSignup;
