import React, { useState, useEffect } from 'react';
import VideoPlaceholder from './VideoPlaceholder';
import ProgressiveImg from '../general/ProgressiveImg';
import { apiRequest } from '../../utils/general';
import Skeleton from '@mui/material/Skeleton';

const LeftVideoSection = (props) => {
  const [details, setDetails] = useState(null);
  const { comingSoon, seriesId, sideClass } = props;

  useEffect(() => {
    if (!seriesId) return;
    const getSeriesInfo = async () => {
      const response = await apiRequest(`playlists/series/${seriesId}`);
      if (response.data?.length > 0) {
        const series = response.data[0];
        const seriesDetails = {
          short_description: series.description,
          thumbnail: series.thumbnail,
          promo_video_id: series.promoVideos[0]?._id,
          logo: '',
          banner_low_rez: '',
          banner_full_rez: '',
        };
        for (const image of series.images) {
          switch (image.title) {
            case 'banner_full_rez':
              seriesDetails.banner_full_rez = image.url;
              break;
            case 'banner_low_rez':
              seriesDetails.banner_low_rez = image.url;
              break;
            case 'logo':
              seriesDetails.logo = image.url;
              break;
          }
        }
        setDetails(seriesDetails);
      }
    };
    getSeriesInfo();
  }, []);

  return details ? (
    <div className={'video-feature-grid ' + sideClass}>
      <ProgressiveImg
        src={details.banner_full_rez}
        placeholderSrc={details.banner_low_rez}
        width='100%'
        height='100%'
      />
      <div className='shadowVeil' />
      <div className='video-grid-video'>
        <VideoPlaceholder thumbnail={details.thumbnail} id={details.promo_video_id} />
      </div>
      <div className='video-grid-description'>
        <p className='text-center'>
          <img src={details.logo} alt='details logo' className='logo' />
        </p>
        <p className={'text-center ' + props.textDescriptionClass}>{details.short_description}</p>
      </div>
    </div>
  ) : (
    <Skeleton variation='rectangular' width='100%' height='90vh' sx={{ bgcolor: 'grey.900' }} />
  );
};

export default LeftVideoSection;
