import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { clearAlerts } from '../store/alertSlice';
import { useDispatch } from 'react-redux';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const GlobalAlert = () => {
  const { alerts } = useSelector((state) => state.alert);
  const [alert, setAlert] = useState({ type: '', message: '' });
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (alerts.length > 0) {
      setAlert(alerts[alerts.length - 1]);
      dispatch(clearAlerts());
      setShow(true);
      setTimeout(() => {
        setShow(false);
      }, 3000);
    }
  }, [alerts]);

  const onClose = () => {
    setShow(false);
  };

  return (
    <Snackbar
      open={show}
      autoHideDuration={6000}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      key={'top' + 'right'}
      onClose={onClose}
    >
      <Alert severity={alert.type} sx={{ width: '100%' }}>
        {alert.message}
      </Alert>
    </Snackbar>
  );
};

export default GlobalAlert;
