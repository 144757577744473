import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Avatar from './Avatar';
import { Link } from 'react-router-dom';
import { logOut } from '../../services/auth.service';
import { useDispatch } from 'react-redux';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { showLoader, hideLoader } from '../../store/loaderSlice';
import { clearUser } from '../../store/userSlice';
import { useNavigate } from 'react-router-dom';

const UserMenu = () => {
  const [anchorElUser, setAnchorElUser] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userInitial = useSelector(state => state.user?.userInitial);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    dispatch(showLoader());
    logOut(dispatch)
      .then(() => {
        dispatch(hideLoader());
        navigate('/');
      });
  };

  return (
    <div className='user-profile-menu'>
      <Tooltip title='Profile Options'>
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
          <Avatar className='nav-avatar'>{userInitial}</Avatar>
        </IconButton>
      </Tooltip>
      <Menu
        className='user-profile-menu'
        id='menu-appbar'
        sx={{ bgColor: 'red' }}
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
        onClick={handleCloseUserMenu}
        disableScrollLock={true}
      >
        <MenuItem component={Link} to={'/profile'}>
          <Typography sx={{ mt: 1, pl: 1, display: 'block' }} textAlign='left' className='profile-menu-item'>
            My Account
          </Typography>
        </MenuItem>
        <MenuItem component={Link} to={'#'} onClick={handleLogout}>
          <Typography textAlign='left' className='profile-menu-item' sx={{ pl: 1, display: 'block' }}>
            Logout
          </Typography>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default UserMenu;
