import React from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';

const SeriesCard = (props) => {
  const { series } = props;

  return (
    series && (
      <Card className='series-card'>
        <a href={`/series/${series._id}`}>
          <div className='series-info'>
            <div>
              <p className='series-info__title'>{series.title}</p>
              <p className='series-info__description'>{series.description}</p>
            </div>
          </div>
          <CardMedia component='img' src={series.thumbnail} alt='Video' />
        </a>
      </Card>
    )
  );
};

export default SeriesCard;
