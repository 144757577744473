import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { apiRequest } from '../../utils/general';
import Header from '../common/Header';
import VideoCard from '../video/VideoCard';
import LoadingHeader from '../common/LoadingHeader';

const Series = () => {
  const headerSize = { component: 'h5', variant: 'h5' };
  const [series, setSeries] = useState(null);
  const [seasonOptions, setSeasonOptions] = useState(null);
  const [selectedSeason, setSelectedSeason] = useState({});
  const [seasonVideos, setSeasonVideos] = useState(null);
  const [hasSubscription, setHasSubscription] = useState(false);
  const params = useParams();
  const seriesId = params.id;
  const currentUser = useSelector((state) => state.user);

  useEffect(() => {
    const getSeries = async () => {
      if (seriesId) {
        const response = await apiRequest(`playlists/series/${seriesId}`);
        if (response?.data?.length > 0) {
          setSeries(response.data[0]);
          setSeasonOptions(response.data[0]?.childPlaylists);
          setSelectedSeason(response.data[0]?.childPlaylists[0]);
        }
      }
    };
    getSeries();
  }, []);

  useEffect(() => {
    const expiredCheck =
      !currentUser ||
      currentUser?.subscription_expired ||
      Date.parse(currentUser.subscription_expires_date) - Date.parse(new Date()) < 0;
    setHasSubscription(!expiredCheck);
  }, [currentUser]);

  useEffect(() => {
    const getSeasonVideos = async () => {
      setSeasonVideos(null);
      const response = await apiRequest(`playlists/season/${selectedSeason.id}/videos`);
      if (response.data?.length > 0) {
        setSeasonVideos(response.data);
      }
    };
    if (!!selectedSeason?.id) {
      getSeasonVideos();
    }
  }, [selectedSeason]);

  const getVideoListItems = () => {
    const videoList = [];
    if (seasonVideos) {
      const sortedVideos = seasonVideos.sort((a, b) => a.episode - b.episode);
      for (const video of sortedVideos) {
        videoList.push(<VideoCard video={video} hasSubscription={hasSubscription}/>);
      }
    } else {
      for (let i = 0; i < 6; i++) {
        videoList.push(<VideoCard />);
      }
    }
    return videoList;
  };

  const getSeasonOptions = () => {
    const optionsList = [];
    if (seasonOptions) {
      const sortedSeasons = seasonOptions.sort((a, b) => a.priority - b.priority);
      for (const season of sortedSeasons) {
        optionsList.push(<option value={season.id}>{season.title}</option>);
      }
    }
    return optionsList;
  };

  return (
    <div className='browse-page'>
      {series ? <Header headerSize={headerSize} series={series} headerPage='browse' /> : <LoadingHeader />}
      <div className='series-video-grid'>
        <div className='full-col-span select-wrapper'>
          {seasonOptions ? (
            <select
              value={selectedSeason?.id}
              className='select'
              onChange={(e) => setSelectedSeason({ id: e.target.value })}
            >
              {getSeasonOptions()}
            </select>
          ) : null}
        </div>
        {getVideoListItems()}
      </div>
    </div>
  );
};
export default Series;
