import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { PUBLIC_PLAYER_API_KEY, PLAYER_EMBED_URL } from '../../utils/constants';
import { apiRequest } from '../../utils/general';
import { useSelector } from 'react-redux';

const VideoPlayer = () => {
  const params = useParams();
  const navigate = useNavigate();

  const id = params.id;
  const [showBackButton, setShowBackButton] = useState(false);
  const [playlistPath, setPlaylistPath] = useState('');
  const token = useSelector((state) => state.user?.token);

  const handleMouseEnter = (e) => {
    setShowBackButton(true);
  };

  const handleMouseLeave = (e) => {
    setShowBackButton(false);
  };

  const getSeriesId = async () => {
    const playlistRes = await apiRequest(`/playlists/video/${id}`);
    const playlistId = playlistRes.data.playlist_id;
    const parentId = playlistRes.data.parent_id;
    parentId ? setPlaylistPath(`/series/${parentId}`) : setPlaylistPath(`/series/${playlistId}`);
  };
  const handleExitClick = () => {
    navigate(`${playlistPath}`);
  };

  return (
    <div className='video-player-container'>
      <div className='video-iframe-container' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {showBackButton && (
          <div className='video-inner-hover'>
            <a onClick={handleExitClick} className='flex-center'>
              <i className='fa-solid fa-x'></i>
            </a>
          </div>
        )}
        <iframe
          onLoad={getSeriesId}
          frameBorder='0'
          loading='lazy'
          src={`${PLAYER_EMBED_URL}${id}.iframe?api_key=${PUBLIC_PLAYER_API_KEY}&access_token=${token}&autoplay=true&controls=true`}
          className='note-video-clip'
          allowFullScreen='allowfullscreen'
          audio='1'
        ></iframe>
      </div>
    </div>
  );
};

export default VideoPlayer;
