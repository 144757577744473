import React from 'react';

const WRModal = (props) => {
  const { onClose, isOpen, children, noVeil, title, className } = props;

  const closeModal = () => {
    if (!!onClose) {
      onClose();
    }
  };

  return (
    <div className={`modal-container ${isOpen ? '--visible' : ''} ${noVeil ? '--no-veil' : ''}`}>
      <button className='veil-area' onClick={() => closeModal()} />
      <div className={`wr-modal ${className ?? ''}`}>
        <button className='wr-modal__close-button' onClick={() => closeModal()}>
        <i className="fa-solid fa-x"></i>
        </button>
        {title && <h2>{title}</h2>}
        <div className='wr-modal__body'>{children}</div>
      </div>
    </div>
  );
};

export default WRModal;
