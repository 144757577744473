import React from 'react';
import { Grid } from '@material-ui/core';
import CategoryRow from './CategoryRow';
import Carousel from './Carousel';

const Browse = () => {
  return (
    <main>
      <Grid container direction='column' columns={{ xs: 4, md: 12 }} className='browse-container'>
        <Carousel />
        <CategoryRow url={'playlists/series/category/Genre/Totally Rodeo'} title='Totally Rodeo' />
        <CategoryRow url={'playlists/series/category/Genre/Rodeo Plus'} title='Rodeo Plus' />
        <CategoryRow url={'playlists/series/category/Genre/Inspirational'} title='Inspirational' />
        <CategoryRow
          url={'playlists/series/category/Genre/Relationships - Recipes - Rhythm'}
          title='Relationships - Recipes - Rhythm'
        />
        <CategoryRow
          url={'playlists/series/category/Genre/Equine & Cattle Industry'}
          title='Equine & Cattle Industry'
        />
        <CategoryRow
          url={'playlists/series/category/Genre/Big Rodeos - Bull Riders - Broncs'}
          title='Big Rodeos - Bull Riders - Broncs'
        />
        <CategoryRow url={'playlists/series/category/Genre/Sites & Songs'} title='Sites & Songs' />
        <CategoryRow url={'playlists/series/category/Genre/Classic Cowboy Moments'} title='Classic Cowboy Moments' />
        <CategoryRow url={'playlists/series/category/Genre/Vintage Movies'} title='Vintage Movies' />

        {/* MUI acts goofy when the last category has 1 object, I have 0 idea why, this is a dumb fix -GL */}
        <div style={{ marginBottom: '100px' }} />
      </Grid>
    </main>
  );
};
export default Browse;
