import { apiRequest } from '../utils/general';
import { updateUser, clearUser } from '../store/userSlice';
import { clearAlerts, createAlert } from '../store/alertSlice';
import { clearNotifications } from '../store/notificationSlice';

export const login = async (data, dispatch) => {
  try {
    const signInRes = await apiRequest('users/sign-in', 'post', data);
    const signInData = signInRes.data.data;
    const { subscription_expired, subscription_expires_date, payment_failed, access_token, initial } = signInData;
    dispatch(updateUser({ subscription_expired, subscription_expires_date, payment_failed, access_token, initial }));
    dispatch(createAlert({ message: 'Logged in successfully.', type: 'success' }));
    return signInData;
  } catch (e) {
    throw e;
  }
};

export const logOut = async (dispatch) => {
  try {
    const res = await apiRequest('users/logout', 'post');
    dispatch(clearUser());
    dispatch(clearAlerts());
    dispatch(clearNotifications());
    return res;
  } catch (e) {
    throw e;
  }
};

export const register = async (data, dispatch) => {
  try {
    const res = await apiRequest('users/sign-up', 'post', data);
    const signInData = res.data.data;
    const { subscription_expired, subscription_expires_date, payment_failed, access_token, initial } = signInData;
    dispatch(updateUser({ subscription_expired, subscription_expires_date, payment_failed, access_token, initial }));
    dispatch(createAlert({ message: 'Signed up successfully.', type: 'success' }));
    return signInData;
  } catch (e) {
    throw e;
  }
};
export const forgotPassword = async (data) => {
  try {
    const res = await apiRequest('users/password/forgot', 'post', data);
    return res;
  } catch (e) {
    throw e;
  }
};
export const resetPassword = async (data) => {
  try {
    const res = await apiRequest('users/password/reset', 'post', data);
    return res;
  } catch (e) {
    throw e;
  }
};
