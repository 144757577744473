import React from 'react';
import Skeleton from '@mui/material/Skeleton';

// This should be part of the header component, and be loaded when header doesn't have data...
// However the header component at time of writing needs a big old rewrite so here we are.
const LoadingHeader = () => {
  return (
    <section className={'header-section'}>
      <div className={'header-overlay'}>
        <div className="header-title-box">
          <div className="widescreen-thumbnail">
            <Skeleton variant="rectangular" animation="wave" className="skeleton video-skeleton --series" />
          </div>
          <p>
            <Skeleton className="skeleton" width={'100%'} height={'2rem'} variant="text" animation="wave" />
          </p>
          <p>
            <Skeleton
              className="skeleton"
              width={80 + Math.floor(Math.random() * 10) + '%'}
              variant="text"
              animation="wave"
            />
            <Skeleton
              className="skeleton"
              width={80 + Math.floor(Math.random() * 10) + '%'}
              variant="text"
              animation="wave"
            />
            <Skeleton
              className="skeleton"
              width={80 + Math.floor(Math.random() * 10) + '%'}
              variant="text"
              animation="wave"
            />
            <Skeleton
              className="skeleton"
              width={80 + Math.floor(Math.random() * 10) + '%'}
              variant="text"
              animation="wave"
            />
            <Skeleton
              className="skeleton"
              width={80 + Math.floor(Math.random() * 10) + '%'}
              variant="text"
              animation="wave"
            />
            <Skeleton
              className="skeleton"
              width={80 + Math.floor(Math.random() * 10) + '%'}
              variant="text"
              animation="wave"
            />
            <Skeleton
              className="skeleton"
              width={80 + Math.floor(Math.random() * 10) + '%'}
              variant="text"
              animation="wave"
            />
          </p>
        </div>
      </div>
    </section>
  );
};

export default LoadingHeader;
