import React from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import * as Yup from 'yup';
import { createAlert } from '../../store/alertSlice';
import WRModal from '../general/WRModal';
import { useFormik } from 'formik';
import { showLoader, hideLoader } from '../../store/loaderSlice';
import { ERROR_500 } from '../../utils/errors';
import { useDispatch } from 'react-redux';
import { apiRequest } from '../../utils/general';
import { useParams } from 'react-router-dom';

const changeEmailModal = (props) => {
  const { isModalOpened, onCloseModal } = props;
  const dispatch = useDispatch();
  const params = useParams();

  const initialValues = {
    email: '',
    email_confirmation: '',
  };

  const handleUpdate = (formValue) => {
    formValue.token = props.accessToken;

    dispatch(showLoader());
    apiRequest(`users/profile/${params.email_verification_token}/change-email`, 'patch', formValue)
      .then((res) => {
        dispatch(hideLoader());
        dispatch(
          createAlert({
            message: 'Updated Email successfully.',
            type: 'success',
          })
        );
        props.onCloseModal();
      })
      .catch((e) => {
        dispatch(hideLoader());
        e = e.response;
        if (e.status == 401) {
          dispatch(
            createAlert({
              message: `Email already associated with some account.`,
              type: 'error',
            })
          );
        } else {
          dispatch(createAlert({ message: ERROR_500, type: 'error' }));
        }
      });
  };

  const onModalClose = () => {
    formik.resetForm();
    onCloseModal();
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      email: Yup.string('Enter your email').email('Enter a valid email').required('Email is required'),
      email_confirmation: Yup.string()
        .required('Please retype your email.')
        .oneOf([Yup.ref('email')], 'Your emails do not match.'),
    }),
    onSubmit: (values) => {
      handleUpdate(values);
    },
  });

  return (
    <WRModal isOpen={isModalOpened} title=' Change your email address' onClose={onModalClose}>
      <form noValidate onSubmit={formik.handleSubmit} className='info-form format-errors'>
        <TextField
          autoComplete='email'
          name='email'
          required
          fullWidth
          id='email'
          label='Enter your new email address'
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          variant='outlined'
          className='grid-item-full-width'
        />
        <TextField
          autoComplete='email_confirmation'
          name='email_confirmation'
          required
          fullWidth
          id='email_confirmation'
          label='Re-enter your email address'
          value={formik.values.email_confirmation}
          onChange={formik.handleChange}
          error={formik.touched.email_confirmation && Boolean(formik.errors.email_confirmation)}
          helperText={formik.touched.email_confirmation && formik.errors.email_confirmation}
          variant='outlined'
          className='grid-item-full-width'
        />
        <button type='submit' className='wild-rides-button --half-width grid-item-full-width'>
          Save
        </button>
        <div className='flex-center grid-item-full-width auth-bottom-text'>
          <a to='/profile' className='text-white' onClick={onModalClose}>
            Cancel
          </a>
        </div>
      </form>
    </WRModal>
  );
};

export default changeEmailModal;
