import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import * as Yup from 'yup';
import { createAlert } from '../../store/alertSlice';
import { useFormik } from 'formik';
import { ERROR_500 } from '../../utils/errors';
import { useDispatch } from 'react-redux';
import { apiRequest } from '../../utils/general';
import WRModal from '../general/WRModal';

const ProfileModal = (props) => {
  const { user, accessToken, onCloseModal, isProfileModalOpened } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    formik.setFieldValue('first_name', user.first_name ?? '');
    formik.setFieldValue('last_name', user.last_name ?? '');
    formik.setFieldValue('address', user.address ?? '');
    formik.setFieldValue('city', user.city ?? '');
    formik.setFieldValue('state', user.state ?? '');
    formik.setFieldValue('zip', user.zip ?? '');
    formik.setFieldValue('birthday', user.birthday ?? '');
  }, [user]);

  const initialValues = {
    first_name: '',
    last_name: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    birthday: '',
  };

  const handleUpdate = (formValue) => {
    formValue.token = accessToken;

    apiRequest('users/profile', 'patch', formValue)
      .then((res) => {
        dispatch(
          createAlert({
            message: 'Updated profile successfully.',
            type: 'success',
          })
        );
        onCloseModal();
      })
      .catch((e) => {
        console.error(e);
        if (typeof e === 'string') {
          e = JSON.parse(e);
        }
        if (e.status == 401) {
          dispatch(createAlert({ message: `${e.data.error}`, type: 'error' }));
        } else {
          dispatch(createAlert({ message: ERROR_500, type: 'error' }));
        }
      });
  };

  const handleModalClose = () => {
    formik.resetForm();
    onCloseModal();
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      first_name: Yup.string('Enter your first name').required('First Name is required'),
      last_name: Yup.string('Enter your last name').required('Last Name is required'),
      birthday: Yup.date()
        .required('Birthday is required')
        .test('birthdayPast', 'Birthday is too far in the past', (value, context) => {
          return new Date(value).getFullYear() > new Date().getFullYear() - 120;
        })
        .max(new Date(), 'Birthday cannot be in the future'),
    }),
    onSubmit: (values) => {
      handleUpdate(values);
    },
  });

  return (
    <WRModal isOpen={isProfileModalOpened} title='Edit Personal Info' onClose={handleModalClose}>
      <form noValidate onSubmit={formik.handleSubmit} className='info-form format-errors'>
        <div className='grid-item-full-width'>
          <TextField
            autoComplete='first_name'
            name='first_name'
            required
            fullWidth
            id='first_name'
            label='First Name'
            value={formik.values.first_name}
            onChange={formik.handleChange}
            error={formik.touched.first_name && Boolean(formik.errors.first_name)}
            helperText={formik.touched.first_name && formik.errors.first_name}
            variant='outlined'
          />
        </div>
        <div className='grid-item-full-width'>
          <TextField
            autoComplete='last_name'
            name='last_name'
            required
            fullWidth
            id='last_name'
            label='Last Name'
            value={formik.values.last_name}
            onChange={formik.handleChange}
            error={formik.touched.last_name && Boolean(formik.errors.last_name)}
            helperText={formik.touched.last_name && formik.errors.last_name}
            variant='outlined'
          />
        </div>
        <div className='grid-item-full-width'>
          {/* <DateRange /> */}
          <TextField
            autoComplete='birthday'
            type='date'
            name='birthday'
            required
            fullWidth
            id='birthday'
            label='Birthday (We will send you fun birthday promos!)'
            value={formik.values.birthday}
            onChange={formik.handleChange}
            error={formik.touched.birthday && Boolean(formik.errors.birthday)}
            helperText={formik.touched.birthday && formik.errors.birthday}
            variant='outlined'
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <button type='submit' className='wild-rides-button --half-width grid-item-full-width'>
          Save
        </button>
        <div className='flex-center grid-item-full-width auth-bottom-text'>
          <a to='/profile' className='text-white' onClick={handleModalClose}>
            Cancel
          </a>
        </div>
      </form>
    </WRModal>
  );
};

export default ProfileModal;
