import { createSlice } from '@reduxjs/toolkit';

const alertSlice = createSlice({
  name: 'alert',
  initialState: {
    alerts: [],
  },
  reducers: {
    createAlert: (state, action) => {
      const { payload } = action;
      state.alerts.push({
        message: payload.message,
        type: payload.type,
      });
    },
    clearAlerts: (state) => {
      state.alerts = [];
    },
  },
});

export const { createAlert, clearAlerts } = alertSlice.actions;
const { reducer } = alertSlice;
export default reducer;
