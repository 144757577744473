import React from 'react';
import TextField from '@mui/material/TextField';
import * as Yup from 'yup';
import { createAlert } from '../../store/alertSlice';
import { useFormik } from 'formik';
import { showLoader, hideLoader } from '../../store/loaderSlice';
import { ERROR_500 } from '../../utils/errors';
import { useDispatch } from 'react-redux';
import { apiRequest } from '../../utils/general';
import WRModal from './WRModal';

const NewsletterModal = (props) => {
  const { isOpen, onClose } = props;
  const dispatch = useDispatch();

  const initialValues = {
    first_name: '',
    last_name: '',
    email: '',
  };

  const submitForm = async (formValues) => {
    try {
      dispatch(showLoader());
      await apiRequest('coming-soon/sign-up', 'post', formValues);
      dispatch(hideLoader());
      dispatch(createAlert({ message: 'Registered successfully!', type: 'success' }));
      formik.resetForm();
      onClose();
    } catch (e) {
      console.error(e);
      dispatch(hideLoader());
      if (e.response.status == 422) {
        dispatch(createAlert({ message: 'Email already signed up', type: 'error' }));
      } else {
        dispatch(createAlert({ message: ERROR_500, type: 'error' }));
      }
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      first_name: Yup.string('Enter your first name').required('First Name is required'),
      last_name: Yup.string('Enter your last name').required('Last Name is required'),
      email: Yup.string('Enter your email address').email('Enter a valid email').required('Email is required'),
    }),
    onSubmit: (values) => {
      submitForm(values);
    },
  });

  return (
    <WRModal isOpen={isOpen} title='Sign up for our E-Newsletter' onClose={onClose}>
      <div style={{ width: '100%', textAlign: 'center' }}>
        <p style={{ width: '80%', color: 'white', textAlign: 'center', margin: '0 auto 32px auto' }}>
          Be the first to get exclusive Wild Ride TV updates by signing up for our e-newsletter below!
        </p>
      </div>
      <form className='info-form format-errors' noValidate onSubmit={formik.handleSubmit}>
        <TextField
          autoComplete='first_name'
          name='first_name'
          required
          fullWidth
          id='first_name'
          label='First Name'
          value={formik.values.first_name}
          onChange={formik.handleChange}
          error={formik.touched.first_name && Boolean(formik.errors.first_name)}
          helperText={formik.touched.first_name && formik.errors.first_name}
          variant='outlined'
        />
        <TextField
          autoComplete='last_name'
          name='last_name'
          required
          fullWidth
          id='last_name'
          label='Last Name'
          value={formik.values.last_name}
          onChange={formik.handleChange}
          error={formik.touched.last_name && Boolean(formik.errors.last_name)}
          helperText={formik.touched.last_name && formik.errors.last_name}
          variant='outlined'
        />
        <TextField
          autoComplete='email'
          name='email'
          required
          fullWidth
          id='email'
          label='Email'
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          variant='outlined'
          className='grid-item-full-width'
        />
        <button type='submit' className='wild-rides-button --half-width grid-item-full-width'>
          Save
        </button>
        <div className='flex-center grid-item-full-width auth-bottom-text'>
          <a to='/profile' className='text-white' onClick={onClose}>
            Cancel
          </a>
        </div>
      </form>
    </WRModal>
  );
};

export default NewsletterModal;
