import React, { useState, useEffect } from 'react';
import Header from '../common/Header';
import { Grid } from '@material-ui/core';
import VideoCardList from './VideoCardList';
import LogoFlexBox from './LogoFlexBox';
import TextField from '@mui/material/TextField';
import Button from '@material-ui/core/Button';
import { Avatar } from '@material-ui/core';
import signupButtonIcon from '../../../assets/images/header-signup.png';
import { apiRequest } from '../../utils/general';
import { COWBOY_MOMENTS_SERIES_ID } from '../../utils/constants';

const Home = () => {
  const [email, setEmail] = useState('');

  const handleSignupButtonClick = () => {
    window.location.replace(`/sign-up?email=${encodeURIComponent(email)}`);
  };

  return (
    <main>
      <Grid container direction='column' columns={{ xs: 4, md: 12 }}>
        <Header headerPage='home' />
        <div className='where-to-watch-container'>
          <div className='where-to-watch-content'>
            <div className='watch-text'>
              <h2>Where to Watch Wild Rides TV</h2>
              <p>
                We are committed to making Wild Rides TV available on every device and platform. Stream on up to 3
                devices at a time.
              </p>
            </div>
          </div>
          <LogoFlexBox />
          <p className='paragraph-text'>
            Wild Rides is a streaming platform 100% devoted to rodeo and everything western.
          </p>
          <div className='header-signup-form'>
            <TextField
              id='standard-name'
              variant='outlined'
              placeholder='Email Address'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Button
              aria-label='Add'
              edge='end'
              variant='contained'
              onClick={handleSignupButtonClick}
              className='button-add text-white'
              endIcon={
                <Avatar
                  variant='square'
                  src={signupButtonIcon}
                  imgProps={{
                    style: {
                      maxHeight: '50%',
                      maxWidth: '100%',
                      objectFit: 'cover',
                    },
                  }}
                />
              }
            >
              Sign Up Now
            </Button>
            <p className='subtext'>Free trial offer valid for new and eligible returning subscribers only.</p>
          </div>
        </div>
        <VideoCardList comingSoon={false} />
      </Grid>
    </main>
  );
};

export default Home;
