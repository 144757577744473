// Run this example by adding <%= javascript_pack_tag 'hello_react' %> to the head of your layout file,
// like app/views/layouts/application.html.erb. All it does is render <div>Hello React</div> at the bottom
// of the page.

import React, { useLayoutEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import App from '../components/App';
import { store, persistor } from '../store/store';
import { Provider } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Pricing from '../components/pricing/Pricing';
import VideoPlayer from '../components/video/VideoPlayer';
import SignUp from '../components/Auth/Signup';
import ForgotPassword from '../components/Auth/forgot_password/ForgotPassword';
import ResetPassword from '../components/Auth/reset_password/ResetPassword';
import SignUpPayment from '../components/Auth/SignupPayment';
import AnonymousRoute from '../components/routes/AnonymousRoute';
import ProtectedRoute from '../components/routes/ProtectedRoute';
import Signin from '../components/Auth/signin/Signin';
import ComingSoon from '../components/coming_soon/ComingSoon';
import NotFound from '../components/general/NotFound';
import Home from '../components/home/Home';
import Profile from '../components/user/Profile';
import TermsAndConditions from '../components/pages/TermsAndConditions';
import Browse from '../components/browse/Browse';
import PurchaseVideoWrapper from '../components/video/PurchaseVideoWrapper';
import Series from '../components/series/Series';
import ContactUs from '../components/pages/ContactUs';
import { apiRequest } from '../utils/general';
import { PrivacyPolicy } from '../components/pages/PrivacyPolicy';
import { PersistGate } from 'redux-persist/lib/integration/react';
import NewsletterSignup from '../components/pages/NewsletterSignup';
import GiftingWildRides from '../components/gift/GiftingWildRides';
import GiftPlan from '../components/gift/GiftPlan';
import GiftWrapper from '../components/gift/GiftWrapper';
import Alert from '../../javascript/components/Alert';
import Loader from '../../javascript/components/Loader';
// import MyPurchases from "../components/user/MyPurchases";
// import MyWatchList from '../components/user/MyWatchlist';

const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

document.addEventListener('DOMContentLoaded', () => {
  apiRequest('keys/stripe-publishable')
    .then((response) => {
      const stripePromise = loadStripe(response.data.data);
      const container = document.getElementById('root');
      const root = createRoot(container);
      root.render(
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <Elements stripe={stripePromise}>
              <Alert />
              <Loader />
              <BrowserRouter>
                <Wrapper>
                  <Routes>
                    {/* These do not wrap the page with our app stuff - GL*/}
                    {/* these routes explicitly require the user to NOT be logged in - GL */}
                    <Route element={<AnonymousRoute />}>
                      <Route path='/sign-up' element={<SignUp />} />
                    </Route>

                    {/* these routes explicitly require the user to be logged - GL*/}
                    <Route element={<ProtectedRoute />}>
                      <Route path='/user/subscribe' element={<SignUpPayment />} />
                      <Route path='/user/video/:video_id/subscribe' element={<SignUpPayment />} />
                      <Route path='/video/:id' element={<VideoPlayer />} />
                    </Route>

                    {/* these routes are public - GL*/}
                    <Route path='/coming-soon' element={<ComingSoon />} />
                    <Route path='/gift' element={<GiftWrapper />} />
                    {/* these routes wrap the pages with our nav and stuff, and have their own permissioning again - GL*/}
                    <Route path='/' element={<App />}>
                      {/* these routes explicitly require the user to NOT be logged in - GL */}
                      <Route element={<AnonymousRoute />}>
                        <Route index element={<Home />} />
                        <Route path='/sign-in' element={<Signin />} />
                        <Route path='/forgot-password' element={<ForgotPassword />} />
                      </Route>

                      {/* these routes explicitly require the user to be logged in */}
                      <Route element={<ProtectedRoute />}>
                        <Route path='/user/:id/subscribe' element={<SignUpPayment />} />
                        <Route path='/user/:id/video/:video_id/subscribe' element={<SignUpPayment />} />
                        <Route path='/browse' element={<Browse />} />
                        <Route path='/profile' element={<Profile />} />
                        <Route path='/profile/edit' element={<Profile />} />
                        <Route path='/profile/:email_verification_token/change-email' element={<Profile />} />
                        <Route path='/card/create' element={<Profile />} />
                        <Route path='/video/:id/subscribe' element={<PurchaseVideoWrapper />} />
                        <Route path='/series/:id' element={<Series />} />
                        {/* <Route path="/my-purchases" element={<MyPurchases />} />
                            <Route exact path="/my-watchlist" element={<MyWatchList />} /> */}
                      </Route>

                      {/* these routes are public - GL*/}

                      <Route path='/gift-plan' element={<GiftPlan />} />
                      <Route path='/gifting-wild-rides' element={<GiftingWildRides />} />
                      <Route path='/pricing' element={<Pricing />} />
                      <Route path='/contact-us' element={<ContactUs />} />
                      <Route path='/newsletter' element={<NewsletterSignup />} />
                      <Route path='/reset-password/:token' element={<ResetPassword />} />
                      <Route path='/terms-and-conditions' element={<TermsAndConditions />} />
                      <Route path='/privacy-policy' element={<PrivacyPolicy />} />
                    </Route>
                    <Route path='*' element={<NotFound />} />
                  </Routes>
                </Wrapper>
              </BrowserRouter>
            </Elements>
          </PersistGate>
        </Provider>
      );
    })
    .catch((err) => console.error(err));
});
