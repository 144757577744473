import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { register } from '../../services/auth.service';
import { createAlert } from '../../store/alertSlice';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import SignupValidationSchema from '../validation/SignupRules';
import { showLoader, hideLoader } from '../../store/loaderSlice';
import Alert from '../Alert';
import Loader from '../Loader';
import { useSearchParams, Link } from 'react-router-dom';
import { apiRequest } from '../../utils/general';

const termsCheckboxLabel = (
  <div>
    I agree to the <a href='/terms-and-conditions'>terms & conditions</a> of Wild Rides Television*
  </div>
);

export default function SignUp() {
  const dispatch = useDispatch();
  const [logoUrl, setLogoUrl] = useState('');
  const [backgroundVideoUrl, setBackgroundVideoUrl] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const [videoId, setVideoId] = useState();

  useEffect(() => {
    if (searchParams.get('video_id')) {
      setVideoId(searchParams.get('video_id'));
    }

    dispatch(showLoader());

    apiRequest('users/sign-up-background')
      .then((res) => {
        dispatch(hideLoader());
        setBackgroundVideoUrl(res.data.video_url);
      })
      .catch((err) => console.error(err));

    apiRequest('users/sign-up-logo')
      .then((res) => {
        dispatch(hideLoader());
        setLogoUrl(res.data.logo_url);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      email:
        !!decodeURIComponent(searchParams.get('email')) && decodeURIComponent(searchParams.get('email')) !== 'null'
          ? decodeURIComponent(searchParams.get('email'))
          : '',
      password: '',
      password_confirmation: '',
      terms_and_condition: false,
      is_over_18: false,
      receive_updates: false,
    },
    validationSchema: SignupValidationSchema,
    onSubmit: (values) => {
      handleSignup(values);
    },
  });

  const handleSignup = async (data) => {
    try {
      dispatch(showLoader());
      await register(data, dispatch);
      if(data.receive_updates){
        apiRequest('coming-soon/sign-up', 'post', data);
      }
      dispatch(hideLoader());
      if (videoId) {
        window.location.replace(`/user/video/${videoId}/subscribe`);
      } else {
        window.location.replace(`/user/subscribe`);
      }
    } catch (err) {
      dispatch(hideLoader());
      console.error(err);
      if (typeof err === 'string') {
        err = JSON.parse(err);
      }
      if (err.status == 422) {
        dispatch(createAlert({ message: 'Email already taken!', type: 'error' }));
      } else {
        dispatch(createAlert({ message: 'An error occured!', type: 'error' }));
      }
    }
  };

  return (
    <>
      <Alert />
      <Loader />
      <div className='video-background'>
        <video autoPlay playsInline loop muted src={backgroundVideoUrl} type='video/mp4' />
        <div className='fade-bottom'></div>
      </div>
      <div className='signup-main-container info-form'>
        <Container component='main' maxWidth='sm' className='signup-inner-container'>
          <Box className='signup-box'>
            <img className='signup-logo' src={logoUrl} alt='signup logo' width='80%' />
            <Typography component='h1' variant='h5' className='text-white text-center shadow-text'>
              {videoId ? 'Signup to buy this episode' : "We're glad you've decided to join us!"}
            </Typography>
            <Box sx={{ mt: 3 }}>
              <form noValidate onSubmit={formik.handleSubmit} className='format-errors'>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      autoComplete='given-name'
                      name='first_name'
                      required
                      fullWidth
                      id='first_name'
                      label='First Name'
                      value={formik.values.first_name}
                      onChange={formik.handleChange}
                      error={formik.touched.first_name && Boolean(formik.errors.first_name)}
                      helperText={formik.touched.first_name && formik.errors.first_name}
                      autoFocus
                      variant='outlined'
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      autoComplete='last-name'
                      name='last_name'
                      required
                      fullWidth
                      id='last_name'
                      label='Last Name'
                      onChange={formik.handleChange}
                      error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                      helperText={formik.touched.last_name && formik.errors.last_name}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      required
                      id='email'
                      name='email'
                      label='Email'
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      error={formik.touched.email && Boolean(formik.errors.email)}
                      helperText={formik.touched.email && formik.errors.email}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      required
                      id='password'
                      name='password'
                      label='Password'
                      type='password'
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      error={formik.touched.password && Boolean(formik.errors.password)}
                      helperText={formik.touched.password && formik.errors.password}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      name='password_confirmation'
                      label='Re-enter Password'
                      type='password'
                      id='password_confirmation'
                      autoComplete='password_confirmation'
                      value={formik.values.password_confirmation}
                      onChange={formik.handleChange}
                      error={formik.touched.password_confirmation && Boolean(formik.errors.password_confirmation)}
                      helperText={formik.touched.password_confirmation && formik.errors.password_confirmation}
                    />
                  </Grid>
                  <Grid item xs={12} className='signup-checkbox'>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id='terms_and_condition'
                          name='terms_and_condition'
                          value={formik.values.terms_and_condition}
                          onChange={formik.handleChange}
                          required
                          checked={formik.values.terms_and_condition}
                          sx={{
                            color: 'white',
                            '&.Mui-checked': {
                              color: 'white',
                            },
                          }}
                        />
                      }
                      id='terms_and_condition'
                      name='terms_and_condition'
                      label={termsCheckboxLabel}
                    />
                    {formik.errors.terms_and_condition && formik.touched.terms_and_condition && (
                      <FormHelperText error>
                        {formik.errors.terms_and_condition && formik.touched.terms_and_condition
                          ? formik.errors.terms_and_condition
                          : ' '}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={12} margin={0} className='signup-checkbox'>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id='is_over_18'
                          name='is_over_18'
                          value={formik.values.is_over_18}
                          onChange={formik.handleChange}
                          required
                          checked={formik.values.is_over_18}
                          sx={{
                            color: 'white',
                            '&.Mui-checked': {
                              color: 'white',
                            },
                          }}
                        />
                      }
                      id='is_over_18'
                      name='is_over_18'
                      label='I am 18 years or older*'
                    />
                    {formik.errors.is_over_18 && formik.touched.is_over_18 && (
                      <FormHelperText error>
                        {formik.errors.is_over_18 && formik.touched.is_over_18 ? formik.errors.is_over_18 : ' '}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={12} className='signup-checkbox'>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id='receive_updates'
                          name='receive_updates'
                          value={formik.values.receive_updates}
                          onChange={formik.handleChange}
                          required
                          checked={formik.values.receive_updates}
                          sx={{
                            color: 'white',
                            '&.Mui-checked': {
                              color: 'white',
                            },
                          }}
                        />
                      }
                      id='receive_updates'
                      name='receive_updates'
                      label='I would like to receive news, updates and promotional material from Wild Rides Television via email'
                    />
                    {formik.errors.receive_updates && formik.touched.receive_updates && (
                      <FormHelperText error>
                        {formik.errors.receive_updates && formik.touched.receive_updates
                          ? formik.errors.receive_updates
                          : ' '}
                      </FormHelperText>
                    )}
                  </Grid>
                  <span className='required-label text-white'>*Required</span>
                </Grid>
                <Button
                  type='submit'
                  fullWidth
                  variant='contained'
                  sx={{ mt: 3, mb: 2, p: 2 }}
                  color='inherit'
                  className='signup-button'
                >
                  Next
                </Button>
                <div className='auth-bottom-text'>
                  <Link to='/browse'>Cancel</Link>
                </div>
              </form>
            </Box>
          </Box>
        </Container>
      </div>
    </>
  );
}
