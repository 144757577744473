import React, { useState } from 'react';
import { logOut } from '../../services/auth.service';
import { useDispatch } from 'react-redux';
import { showLoader, hideLoader } from '../../store/loaderSlice';
import { clearUser } from '../../store/userSlice';
import { useNavigate } from 'react-router-dom';
import Avatar from './Avatar';
import arrow from '../../../assets/images/mobile-menu-arrow.svg';

const MobileUserMenu = (props) => {
  const { onMenuClose } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showUserMenu, setShowUserMenu] = useState(false);

  const userPages = [
    {
      label: 'My Account',
      url: '/profile',
    },
    {
      label: 'Logout',
      url: '/',
    },
  ];
  const handleLogout = () => {
    dispatch(showLoader());
    logOut(dispatch).then(() => {
      onMenuClose();
      dispatch(hideLoader());
      navigate('/');
    });
  };
  return (
    <div className='mobile-user-menu'>
      <Avatar></Avatar>
      <span className='mobile-menu__nav' onClick={() => setShowUserMenu(!showUserMenu)}>
        Your Account
        <img src={arrow} className='mobile-user-menu__img-padding' alt='mobile menu arrow' />
      </span>
      {showUserMenu && (
        <div className='mobile-menu__nav grid-item-full-width'>
          <ul className='--padding-mod'>
            {userPages.map((page) => (
              <li key={page.label}>
                <a
                  href={page.label !== 'Logout' && page.url}
                  className={window.location.pathname == page.url ? 'text-orange' : 'text-white'}
                  onClick={() => page.label === 'Logout' && handleLogout()}
                >
                  {page.label}
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default MobileUserMenu;
