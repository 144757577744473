import swal from 'sweetalert';
import { forgotPassword } from '../services/auth.service';
import { ERROR_500 } from './errors';
import { store } from '../store/store';
import { createAlert } from '../store/alertSlice';
import { createNotification } from '../store/notificationSlice';
import { NOTIFICATIONS } from '../utils/constants';

export const sendResetPassword = async (email) => {
  try {
    await forgotPassword({ email });
    swal({
      title: 'Success!',
      text: `A password reset email was sent to ${email}. Use the email link to reset your password`,
      icon: 'success',
      buttons: {
        cancel: 'Okay!',
      },
    });
  } catch (err) {
    console.error(err);
    if (err.response.status == 422) {
      // almost positive this wont work unfortunately -GL
      store.dispatch(
        createAlert({
          message: `${err.response.data.error}`,
          type: 'error',
        })
      );
    } else {
      store.dispatch(createAlert({ message: ERROR_500, type: 'error' }));
    }
  }
};

export const cardExpiryCalc = (dateToday, card) => {
  if (card?.exp_year && dateToday.getFullYear() > card.exp_year) {
    store.dispatch(createNotification(NOTIFICATIONS.EXPIRED));
  }
  if (card?.exp_year && dateToday.getFullYear() === card.exp_year) {
    if (card.exp_month - (dateToday.getMonth() + 1) <= 0) {
      store.dispatch(createNotification(NOTIFICATIONS.EXPIRED));
    } else if (card.exp_month - (dateToday.getMonth() + 1) <= 2) {
      store.dispatch(createNotification(NOTIFICATIONS.EXPIRES_SOON));
    }
  }
};

export const checkForNotifications = (payment_failed, cards) => {
  if (payment_failed) {
    store.dispatch(createNotification(NOTIFICATIONS.PAYMENT_FAILED));
  }
  if (cards) {
    const primaryCard = cards[0];
    const dateToday = new Date();
    cardExpiryCalc(dateToday, primaryCard);
  }
};
