import React, { useState } from "react";
import { PLAYER_EMBED_URL, PUBLIC_PLAYER_API_KEY } from "../../utils/constants";
import Skeleton from "@mui/material/Skeleton";

const VideoPlaceholder = (props) => {
  const [loading, setLoading] = useState(true);
  return (
    <>
      <div className="video-placeholder">
        {loading && (
          <Skeleton
            className="video-skeleton"
            sx={{ bgcolor: "grey.900" }}
            variant="rectangular"
          />
        )}
        {props.id && (
          <iframe
            id={props.id}
            className="iframe"
            frameBorder="0"
            loading="lazy"
            title="Promo video"
            src={`${PLAYER_EMBED_URL}${props.id}.iframe?api_key=${PUBLIC_PLAYER_API_KEY}&controls=true`}
            allowFullScreen="allowfullscreen"
            onLoad={() => setLoading(false)}
            audio="1"
          />
        )}
      </div>
    </>
  );
};

export default VideoPlaceholder;
