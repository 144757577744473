import React from 'react';

const TermsAndConditions = () => {
  return (
    <div className='info-page --wide'>
      <div className='terms-and-conditions-div'>
        <h1>Terms &amp; Conditions</h1>
        <br />
        <br />
        <p>
          Prairie Rose Productions provides a personalized subscription service that allows our members to access
          entertainment content ("Wild Rides Television Network) over the Internet on certain Internet-connected TV's,
          computers and other devices ("Wild Rides Television Network").
        </p>
        <p>You have accepted these Terms of Use, which govern your use of our service.</p>
        <p>
          As used in these Terms of Use, "Wild Rides Television Network & Prairie Rose Productions service", "our
          service" or "the service" means the personalized service provided by Wild Rides Television Network & Prairie
          Rose Productions for discovering and accessing Wild Rides Television Network & Prairie Rose Productions
          content, including all features and functionalities, recommendations and reviews, our websites, and user
          interfaces, as well as all content and software associated with our service.
        </p>
        <p>
          YOU AGREE TO THE ARBITRATION AGREEMENT AND CLASS ACTION WAIVER DESCRIBED IN SECTION 7 TO RESOLVE ANY DISPUTES
          WITH Wild Rides Television Network & Prairie Rose Productions (EXCEPT FOR MATTERS THAT MAY BE TAKEN TO SMALL
          CLAIMS COURT).
        </p>
        <br />
        <ol>
          <li>
            <strong>Membership</strong>
            <ol>
              <li>
                <p>
                  Your Wild Rides Television Network & Prairie Rose Productions membership will continue and
                  automatically renew until terminated. To use the Wild Rides Television Network & Prairie Rose
                  Productions service you must have Internet access and a Wild Rides Television Network ready device and
                  provide us with one or more Payment Methods. "Payment Method" means a current, valid, accepted method
                  of payment, as may be updated from time to time and which may include payment through your account
                  with a third party. You must cancel your membership before it renews in order to avoid billing of the
                  membership fees for the next billing cycle to your Payment Method (see "Cancellation" below).
                </p>
              </li>
              <li>
                <p>
                  We may offer a number of membership plans. Some membership plans may have differing conditions and
                  limitations, which will be disclosed at your sign-up or in other communications made available to you.
                  You can find specific details regarding your Wild Rides Television Network membership by visiting the
                  WildRidesTV.com website and clicking on the "My Account" link available at the top of the pages under
                  your profile name.
                </p>
              </li>
            </ol>
          </li>
          <br />
          <li>
            <strong>Promotional Offers.</strong>We may from time to time offer special promotional offers, plans or
            memberships (“Offers”). Offer eligibility is determined by Wild Rides Television Network & Prairie Rose
            Productions at its sole discretion and we reserve the right to revoke an Offer and put your account on hold
            in the event that we determine you are not eligible. Members of households with an existing or recent Wild
            Rides Television Network membership may not be eligible for certain introductory Offers. We may use
            information such as device ID, method of payment or an account email address used with an existing or recent
            Wild Rides Television Network membership to determine Offer eligibility. The eligibility requirements and
            other limitations and conditions will be disclosed when you sign-up for the Offer or in other communications
            made available to you. <br />
          </li>
          <br />
          <br />
          <li>
            <strong>Billing and Cancellation</strong>
            <ol>
              <li>
                <p>
                  <u>Billing Cycle.</u> The membership fee for the Wild Rides Television Network & Prairie Rose
                  Productions service and any other charges you may incur in connection with your use of the service,
                  such as taxes and possible transaction fees, will be charged to your Payment Method on the specific
                  payment date indicated on the "My Account" page. The length of your billing cycle will depend on the
                  type of subscription that you choose when you signed up for the service. Membership fees are fully
                  earned upon payment. In some cases your payment date may change, for example if your Payment Method
                  has not successfully settled, when you change your subscription plan or if your paid membership began
                  on a day not contained in a given month. Visit the WildRidesTV.com website and click on the "Billing
                  details" link on the "My Account" page to see your next payment date. We may authorize your Payment
                  Method in anticipation of membership or service-related charges through various methods, including
                  authorizing it up to approximately one month of service as soon as you register.
                </p>
              </li>
              <li>
                <p>
                  <u>Payment Methods.</u> To use the Wild Rides Television Network service you must provide one or more
                  Payment Methods. You authorize us to charge any Payment Method associated to your account in case your
                  primary Payment Method is declined or no longer available to us for payment of your subscription fee.
                  You remain responsible for any uncollected amounts. If a payment is not successfully settled, due to
                  expiration, insufficient funds, or otherwise, and you do not cancel your account, we may suspend your
                  access to the service until we have successfully charged a valid Payment Method. For some Payment
                  Methods, the issuer may charge you certain fees, such as foreign transaction fees or other fees
                  relating to the processing of your Payment Method. Check with your Payment Method service provider for
                  details.
                </p>
              </li>
              <li>
                <p>
                  <u>Updating your Payment Methods.</u> You can update your Payment Methods by going to the "My Account"
                  page. We may also update your Payment Methods using information provided by the payment service
                  providers. Following any update, you authorize us to continue to charge the applicable Payment
                  Method(s).
                </p>
              </li>
              <li>
                <p>
                  <u>Cancellation.</u> You can cancel your Wild Rides Television Network membership at any time, and you
                  will continue to have access to the Wild Rides Television Network service through the end of your
                  billing period. To cancel, go to the "My Account" page on our WildRidesTV.com website and follow the
                  instructions for cancellation. If you cancel your membership, your account will automatically close at
                  the end of your current billing period. To see when your account will close, click "Billing details"
                  on the "My Account" page.
                </p>
              </li>
              <li>
                <p>
                  <u>Changes to the Price and Subscription Plans.</u> We reserve the right to change our subscription
                  plans or adjust pricing for our service or any components thereof in any manner and at any time as we
                  may determine in our sole and absolute discretion. Except as otherwise expressly provided for in these
                  Terms of Use, any price changes or changes to your subscription plan will take effect following notice
                  to you.
                </p>
              </li>
              <li>
                <p>
                  <u>No Refunds.</u> Payments are nonrefundable and there are no refunds or credits for partially used
                  membership periods. Following any cancellation, however, you will continue to have access to the
                  service through the end of your current billing period. At any time, and for any reason, we may
                  provide a refund, discount, or other consideration to some or all of our members ("credits"). The
                  amount and form of such credits, and the decision to provide them, are at our sole and absolute
                  discretion. The provision of credits in one instance does not entitle you to credits in the future for
                  similar instances, nor does it obligate us to provide credits in the future, under any circumstance.
                </p>
              </li>
            </ol>
          </li>
          <br />
          <li>
            <strong>Wild Rides Television Network & Prairie Rose Productions Service</strong>
            <ol>
              <li>
                <p>
                  You must be at least 18 years of age to become a member of the Wild Rides Television Network & Prairie
                  Rose Productions service. Individuals under the age of 18 may only utilize the service with the
                  involvement of a parent or legal guardian, under such person's account and otherwise subject to these
                  Terms of Use.
                </p>
              </li>
              <li>
                <p>
                  The Wild Rides Television Network & Prairie Rose Productions service and any content accessed through
                  our service are for your personal and non-commercial use only and may not be shared with individuals
                  beyond your household. During your Wild Rides Television Network & Prairie Rose Productions
                  membership, we grant you a limited, non-exclusive, non-transferable right to access the Wild Rides
                  Television Network & Prairie Rose Productions service and Wild Rides Television Network & Prairie Rose
                  Productions content through the service. Except for the foregoing, no right, title or interest shall
                  be transferred to you. You agree not to use the service for public performances.
                </p>
              </li>
              <li>
                <p>
                  You may access Wild Rides Television Network & Prairie Rose Productions content primarily within the
                  country in which you have established your account and only in geographic locations where we offer our
                  service and have licensed such content. The content that may be available will vary by geographic
                  location and will change from time to time. The number of devices on which you may simultaneously
                  watch depends on your chosen subscription plan and is specified on the "My Account" page.
                </p>
              </li>
              <li>
                <p>
                  The Wild Rides Television Network & Prairie Rose Productions service, including the content library,
                  is regularly updated. In addition, we continually test various aspects of our service, including but
                  not limited to our websites, user interfaces, promotional features and availability of Wild Rides
                  Television Network & Prairie Rose Productions content. You can turn off test participation at any time
                  by visiting the "My Account" page and changing the "Test participation" settings.
                </p>
              </li>
              <li>
                <p>
                  Some Wild Rides Television Network & Prairie Rose Productions content is available for temporary
                  download and offline viewing on certain supported devices ("Offline Titles"). Limitations apply,
                  including restrictions on the number of Offline Titles per account, the maximum number of devices that
                  can contain Offline Titles, the time period within which you will need to begin viewing Offline Titles
                  and how long the Offline Titles will remain accessible. Some Offline Titles may not be playable in
                  certain countries and if you go online in a country where you would not be able to stream that Offline
                  Title, the Offline Title will not be playable while you are in that country.
                </p>
              </li>
              <li>
                <p>
                  You agree to use the Wild Rides Television Network & Prairie Rose Productions service, including all
                  features and functionalities associated therewith, in accordance with all applicable laws, rules and
                  regulations, or other restrictions on use of the service or content therein. Except as explicitly
                  authorized in these Terms of Use, you agree not to archive, download, reproduce, distribute, modify,
                  display, perform, publish, license, create derivative works from, offer for sale, or use content and
                  information contained on or obtained from or through the Wild Rides Television Network & Prairie Rose
                  Productions service. You also agree not to circumvent, remove, alter, deactivate, degrade or thwart
                  any of the content protections in the Wild Rides Television Network & Prairie Rose Productions
                  service; use any robot, spider, scraper or other automated means to access the Wild Rides Television
                  Network & Prairie Rose Productions service; decompile, reverse engineer or disassemble any software or
                  other products or processes accessible through the Wild Rides Television Network & Prairie Rose
                  Productions service; insert any code or product or manipulate the content of the Wild Rides Television
                  Network & Prairie Rose Productions service in any way; or use any data mining, data gathering or
                  extraction method. In addition, you agree not to upload, post, e-mail or otherwise send or transmit
                  any material designed to interrupt, destroy or limit the functionality of any computer software or
                  hardware or telecommunications equipment associated with the Wild Rides Television Network & Prairie
                  Rose Productions service, including any software viruses or any other computer code, files or
                  programs. We may terminate or restrict your use of our service if you violate these Terms of Use or
                  are engaged in illegal or fraudulent use of the service.
                </p>
              </li>
              <li>
                <p>
                  The quality of the display of the Wild Rides Television Network & Prairie Rose Productions content may
                  vary from device to device, and may be affected by a variety of factors, such as your location, the
                  bandwidth available through and/or speed of your Internet connection. HD, Ultra HD and HDR
                  availability is subject to your Internet service and device capabilities. Not all content is available
                  in all formats, such as HD, Ultra HD or HDR and not all plans allow you to receive content in all
                  formats. Default playback settings on cellular networks exclude HD, Ultra HD and HDR content. The
                  minimum connection speed for SD quality is 1.0 Mbps; however, we recommend a faster connection for
                  improved video quality. A download speed of at least 3.0 Mbps per stream is recommended to receive HD
                  content (defined as a resolution of 720p or higher). A download speed of at least 15.0 Mbps per stream
                  is recommended to receive Ultra HD (defined as a resolution of 4K or higher). You are responsible for
                  all Internet access charges. Please check with your Internet provider for information on possible
                  Internet data usage charges. Wild Rides Television Network & Prairie Rose Productions makes no
                  representations or warranties about the quality of your watching experience on your display. The time
                  it takes to begin watching Wild Rides Television Network & Prairie Rose Productions content will vary
                  based on a number of factors, including your location, available bandwidth at the time, the Wild Rides
                  Television Network & Prairie Rose Productions content you have selected and the configuration of your
                  Wild Rides Television Network & Prairie Rose Productions ready device.
                </p>
              </li>
              <li>
                <p>
                  The Wild Rides Television Network & Prairie Rose Productions software is developed by, or for, Wild
                  Rides Television Network & Prairie Rose Productions and may solely be used for authorized streaming
                  and to access content from Wild Rides Television Network & Prairie Rose Productions through Wild Rides
                  Television Network & Prairie Rose Productions ready devices. This software may vary by device and
                  medium, and functionalities may also differ between devices. By using our service, you agree to
                  receive, without further notice or prompting, updated versions of the Wild Rides Television Network &
                  Prairie Rose Productions and related third-party software. If you do not accept the foregoing terms,
                  do not use our service.
                </p>
              </li>
              <li>
                <p>
                  By using our service, you agree to look solely to the entity that manufactured and/or sold you the
                  Wild Rides Television Network & Prairie Rose Productions ready device for any issues related to the
                  device and its compatibility with the Wild Rides Television Network & Prairie Rose Productions
                  service. We do not take responsibility or otherwise warrant the performance of Wild Rides Television
                  Network & Prairie Rose Productions ready devices, including the continued compatibility with our
                  service.
                </p>
              </li>
            </ol>
          </li>
          <br />
          <li>
            <strong>Passwords and Account Access.</strong>
            The member who created the Wild Rides Television Network & Prairie Rose Productions account and whose
            Payment Method is charged (the "Account Owner") is responsible for any activity that occurs through the Wild
            Rides Television Network & Prairie Rose Productions account. To maintain control over the account and
            prevent anyone from accessing the account (which could include information on viewing history for the
            account), the Account Owner should maintain control over the Wild Rides Television Network & Prairie Rose
            Productions ready devices that are used to access the service and not reveal the password or details of the
            Payment Method associated to the account to anyone. You are responsible for updating and maintaining the
            accuracy of the information you provide to us relating to your account. We can terminate your account or
            place your account on hold in order to protect you, Wild Rides Television Network & Prairie Rose Productions
            or our partners from identity theft or other fraudulent activity. Wild Rides Television Network & Prairie
            Rose Productions is not obligated to credit or discount a membership for holds placed on the account by
            either a representative of Wild Rides Television Network & Prairie Rose Productions or by the automated
            processes of Wild Rides Television Network & Prairie Rose Productions. If your Wild Rides Television Network
            & Prairie Rose Productions ready device is sold, lost or stolen, please deactivate the Wild Rides Television
            Network & Prairie Rose Productions ready device. If you fail to log out or deactivate your device,
            subsequent users may access the Wild Rides Television Network & Prairie Rose Productions service through
            your account and may be able to access certain of your account information. To deactivate a device, follow
            instructions on the "My Account" page of our Wild Rides Television Network & Prairie Rose Productions.com
            website.
          </li>
          <br />
          <br />
          <li>
            <strong>Disclaimers of Warranties and Limitations on Liability</strong>
            <ol>
              <li>
                <p>
                  THE Wild Rides Television Network & Prairie Rose Productions SERVICE AND ALL CONTENT AND SOFTWARE
                  ASSOCIATED THEREWITH, OR ANY OTHER FEATURES OR FUNCTIONALITIES ASSOCIATED WITH THE Wild Rides
                  Television Network & Prairie Rose Productions SERVICE, ARE PROVIDED "AS IS" AND "AS AVAILABLE" WITH
                  ALL FAULTS AND WITHOUT WARRANTY OF ANY KIND. Wild Rides Television Network & Prairie Rose Productions
                  DOES NOT GUARANTEE, REPRESENT, OR WARRANT THAT YOUR USE OF THE Wild Rides Television Network & Prairie
                  Rose Productions SERVICE WILL BE UNINTERRUPTED OR ERROR-FREE. Wild Rides Television Network & Prairie
                  Rose Productions SPECIFICALLY DISCLAIMS LIABILITY FOR THE USE OF APPLICATIONS, Wild Rides Television
                  Network & Prairie Rose Productions READY DEVICES, AND Wild Rides Television Network & Prairie Rose
                  Productions SOFTWARE (INCLUDING THEIR CONTINUING COMPATIBILITY WITH OUR SERVICE).
                </p>
              </li>
              <li>
                <p>
                  TO THE EXTENT PERMISSIBLE UNDER APPLICABLE LAWS, IN NO EVENT SHALL Wild Rides Television Network &
                  Prairie Rose Productions, OR ITS SUBSIDIARIES OR ANY OF THEIR SHAREHOLDERS, DIRECTORS, OFFICERS,
                  EMPLOYEES OR LICENSORS BE LIABLE (JOINTLY OR SEVERALLY) TO YOU FOR PERSONAL INJURY OR ANY SPECIAL,
                  INCIDENTAL, INDIRECT OR CONSEQUENTIAL DAMAGES OF ANY KIND, OR ANY DAMAGES WHATSOEVER.
                </p>
              </li>
              <li>
                <p>
                  SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR THE LIMITATION OR EXCLUSION OF
                  LIABILITY FOR CERTAIN TYPES OF DAMAGES. THEREFORE, SOME OF THE ABOVE LIMITATIONS IN THIS SECTION MAY
                  NOT APPLY TO YOU.
                </p>
              </li>
              <li>
                <p>NOTHING IN THESE TERMS OF USE SHALL AFFECT ANY NON-WAIVABLE STATUTORY RIGHTS THAT APPLY TO YOU.</p>
              </li>
            </ol>
          </li>
          <br />
          <li>
            <strong>Arbitration Agreement</strong>
            <ol>
              <li>
                <p>
                  You and Wild Rides Television Network & Prairie Rose Productions agree that any dispute, claim or
                  controversy arising out of or relating in any way to the Wild Rides Television Network & Prairie Rose
                  Productions service, these Terms of Use and this Arbitration Agreement, shall be determined by binding
                  arbitration or in small claims court. Arbitration is more informal than a lawsuit in court.
                  Arbitration uses a neutral arbitrator instead of a judge or jury, allows for more limited discovery
                  than in court, and is subject to very limited review by courts. Arbitrators can award the same damages
                  and relief that a court can award and nothing in this Arbitration Agreement shall be interpreted as
                  limiting any non-waivable statutory rights. You agree that, by agreeing to these Terms of Use, the
                  U.S. Federal Arbitration Act governs the interpretation and enforcement of this provision, and that
                  you and Wild Rides Television Network & Prairie Rose Productions are each waiving the right to a trial
                  by jury or to participate in a class action. This arbitration provision shall survive termination of
                  this Agreement and the termination of your Wild Rides Television Network & Prairie Rose Productions
                  membership.
                </p>
              </li>
              <li>
                <p>
                  If you elect to seek arbitration or file a small claim court action, you must first send to Wild Rides
                  Television Network & Prairie Rose Productions, by certified mail, a written Notice of your claim
                  ("Notice"). The Notice to Wild Rides Television Network & Prairie Rose Productions must be addressed
                  to: —-- If Wild Rides Television Network & Prairie Rose Productions initiates arbitration, it will
                  send a written Notice to the email address used for your membership account. A Notice, whether sent by
                  you or by Wild Rides Television Network & Prairie Rose Productions, must (a) describe the nature and
                  basis of the claim or dispute; and (b) set forth the specific relief sought ("Demand"). If Wild Rides
                  Television Network & Prairie Rose Productions and you do not reach an agreement to resolve the claim
                  within 30 days after the Notice is received, you or Wild Rides Television Network & Prairie Rose
                  Productions may commence an arbitration proceeding or file a claim in small claims court.
                </p>
              </li>
              <li>
                <p>
                  You may download or copy a form Notice and a form to initiate arbitration at www.adr.org. If you are
                  required to pay a filing fee, after Wild Rides Television Network & Prairie Rose Productions receives
                  notice at the Notice Address that you have commenced arbitration, Wild Rides Television Network &
                  Prairie Rose Productions will reimburse you for your payment of the filing fee, unless your claim is
                  for greater than US$10,000, in which event you will be responsible for filing fees.
                </p>
              </li>
              <li>
                <p>
                  The arbitration will be governed by the —- (the "AAA Rules") —-, or by writing to the Notice Address.
                  The arbitrator is bound by the terms of this Agreement. All issues are for the arbitrator to decide,
                  including issues relating to the scope and enforceability of this arbitration agreement. Unless Wild
                  Rides Television Network & Prairie Rose Productions and you agree otherwise, any arbitration hearings
                  will take place in the county (or parish) of your residence. The arbitrator's award shall be final and
                  binding on all parties, except (1) for judicial review expressly permitted by law or (2) if the
                  arbitrator's award includes an award of injunctive relief against a party, in which case that party
                  shall have the right to seek judicial review of the injunctive relief in a court of competent
                  jurisdiction that shall not be bound by the arbitrator's application or conclusions of law.
                </p>
              </li>
              <li>
                <p>
                  If your claim is for US$10,000 or less, we agree that you may choose whether the arbitration will be
                  conducted solely on the basis of documents submitted to the arbitrator, through a telephonic hearing,
                  or by an in-person hearing as established by the — Rules. If your claim exceeds US$10,000, the right
                  to a hearing will be determined by the—- Rules. Regardless of the manner in which the arbitration is
                  conducted, the arbitrator shall issue a reasoned written decision explaining the essential findings
                  and conclusions on which the award is based. If the arbitrator issues you an award that is greater
                  than the value of Wild Rides Television Network & Prairie Rose Productions's last written settlement
                  offer made before an arbitrator was selected (or if Wild Rides Television Network & Prairie Rose
                  Productions did not make a settlement offer before an arbitrator was selected), then Wild Rides
                  Television Network & Prairie Rose Productions will pay you the amount of the award or US$5,000,
                  whichever is greater. Except as expressly set forth herein, the payment of all filing, administration
                  and arbitrator fees will be governed by the AAA Rules.
                </p>
              </li>
              <li>
                <p>
                  YOU AND Wild Rides Television Network & Prairie Rose Productions AGREE THAT EACH MAY BRING CLAIMS
                  AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN
                  ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. Further, unless both you and Wild Rides Television
                  Network & Prairie Rose Productions agree otherwise, the arbitrator may not consolidate more than one
                  person's claims with your claims, and may not otherwise preside over any form of a representative or
                  class proceeding. If this specific provision is found to be unenforceable, then the entirety of this
                  arbitration provision shall be null and void.
                </p>
              </li>
            </ol>
          </li>
          <br />
          <li>
            <strong>Miscellaneous</strong>
            <ol>
              <li>
                <p>
                  <u>Governing Law.</u> These Terms of Use shall be governed by and construed in accordance with the
                  laws of the state of North Dakota, U.S.A. without regard to conflict of laws provisions. These terms
                  will not limit any consumer protection rights that you may be entitled to under the mandatory laws of
                  your state of residence.
                </p>
              </li>
              <li>
                <p>
                  <u>Unsolicited Materials.</u> Wild Rides Television Network & Prairie Rose Productions does not accept
                  unsolicited materials or ideas for Wild Rides Television Network & Prairie Rose Productions content,
                  and is not responsible for the similarity of any of its content or programming in any media to
                  materials or ideas transmitted to Wild Rides Television Network & Prairie Rose Productions. Should you
                  send any unsolicited materials or ideas, you do so with the understanding that no additional
                  consideration of any sort will be provided to you, and you are waiving any claim against Wild Rides
                  Television Network & Prairie Rose Productions and its affiliates regarding the use of such materials
                  and ideas, even if material or an idea is used that is substantially similar to the material or idea
                  you sent.
                </p>
              </li>
              <li>
                <p>
                  <u>Feedback</u> Wild Rides Television Network & Prairie Rose Productions is free to use any comments,
                  information, ideas, concepts, reviews, or techniques or any other material contained in any
                  communication you may send to us ("Feedback"), including responses to questionnaires or through
                  postings to the Wild Rides Television Network & Prairie Rose Productions service, including our
                  websites and user interfaces, worldwide and in perpetuity without further compensation,
                  acknowledgement or payment to you for any purpose whatsoever including, but not limited to,
                  developing, manufacturing and marketing products and creating, modifying or improving the Wild Rides
                  Television Network & Prairie Rose Productions service. In addition, you agree not to enforce any
                  "moral rights" in and to the Feedback, to the extent permitted by applicable law.
                </p>
              </li>
              <li>
                <p>
                  <u>Customer Support.</u> To find more information about our service and its features, or if you need
                  assistance with your account, please visit the Wild Rides Television Network & Prairie Rose
                  Productions Help Center, which is accessible through the Wild Rides Television Network & Prairie Rose
                  Productions.com website. In certain instances, Customer Service may best be able to assist you by
                  using a remote access support tool through which we have full access to your computer. If you do not
                  want us to have this access, you should not consent to support through the remote access tool, and we
                  will assist you through other means. In the event of any conflict between these Terms of Use and
                  information provided by Customer Service or other portions of our websites, these Terms of Use will
                  control.
                </p>
              </li>
              <li>
                <p>
                  <u>Survival.</u> If any provision or provisions of these Terms of Use shall be held to be invalid,
                  illegal, or unenforceable, the validity, legality and enforceability of the remaining provisions shall
                  remain in full force and effect.
                </p>
              </li>
              <li>
                <p>
                  <u>Changes to Terms of Use and Assignment.</u> Wild Rides Television Network & Prairie Rose
                  Productions may, from time to time, change these Terms of Use. Such revisions shall be effective
                  immediately; provided however, for existing members, such revisions shall, unless otherwise stated, be
                  effective 30 days after posting. We may assign our agreement with you to any affiliated company or to
                  any entity that succeeds to all or substantially all of our business or assets related to the
                  applicable Wild Rides Television Network & Prairie Rose Productions service.
                </p>
              </li>
              <li>
                <p>
                  <u>Communication Preferences.</u> We will send you information relating to your account (e.g. payment
                  authorizations, invoices, changes in password or Payment Method, confirmation messages, notices) in
                  electronic form only, for example via emails to your email address provided during registration. You
                  agree that any notices, agreements, disclosures or other communications that we send to you
                  electronically will satisfy any legal communication requirements, including that such communications
                  be in writing.
                </p>
              </li>
            </ol>
          </li>
        </ol>
      </div>
    </div>
  );
};

export default TermsAndConditions;
