import { Card, Divider, Grid } from '@mui/material';
import { sendResetPassword } from '../../utils/userHelper';
import React, { useState, useEffect } from 'react';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CakeIcon from '@mui/icons-material/Cake';
import ProfileModal from './ProfileModal';
import ChangeEmailModal from './ChangeEmailModal';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import SubscriptionInfo from './SubscriptionInfo';
import { apiRequest } from '../../utils/general';
import Skeleton from '@mui/material/Skeleton';

const Profile = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [user, setUser] = useState({});
  const [isProfileModalOpened, setIsProfileModalOpened] = useState(pathname == '/profile/edit');
  const [isChangeEmailModalOpened, setIsChangeEmailModalOpened] = useState(pathname.includes('change-email'));
  const [loading, setLoading] = useState(true);
  const accessToken = useSelector((state) => state.user.token);

  const fetchUserDetails = async () => {
    try {
      const profileRes = await apiRequest(`users/profile`);
      setUser(profileRes.data.data.user);
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);

  const onCloseModal = () => {
    setIsProfileModalOpened(false);
    setIsChangeEmailModalOpened(false);
    navigate('/profile');
    fetchUserDetails();
  };

  const handleChangeEmailClick = async () => {
    try {
      const confirmed = await swal({
        title: 'Are you sure?',
        text: 'An email with a link to change the email address will be sent to the old email!',
        icon: 'warning',
        buttons: true,
        dangerMode: true,
      });
      if (confirmed) {
        await apiRequest(`users/profile/${accessToken}/change-email`);
        swal(
          {
            title: 'Please verify your email',
            text: 'A verification email has been sent to your current email address on file. Please check the email and follow the instructions to change the email.',
            icon: 'success',
            button: 'Got it!',
          }
        );
      }
    } catch (err) {
      console.error(err);
      swal('Invalid Token', {
        icon: 'error',
      });
    }
  };
  const handleChangePasswordClick = async (email) => {
    try {
      const confirmed = await swal({
        title: 'Are you sure?',
        text: 'An email with a link to change the password will be sent to your email address',
        icon: 'warning',
        buttons: true,
        dangerMode: true,
      });
      if (confirmed) {
        sendResetPassword(email);
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className='profile-page'>
      <ProfileModal
        isProfileModalOpened={isProfileModalOpened}
        onCloseModal={onCloseModal}
        user={user}
        accessToken={accessToken}
      />
      <ChangeEmailModal isModalOpened={isChangeEmailModalOpened} onCloseModal={onCloseModal} />
      <div className='profile-container'>
        <div className='profile-card-container'>
          <Grid item>
            <Card className='profile-card'>
              <CardContent>
                <Typography variant='h6' component='div' sx={{ mb: 1.5 }} className='text-black'>
                  Your Account
                </Typography>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography variant='body2' className='text-brown'>
                      <div className='profile-card-header'>Personal Info</div>
                    </Typography>
                  </Grid>
                  <Grid item xs={6} className='profile-card-menu'>
                    {!loading ? (
                      <Button
                        component={Link}
                        to='/profile/edit'
                        type='button'
                        onClick={() => setIsProfileModalOpened(true)}
                      >
                        Edit Personal Info
                      </Button>
                    ) : null}
                  </Grid>
                </Grid>
                <div className='profile-card-body'>
                  {!loading ? (
                    <>
                      <Typography variant='body2'>
                        {user.first_name} {user.last_name}
                      </Typography>
                      <Typography variant='body2'>
                        <CakeIcon fontSize='small' className='text-orange' sx={{ mb: '-2px' }} /> &nbsp;{' '}
                        {user.birthday ?? 'Update your birthday'}
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Skeleton width='60%' />
                      <Skeleton width='70%' />
                      <Skeleton width='50%' />
                      <Skeleton width='80%' />
                    </>
                  )}
                </div>
                <Divider className='section-divider' />
                <Grid container>
                  <Grid item xs={6}>
                    <Typography variant='body2' className='text-brown'>
                      <div className='profile-card-header'>Email</div>
                    </Typography>
                  </Grid>
                  <Grid item xs={6} className='profile-card-menu'>
                    {!loading ? (
                      <Button type='button' onClick={handleChangeEmailClick}>
                        Change Email
                      </Button>
                    ) : null}
                  </Grid>
                </Grid>
                <div className='profile-card-body'>
                  {!loading ? <Typography variant='body2'>{user.email}</Typography> : <Skeleton width='80%' />}
                </div>
                <Divider className='section-divider' />
                <Grid container>
                  <Grid item xs={4}>
                    <Typography variant='body2' className='text-brown'>
                      <div className='profile-card-header'>Password</div>
                    </Typography>
                  </Grid>
                  <Grid item xs={8} className='profile-card-menu'>
                    {!loading ? (
                      <Button onClick={() => handleChangePasswordClick(user.email)}>Change Password</Button>
                    ) : null}
                  </Grid>
                </Grid>
                <div className='profile-card-body'>
                  <Typography variant='body2'>●●●●●●●●●●●</Typography>
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item>
            <SubscriptionInfo />
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default Profile;
