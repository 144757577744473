import React from 'react';

export default function VideoBackground(props) {
  const { videoSrc } = props;
  return (
    <div className='video-background'>
      <video autoPlay playsInline loop muted src={videoSrc} type='video/mp4' />
      <div className='fade-bottom'></div>
    </div>
  );
}
