import React, { useState } from 'react';
import logo from '../../../assets/images/auth-logo.svg';
import GiftPlan from './GiftPlan';
import GiftInfo from './GiftInfo';
import GiftPayment from './GiftPayment';
const GiftWrapper = () => {
  const [component, setComponent] = useState(<GiftPlan onPlanSubmit={handlePlanSubmit} logo={logo} />);

  function handlePlanSubmit(planId) {
    setComponent(<GiftInfo onInfoSubmit={handleInfoSubmit} onCancel={handleInfoCancel} logo={logo} planId={planId} />);
  }

  function handleInfoSubmit(values, planId) {
    const data = {};
    for (const key in values) {
      data[key] = values[key];
    }
    data.plan_id = planId;
    setComponent(<GiftPayment onCancel={handlePaymentCancel} logo={logo} giftData={data} />);
  }

  function handleInfoCancel() {
    setComponent(<GiftPlan onPlanSubmit={handlePlanSubmit} logo={logo} />);
  }
  function handlePaymentCancel(planId, data) {
    setComponent(
      <GiftInfo
        onInfoSubmit={handleInfoSubmit}
        onCancel={handleInfoCancel}
        giftData={data}
        logo={logo}
        planId={planId}
      />
    );
  }

  return component;
};
export default GiftWrapper;
