import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { apiRequest } from '../../utils/general';
import NewsletterModal from '../general/NewsletterModal';
import LogoFlexBox from '../home/LogoFlexBox';

export default function Footer(props) {
  const [logoUrl, setLogoUrl] = useState('');
  const [isSignupOpen, setIsSignupOpen] = useState(false);

  useEffect(() => {
    apiRequest('assets/footer-assets')
      .then((res) => {
        let data = res.data;
        setLogoUrl(data.logo_url);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const closeModal = () => {
    setIsSignupOpen(false);
  };

  const openModal = () => {
    setIsSignupOpen(true);
  };

  return (
    <>
      <NewsletterModal isOpen={isSignupOpen} onClose={closeModal} />
      <div className='footer-container'>
        <div className='fade-container' />
        <div className='footer-container__footer-flex'>
          <p> Privacy & Terms</p>
          <Link to='/privacy-policy'>Privacy Policy</Link>
          <Link to='/terms-and-conditions'>Terms & Conditions</Link>
        </div>
        <div className='footer-container__footer-flex'>
          <p>Other Resources</p>
          <a href='https://news.wildridestv.com/help-center/'>Help Center</a>
          <a href='https://news.wildridestv.com/about/'>About Us</a>
          <Link to='/contact-us'>Contact/Support</Link>
        </div>
        <div className='footer-container__footer-flex'>
          <p className='footer_flex__no-margin-bottom'>Where to Watch</p>
          <LogoFlexBox />
        </div>
        <div className='footer-container__footer-button'>
          <div>
            <button className='wild-rides-button' onClick={() => openModal()}>
              Get Our E-Newsletter
            </button>
          </div>
        </div>
        <div className='footer-container__footer-flex --center'>
          <Link to='/'>
            <img className='logo-image' src={logoUrl} alt='wild rides tv logo' />
          </Link>
          <p>© 2023 Wild Rides TV. All rights reserved.</p>
        </div>
      </div>
    </>
  );
}
