import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@mui/material/TextField';
import VideoBackground from '../../common/VideoBackground';
import * as yup from 'yup';
import { resetPassword } from '../../../services/auth.service';
import { createAlert } from '../../../store/alertSlice';
import { useFormik } from 'formik';
import { showLoader, hideLoader } from '../../../store/loaderSlice';
import { ERROR_500 } from '../../../utils/errors';
import { useNavigate, useParams } from 'react-router-dom';
import { apiRequest } from '../../../utils/general';

export default function ResetPassword() {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(state => !!state.user?.token);

  const initialValues = {
    password: '',
    password_confirmation: '',
  };
  const [backgroundVideoUrl, setBackgroundVideoUrl] = useState('');

  useEffect(() => {
    const fetchBackgroundVideo = async () => {
      try {
        const backgroundVideoRes = await apiRequest('users/sign-up-background');
        setBackgroundVideoUrl(backgroundVideoRes.data.video_url);
      } catch (err) {
        console.error(err);
        dispatch(createAlert({ message: 'An error occured!', type: 'error' }));
      }
    };
    fetchBackgroundVideo();
  }, []);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: yup.object({
      password: yup
        .string('Enter your password')
        .min(8, 'Password should be of minimum 8 characters length')
        .required('Password is required'),
      password_confirmation: yup
        .string()
        .required('Please retype your password.')
        .oneOf([yup.ref('password')], 'Your passwords do not match.'),
    }),
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  const handleCloseModal = () => {
    formik.resetForm();
    isLoggedIn ? navigate('/profile') : navigate('/sign-in');
    dispatch(createAlert({ message: 'Password Reset Cancelled', type: 'error' }));
  };

  const handleSubmit = (formValue) => {
    dispatch(showLoader());
    const data = formValue;
    data.token = params.token;
    resetPassword(data)
      .then((res) => {
        dispatch(hideLoader());
        dispatch(createAlert({ message: res.data.message, type: 'success' }));
        isLoggedIn ? navigate('/profile') : navigate('/sign-in');
      })
      .catch((error) => {
        console.error(error);
        dispatch(hideLoader());
        if (error.response.status == 422) {
          dispatch(
            createAlert({
              message: `${error.response.data.error}`,
              type: 'error',
            })
          );
        } else {
          dispatch(createAlert({ message: ERROR_500, type: 'error' }));
        }
      });
  };

  return (
    <>
      <VideoBackground videoSrc={backgroundVideoUrl} />
      <div className='info-page'>
        <div className='info-page__header'>
          <h1>Reset Password</h1>
          <p>Create a new password for your account.</p>
        </div>
        <form noValidate onSubmit={formik.handleSubmit} className='info-form format-errors'>
          <TextField
            fullWidth
            required
            autofocus
            id='password'
            name='password'
            label='Password'
            type='password'
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
          <TextField
            sx={{ mt: 3, width: 1 }}
            required
            fullWidth
            name='password_confirmation'
            label='Re-enter Password'
            type='password'
            id='password_confirmation'
            autoComplete='password_confirmation'
            value={formik.values.password_confirmation}
            onChange={formik.handleChange}
            error={formik.touched.password_confirmation && Boolean(formik.errors.password_confirmation)}
            helperText={formik.touched.password_confirmation && formik.errors.password_confirmation}
          />
          <div className='flex-center'>
            <button className='wild-rides-button --half-width' type='submit'>
              Submit
            </button>
          </div>
          <div className='auth-bottom-text'>
            <a onClick={handleCloseModal}>Cancel</a>
          </div>
        </form>
      </div>
    </>
  );
}
