import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import * as Yup from 'yup';
import { createAlert } from '../../../store/alertSlice';
import { useFormik } from 'formik';
import { ERROR_500 } from '../../../utils/errors';
import { useDispatch } from 'react-redux';
import { apiRequest } from '../../../utils/general';
import Grid from '@mui/material/Grid';
import FormHelperText from '@mui/material/FormHelperText';
import WRModal from '../../general/WRModal';
import { showLoader, hideLoader } from '../../../store/loaderSlice';

const ChangePlan = (props) => {
  const { subscription, cardDetails, onCloseModal, onDeleteCards, isModalOpened } = props;
  const userToken = useSelector(state => state.user?.token);
  const dispatch = useDispatch();
  const [planMonthly, setPlanMonthly] = useState({});
  const [planYearly, setPlanYearly] = useState({});
  const [isNotYearlyPlan, setIsNotYearlyPlan] = useState(false);
  const [initialPlanId, setInitialPlanId] = useState();
  const [planMonthlyPrice, setPlanMonthlyPrice] = useState([]);
  const [planYearlyPrice, setPlanYearlyPrice] = useState([]);

  useEffect(() => {
    fetchPlans();
    if (subscription) {
      setIsNotYearlyPlan(subscription && subscription.plan_name != 'Year');
      setInitialPlanId(subscription.plan_id);
      formik.setValues({ plan_id: subscription.plan_id });
      if (subscription.plan_id) {
        setInitialPlanId(subscription.plan_id);
        formik.setValues({ plan_id: subscription.plan_id });
      }
    }
  }, [props]);

  const fetchPlans = async () => {
    try {
      const planRes = await apiRequest('subscriptions/plans');
      let plans = planRes.data.response;
      setPlanMonthly(plans[0]);
      setPlanMonthlyPrice(plans[0].amount.split('.'));
      setPlanYearlyPrice(plans[1].amount.split('.'));
      setPlanYearly(plans[1]);
    } catch (err) {
      console.error(err);
      dispatch(createAlert({ message: 'An error occured!', type: 'error' }));
    }
  };

  const formik = useFormik({
    initialValues: {
      plan_id: '',
    },
    validationSchema: Yup.object({
      plan_id: Yup.string().required('You should select a plan'),
    }),
    onSubmit: (values) => {
      handlePlanUpdate(values);
    },
  });

  const handlePlanChange = (event) => {
    formik.setValues({ plan_id: event.target.value });
  };

  const handlePlanUpdate = async (formValue) => {
    try {
      dispatch(showLoader());
      const updateRes = await apiRequest(
        `subscriptions/${subscription.stripe_subscription_id}/update`,
        'patch',
        { plan_id: formValue.plan_id,
          card_details: cardDetails,
          subscription_details: subscription
        }
      );
      await swal({
        title: 'Plan Updated',
        text: updateRes.data.message,
        icon: 'success',
        button: 'Got it!',
      });
      dispatch(hideLoader());
      onCloseModal();
      window.location.reload();
    } catch (err) {
      console.error(err);
      err = err.response;
      if (err.status == 401) {
        dispatch(createAlert({ message: err.data.error, type: 'error' }));
      } else {
        dispatch(createAlert({ message: ERROR_500, type: 'error' }));
      }
    }
  };
  const onModalClose = () => {
    onCloseModal();
  };
  const handleCancelationConfirm = async () => {
    try {
      await apiRequest(`subscriptions/${subscription.subscription_id}/cancel`, 'patch', {
        userToken,
      });
      await swal({
        title: 'Subscription Cancelled',
        text: 'Your subscription has successfully been cancelled. We hope you will return to WRTV. Check back in the future for new programming!',
        icon: 'success',
        button: 'Got it!',
      });
      window.location.reload();
    } catch (err) {
      console.error(err);
      if (err.response.status == 500) {
        dispatch(createAlert({ message: ERROR_500, type: 'error' }));
      } else {
        dispatch(
          createAlert({
            message: err.response.data.error,
            type: 'error',
          })
        );
      }
    }
  };
  const handleCancelPlanClick = async () => {
    try {
      const cancelRes = await swal({
        title: 'Cancel Plan?',
        text: 'Are you sure you want to cancel your WRTV subscription? You will no longer have access to programing that you haven’t previously bought.',
        icon: 'warning',
        buttons: ['Close', 'Continue'],

        dangerMode: true,
        className: 'text-danger',
      });
      if (cancelRes) {
        dispatch(showLoader)
        if (subscription && subscription.status == 'canceled') {
          dispatch(
            createAlert({
              message: `Can not cancel an already canceled plan!`,
              type: 'error',
            })
          );
          return;
        }
        if (subscription && subscription.subscription_id) {
          await onDeleteCards();
          await handleCancelationConfirm();
        } else {
          dispatch(createAlert({ message: 'No active plans.', type: 'error' }));
        }
        dispatch(hideLoader)
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <WRModal isOpen={isModalOpened} title='Change your plan' onClose={onModalClose}>
      <form noValidate onSubmit={formik.handleSubmit}>
        <RadioGroup name='controlled-radio-buttons-group' value={formik.values.plan_id} onChange={handlePlanChange}>
          <Grid container alignItems='center' justifyContent='center' spacing={2} className='pb-5 text-shadow'>
            <div className='price-grid'>
              {isNotYearlyPlan && (
                <div className='price-grid__price-flex'>
                  <div>
                    <span>${planMonthlyPrice[0] + '.'}</span>
                    <span>{planMonthlyPrice[1]}</span>
                  </div>
                  <div>Per {planMonthly.name}</div>
                  <FormControlLabel
                    value={planMonthly._id}
                    control={
                      <Radio
                        className={'btn-price-radio'}
                        sx={{
                          color: 'white',
                          '&.Mui-checked': {
                            color: 'orange',
                          },
                        }}
                      />
                    }
                  />
                </div>
              )}

              {isNotYearlyPlan && (
                <Grid item xs={2} alignContent='center'>
                  <div className='vertical-line'></div>
                </Grid>
              )}
              <div className={`price-grid__price-flex ${!isNotYearlyPlan && 'grid-item-full-width'}`}>
                <div>
                  <span>${planYearlyPrice[0] + '.'}</span>
                  <span>{planYearlyPrice[1]}</span>
                </div>
                <div>Per {planYearly.name}</div>
                <FormControlLabel
                  value={planYearly._id}
                  control={
                    <Radio
                      className={'btn-price-radio'}
                      sx={{
                        color: 'white',
                        '&.Mui-checked': {
                          color: 'orange',
                        },
                      }}
                    />
                  }
                />
              </div>
            </div>
            {formik.errors.plan_id && formik.touched.plan_id && (
              <FormHelperText error>
                {formik.errors.plan_id && formik.touched.plan_id ? formik.errors.plan_id : ' '}
              </FormHelperText>
            )}
          </Grid>
        </RadioGroup>
        <div className='text-center'>
          <div>
            <Button sx={{ mt: 2, mb: 2 }} onClick={handleCancelPlanClick}>
              <span
                className='text-white text-normal text-underline-on-hover'
                sx={{ textTransform: 'none !important' }}
              >
                I want to cancel my plan
              </span>
            </Button>
          </div>
          <Button
            type='submit'
            disabled={initialPlanId === formik.values.plan_id}
            variant='contained'
            sx={{ mt: 2, pb: 2, pt: 2, pl: 10, pr: 10 }}
            color='inherit'
            className='signup-button'
          >
            Save
          </Button>
          <div>
            <Button onClick={onModalClose} sx={{ mt: 3 }}>
              <span className='text-white text-underline-on-hover text-capitalize'>Cancel</span>
            </Button>
          </div>
        </div>
      </form>
    </WRModal>
  );
};

export default ChangePlan;
