import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import Footer from './layout/Footer';
import Navbar from './layout/Navbar';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const App = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => !!state.user?.token);

  useEffect(() => {
    if (isLoggedIn && pathname == '/') {
      navigate('/browse');
    }
  }, [pathname]);

  return (
    <>
      <Navbar />
      <Outlet />
      <Footer />
    </>
  );
};

export default App;
