import { Navigate, Outlet } from "react-router-dom";
import React from "react";
import { useLocation } from "react-router";
import { useSelector } from 'react-redux';

const ProtectedRoute = () => {
  const location = useLocation();
  const isLoggedIn = useSelector(state => !!state.user?.token);

  return isLoggedIn ? (
    <Outlet />
  ) : (
    <Navigate to="/sign-in" replace state={{ from: location }} />
  );
};

export default ProtectedRoute;
