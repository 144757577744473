import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import alertReducer from './alertSlice';
import loaderReducer from './loaderSlice';
import notificationReducer from './notificationSlice';
import userReducer from './userSlice';
import { persistStore, createMigrate, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

const rootReducer = combineReducers({
  alert: alertReducer,
  loader: loaderReducer,
  notification: notificationReducer,
  user: userReducer,
});

const migrations = {
  0: (state) => {
    // initial state, do not change -GL
    return {
      alert: {
        alerts: [],
      },
      loader: {
        loader: {
          show: false,
          message: '',
        },
      },
      notification: {
        notifications: [],
        priority: '',
      },
      user: {
        subscription_expired: true,
        subscription_expires_date: null,
        payment_failed: false,
      }
    }
  },
  1: (state) => {
    return {
      ...state,
      user: {
        ...state.user,
        token: null,
        userInitial: null,
      }
    }
  }
}

const persistConfig = {
  key: 'root',
  storage,
  version: 1,
  whitelist: ['user', 'notification'],
  migrate: createMigrate(migrations, { debug: false })
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = configureStore({
  reducer: persistedReducer,
});
export const persistor = persistStore(store);
