import React from 'react';
import Notification from '../Notification';
import { useSelector } from 'react-redux';
const Avatar = () => {
  const avatarClasses = 'avatar-small avatar-small__user-menu';
  const userInitial = useSelector(state => state.user?.userInitial);
  return (
    <div className='avatar flex-center'>
      <Notification classNames={avatarClasses}>
        <div>!</div>
      </Notification>
      {userInitial}
    </div>
  );
};
export default Avatar;
