import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import * as Yup from 'yup';
import { createAlert } from '../../../store/alertSlice';
import { useFormik } from 'formik';
import { showLoader, hideLoader } from '../../../store/loaderSlice';
import { ERROR_500 } from '../../../utils/errors';
import { useDispatch } from 'react-redux';
import { apiRequest } from '../../../utils/general';
import { useStripe, useElements, CardNumberElement, CardCvcElement, CardExpiryElement } from '@stripe/react-stripe-js';
import { Link } from 'react-router-dom';
import WRModal from '../../general/WRModal';

const cardStyle = {
  style: {
    base: {
      color: 'black',
      fontFamily: 'Arial, sans-serif',
      fontSmoothing: 'antialiased',
      '::placeholder': {
        color: '#969696',
      },
    },
    invalid: {
      fontFamily: 'Arial, sans-serif',
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
};

const AddPaymentMethodModal = (props) => {
  const { onDeleteCards, onCloseModal, onCardAdded, isModalOpened } = props;
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const [cardNumberError, setCardNumberError] = useState(null);
  const [cardExpiryError, setCardExpiryError] = useState(null);
  const [cardCvcError, setCardCvcError] = useState(null);
  const [cardNumberIsEmpty, setCardNumberIsEmpty] = useState(true);
  const [cardExpiryIsEmpty, setCardExpiryIsEmpty] = useState(true);
  const [cardCvcIsEmpty, setCardCvcIsEmpty] = useState(true);

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema: Yup.object({
      name: Yup.string('Enter your name').required('Name is required'),
    }),
    onSubmit: async (values) => {
      dispatch(showLoader('Processing..'));
      try {
        const tokenRes = await getToken(values);
        dispatch(hideLoader());
        if (tokenRes.status) {
          await onDeleteCards();
          handlePayment({ ...values, stripe_token: tokenRes.token });
          handleModalClose()
        }
      } catch (err) {
        console.error(err);
      }
    },
  });

  const getToken = async () => {
    if (!stripe || !elements) {
      return;
    }
    const card = elements.getElement(CardNumberElement);
    let card_data = {
      name: formik.values.name,
    };
    const result = await stripe.createToken(card, card_data);
    if (result.error) {
      return { status: false, error: result.error.message };
    } else {
      return { status: true, token: result.token.id };
    }
  };

  const handlePayment = async (data) => {
    try {
      dispatch(showLoader('Processing..'));
      let cardRes = await apiRequest('cards/create', 'post', data);
      cardRes = cardRes.data;
      dispatch(hideLoader());
      dispatch(createAlert({ message: cardRes.message, type: 'success' }));
      onCardAdded(cardRes.card);
    } catch (err) {
      dispatch(hideLoader());
      console.error(err);
      if (err.response.status == 401) {
        dispatch(
          createAlert({
            message: 'There seems to be some problem with the card, please check it!',
            type: 'error',
          })
        );
      } else {
        dispatch(createAlert({ message: ERROR_500, type: 'error' }));
      }
    }
  };
  const handleModalClose = () => {
    setCardNumberError(false);
    setCardExpiryError(false);
    setCardCvcError(false);
    elements.getElement(CardNumberElement).clear();
    elements.getElement(CardExpiryElement).clear();
    elements.getElement(CardCvcElement).clear();
    formik.resetForm();
    onCloseModal();
  };
  const checkForm = (event) => {
    if (!cardNumberError) {
      cardNumberIsEmpty ? setCardNumberError('Your card number is incomplete.') : setCardNumberError(false);
    }
    if (!cardExpiryError) {
      cardExpiryIsEmpty ? setCardExpiryError(`Your card's expiration date is incomplete.`) : setCardExpiryError(false);
    }
    if (!cardCvcError) {
      cardCvcIsEmpty ? setCardCvcError(`Your card's security code is incomplete.`) : setCardCvcError(false);
    }
    formik.handleSubmit(event);
  };
  const handleCardExpiryChange = (e) => {
    e.error ? setCardExpiryError(e.error.message) : setCardExpiryError(null);
    setCardExpiryIsEmpty(e.empty);
  };
  const handleCardCvcChange = (e) => {
    e.error ? setCardCvcError(e.error.message) : setCardCvcError(null);
    setCardCvcIsEmpty(e.empty);
  };
  const handleCardNumberChange = (e) => {
    e.error ? setCardNumberError(e.error.message) : setCardNumberError(null);
    setCardNumberIsEmpty(e.empty);
  };

  return (
    <WRModal isOpen={isModalOpened} title='Update Payment' onClose={handleModalClose}>
      <form noValidate onSubmit={checkForm} className='info-form format-errors'>
        <div className='grid-item-full-width'>
          <TextField
            autoComplete='given-name'
            name='name'
            InputLabelProps={{ shrink: true }}
            required
            fullWidth
            id='name'
            label='Name'
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            variant='outlined'
            placeholder="Cardholder's Name"
          />
        </div>
        <div className='position-relative grid-item-full-width'>
          <div className='card-element-modal-subtext'>Credit Card Number *</div>
          <CardNumberElement
            options={cardStyle}
            className={`card-element ${cardNumberError ? 'StripeElement --error' : 'StripeElement'}`}
            onChange={handleCardNumberChange}
          />
          <div>{cardNumberError && <div className='error-formatting'>{cardNumberError}</div>}</div>
        </div>
        <div className='position-relative'>
          <div className='card-element-modal-subtext'>Expiration Date *</div>
          <CardExpiryElement
            options={cardStyle}
            className={`card-element ${
              cardExpiryError ? 'StripeElement StripeElement--empty --error' : 'StripeElement'
            }`}
            onChange={handleCardExpiryChange}
          />
          {cardExpiryError && <div className='error-formatting'>{cardExpiryError}</div>}
        </div>
        <div className='position-relative'>
          <div className='card-element-modal-subtext'>CVC *</div>
          <CardCvcElement
            options={cardStyle}
            className={`card-element ${cardCvcError ? 'StripeElement StripeElement--empty --error' : 'StripeElement'}`}
            onChange={handleCardCvcChange}
          />
          {cardCvcError && <div className='error-formatting'>{cardCvcError}</div>}
        </div>

        <div className='text-center grid-item-full-width'>
          <button disabled={!stripe} className='wild-rides-button --half-width'>
            Submit
          </button>
          <div className='auth-bottom-text'>
            <Link to='/profile' className='text-white' onClick={handleModalClose}>
              Cancel
            </Link>
          </div>
        </div>
      </form>
    </WRModal>
  );
};

export default AddPaymentMethodModal;
