import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import AddPaymentMethodModal from '../user/payment/AddPaymentMethodModal';
import { useDispatch } from 'react-redux';
import { showLoader, hideLoader } from '../../store/loaderSlice';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { createAlert } from '../../store/alertSlice';
import { apiRequest } from '../../utils/general';

const PaymentModal = (props) => {
  const [cardId, setCardId] = useState('');
  const dispatch = useDispatch();
  const [isAddPaymentModalOpened, setIsAddPaymentModalOpened] = useState(false);
  const [cards, setCards] = useState([]);

  useEffect(() => {
    fetchCards();
  }, []);

  useEffect(() => {
    if (cards.length > 0) {
      setCardId(cards[0].id);
    }
  }, [cards.length]);

  const onPaymentModalClose = () => {
    props.onModalClose();
  };

  const onAddPaymentModalClose = () => {
    setIsAddPaymentModalOpened(false);
  };
  const onCardAdded = (card) => {
    setIsAddPaymentModalOpened(false);
    setCards([card, ...cards]);
  };

  const handleCardChange = (event) => {
    setCardId(event.target.value);
  };

  const makePayment = () => {
    if (!cardId) {
      dispatch(createAlert({ message: 'Please select a card.', type: 'error' }));
      return;
    }

    props.onSubmitPayment(cardId);
  };

  const fetchCards = () => {
    dispatch(showLoader('Loading Cards..'));
    apiRequest('users/subscription-details')
      .then((res) => {
        dispatch(hideLoader());
        if (res.data?.cards) {
          setCards(res.data.cards);
        }
      })
      .catch((err) => {
        dispatch(hideLoader());
        console.error(err);
      });
  };

  return (
    <>
      <AddPaymentMethodModal
        isModalOpened={isAddPaymentModalOpened}
        onModalClose={onAddPaymentModalClose}
        onCardAdded={onCardAdded}
      />
      <Dialog
        open={props.isModalOpened}
        onClose={onPaymentModalClose}
        maxWidth={'sm'}
        fullWidth
        className='black-and-white-modal'
      >
        <DialogTitle id='alert-dialog-title' sx={{ marginTop: '5%' }}>
          <Typography
            component='h1'
            variant='h4'
            sx={{
              position: 'absolute',
              right: 5,
              top: 5,
            }}
          >
            <IconButton onClick={onPaymentModalClose}>
              <CloseIcon />
            </IconButton>
          </Typography>

          <Typography component='h2' variant='h4' className='modal-title'>
            {props.title}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container direction={'column'} spacing={2} justifyContent='center' alignItems='center'>
            <Grid item>
              <h3>
                <strong>How would you like to pay ${props.price}?</strong>
              </h3>
            </Grid>
            <Grid item>
              <RadioGroup value={cardId} onChange={handleCardChange}>
                {cards.map((card) => (
                  <FormControlLabel
                    key={card.id}
                    value={card.id}
                    control={<Radio className='btn-price-radio' />}
                    label={`Use saved card ending in ${card.last4}`}
                  />
                ))}
              </RadioGroup>
            </Grid>
            <Grid item m={1}>
              OR
            </Grid>
            <Grid item>
              <a
                href='javascript:void(0)'
                type='button'
                onClick={() => {
                  setIsAddPaymentModalOpened(true);
                }}
              >
                <IconButton>
                  <AddCircleIcon />
                </IconButton>
                Add a new payment method
              </a>
            </Grid>
            <Grid item>
              <Button onClick={makePayment} fullWidth variant='contained' sx={{ mt: 2, mb: 2, p: 2, pl: 10, pr: 10 }}>
                Continue
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PaymentModal;
