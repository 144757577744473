import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { checkForToken } from '../../utils/general';

const AnonymousRoute = () => {
  const isLoggedIn = useSelector(state => !!state.user?.token);

  return isLoggedIn ? <Navigate to="/" /> : <Outlet /> ;
};

export default AnonymousRoute;
