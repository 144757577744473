import * as yup from "yup";

const SignupValidationSchema = yup.object({
  first_name: yup
    .string("Enter your first name")
    .required("First Name is required"),
  last_name: yup
    .string("Enter your last name")
    .required("Last Name is required"),
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup
    .string("Enter your password")
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
  password_confirmation: yup
    .string()
    .required("Please retype your password.")
    .oneOf([yup.ref("password")], "Your passwords do not match."),
  terms_and_condition: yup
    .boolean()
    .oneOf([true], "You must accept the terms and conditions"),
  is_over_18: yup.boolean().oneOf([true], "You must be 18 years or older."),
  receive_updates: yup
    .boolean()
    .oneOf([true, false], "You must be 18 years or older."),
});

export default SignupValidationSchema;
