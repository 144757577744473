import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { showLoader, hideLoader } from '../../store/loaderSlice';
import { useDispatch } from 'react-redux';
import Loader from '../Loader';
import Alert from '../Alert';
import ComingSoonFooter from './ComingSoonFooter';
import SignupForm from './SignupForm';
import SiteLogo from '../../../assets/images/auth-logo.svg';
import { apiRequest } from '../../utils/general';
import VideoCardList from '../home/VideoCardList';

const MainText = () => {
  return (
    <p id='coming-soon-text'>
      WRTV is the new Wild Rides TV network that rolls out January 1st with tons of wild west programming and so much
      more. You could say that WRTV is to rodeo and ranching what NFL Films is to the NFL. And it features top-notch
      hosts of five new series including barrel racer Amberley Snyder in <i>Pure Grit</i>, eight-time World Champions
      Joe Beaver and Donnie Gay in <i>Winning Ways</i> and <i>Rodeo's Son</i>, rodeo's biggest entertainer, Flint
      Rasmussen in <i>FLINT Unedited</i> and that ranch-raised Montana cowboy that stars in the hit TV series{' '}
      <i>Yellowstone</i>, Forrie J. Smith in <i>Ramblin' with Forrie J.</i> Plus, 10 other series with non-western
      themes like <i>How Couples Met</i>, <i>First Step</i>, <i>The Reveal</i>, <i>Life In America</i>,{' '}
      <i>Preserving America</i> and so much more. And you will love them all. So, check them out, right now!
    </p>
  );
};

const ComingSoon = () => {
  const dispatch = useDispatch();
  const [backgroundVideoUrl, setBackgroundVideoUrl] = useState('');

  useEffect(() => {
    dispatch(showLoader());
    apiRequest('coming-soon/assets')
      .then((res) => {
        let data = res.data.data;
        dispatch(hideLoader());
        setBackgroundVideoUrl(data.video_url);
      })
      .catch((err) => console.error(err));
  }, []);

  return (
    <>
      <Loader />
      <Alert />
      <div className='video-background'>
        <video autoPlay playsInline loop muted src={backgroundVideoUrl} type='video/mp4' />
        <div className='fade-bottom'></div>
      </div>
      <div className='signup-main-container info-form' style={{ padding: '0' }}>
        <Grid container direction='column'>
          <Grid item>
            <Container component='main' maxWidth='sm' className='signup-inner-container'>
              <Box className='signup-box'>
                {true && <img className='signup-logo' alt='signup logo' src={SiteLogo} width='80%' />}
                <Typography variant='h1' className='text-white text-center shadow-text big-text'>
                  Coming Soon
                </Typography>
                <div id='coming-soon-main-text'>
                  <h5 className='text-white'>
                    <MainText />
                  </h5>
                </div>
                <SignupForm idAddon='header' />
              </Box>
            </Container>
          </Grid>
          <VideoCardList comingSoon={true} />
          <Grid item>
            <ComingSoonFooter />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default ComingSoon;
