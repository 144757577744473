import React, { useState, useEffect } from 'react';
import Slide from './Slide';
import { CAROUSEL_LIST } from '../../utils/constants';

const Carousel = () => {
  const slowTransition = '3s';
  const fastTransition = '.5s';
  const [currentSlideIndex, setCurrentSlideIndex] = useState(-1);
  const [transitionSpeed, setTransitionSpeed] = useState(fastTransition);

  useEffect(() => {
    setCurrentSlideIndex(0);
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      handleNextSlide();
    }, 4000);
    return () => clearInterval(intervalId);
  }, [currentSlideIndex]);

  const handlePreviousSlide = () => {
    let newIndex = currentSlideIndex - 1;
    if (newIndex < 0) {
      newIndex = CAROUSEL_LIST.length - 1;
      setTransitionSpeed(slowTransition);
    } else {
      setTransitionSpeed(fastTransition);
    }
    setCurrentSlideIndex(newIndex);
  };

  const handleNextSlide = () => {
    let newIndex = currentSlideIndex + 1;
    if (newIndex > CAROUSEL_LIST.length - 1) {
      newIndex = 0;
      setTransitionSpeed(slowTransition);
    } else {
      setTransitionSpeed(fastTransition);
    }
    setCurrentSlideIndex(newIndex);
  };

  const dots = CAROUSEL_LIST.map((slide, index) => {
    return (
      <span
        key={index}
        className={`${index === currentSlideIndex ? 'current-dot' : 'dot'}`}
        onClick={() => setCurrentSlideIndex(index)}
      />
    );
  });

  return (
    <>
      <div className='carousel-container'>
        <button className='carousel__button --left' onClick={handlePreviousSlide}>
          <div className='chevron left --large'></div>
        </button>
        <div
          className='carousel'
          style={{ transform: `translateX(${-83 * currentSlideIndex}vw)`, transition: `ease all ${transitionSpeed}` }}
        >
          {CAROUSEL_LIST.map((slide, index) => (
            <Slide
              slideData={slide}
              thisSlideIndex={index}
              currentSlideIndex={currentSlideIndex}
              slidesLength={CAROUSEL_LIST.length}
            />
          ))}
        </div>
        <button className='carousel__button --right' onClick={handleNextSlide}>
          <div className='chevron right --large'></div>
        </button>
        <div className='dots-container'>{dots}</div>
      </div>
    </>
  );
};
export default Carousel;
