import React from 'react';
import rokuLogo from '../../../assets/images/roku-logo-square.png';
import appleTVLogo from '../../../assets/images/apple-tv-square.png';
import fireTvLogo from '../../../assets/images/fire_square.png';
import androidTvLogo from '../../../assets/images/Android_tv_logo.png';
import TCLLogo from '../../../assets/images/TCL.png';
import panasonicLogo from '../../../assets/images/panasonic-logo.png';
import sharpLogo from '../../../assets/images/sharp-logo.png';
import philipsLogo from '../../../assets/images/philips-logo.png';
import sonyLogo from '../../../assets/images/sony-logo.png';
import appleStoreLogo from '../../../assets/images/apple-store-logo.png';
import amazonStoreLogo from '../../../assets/images/amazon-store-logo.png';
import googleStoreLogo from '../../../assets/images/google-store-logo.png';
import lgLogo from '../../../assets/images/lg-logo.png';

const LogoFlexBox = () => {
  const firstRow = [
    {
      src: rokuLogo,
      alt: 'roku-logo',
      url: 'https://channelstore.roku.com/details/712ff05702bf1c9367c53a336d0ab24f/wild-rides-tv',
    },
    { src: appleTVLogo, alt: 'apple-tv-logo', url: 'https://apps.apple.com/us/app/wild-rides-tv/id1660973080' },
    { src: fireTvLogo, alt: 'fire-tv-logo', url: 'https://www.amazon.com/gp/product/B0BQKF1NG9' },
    {
      src: androidTvLogo,
      alt: 'android-tv-logo',
      url: 'https://play.google.com/store/apps/details?id=com.maz.tvod285',
    },
    { src: TCLLogo, alt: 'tcl-logo' },
    { src: lgLogo, alt: 'lg-logo', url: 'https://my.lgappstv.com/main/tvapp/detail?appId=1202603' },
  ];

  const secondRow = [
    { src: panasonicLogo, alt: 'panasonic-logo' },
    { src: sharpLogo, alt: 'sharp-logo' },
    { src: philipsLogo, alt: 'philips-logo' },
    { src: sonyLogo, alt: 'sony-logo' },
  ];

  const thirdRow = [
    { src: appleStoreLogo, alt: 'apple-store-logo', url: 'https://apps.apple.com/us/app/wild-rides-tv/id1660973080' },
    { src: amazonStoreLogo, alt: 'amazon-store-logo', url: 'https://www.amazon.com/gp/product/B0BQKF1NG9' },
    {
      src: googleStoreLogo,
      alt: 'google-store-logo',
      url: 'https://play.google.com/store/apps/details?id=com.maz.tvod285',
    },
  ];

  const handleImageClick = (image) => {
    if (image.url) {
      console.log(image.url);
      window.open(image.url, '_blank');
    }
  };

  const imageMapper = (row, className, alignItems = '') => {
    const gridRow = row.map((image, index) => {
      return (
        <div key={index} className={` flex-center ${alignItems}`}>
          <img
            src={image.src}
            alt={image.alt}
            onClick={() => handleImageClick(image)}
            className={`${image.url ? 'image-cursor' : ''}`}
          />
        </div>
      );
    });
    return <div className={`${className}`}>{gridRow}</div>;
  };
  return (
    <div className='logo-container'>
      {imageMapper(firstRow, 'logo-flex')}
      {imageMapper(secondRow, 'logo-flex')}
      {imageMapper(thirdRow, 'logo-flex --center', '--align-start')}
    </div>
  );
};

export default LogoFlexBox;
