export const PAGES = [
  {
    label: 'Home',
    url: '/',
  },
  {
    label: 'Browse',
    url: '/browse',
  },
  {
    label: 'Pricing',
    url: '/pricing',
  },
  {
    label: 'Shop',
    url: '/shop',
  },
  {
    label: 'News',
    url: '/news',
  },
];

export let BREAKING_THE_SOD_SERIES_ID;
export let CATTLE_INDUSTRY_TODAY_SERIES_ID;
export let COWBOY_MOMENTS_SERIES_ID;
export let FIRST_STEP_SERIES_ID;
export let FLINT_UNEDITED_SERIES_ID;
export let HOW_COUPLES_MET_SERIES_ID;
export let LIFE_IN_AMERICA_SERIES_ID;
export let PRESERVING_AMERICA_SERIES_ID;
export let PURE_GRIT_SERIES_ID;
export let RODEO_SONS_SERIES_ID;
export let RAMBLIN_SERIES_ID;
export let THE_REVEAL_SERIES_ID;
export let WESTERN_JUSTICE_SERIES_ID;
export let WILD_RIDES_SERIES_ID;
export let WINNING_WAYS_SERIES_ID;
export let HEADER_WATCH_NOW_EPISODE_ID;

export let STRIPE_MONTHLY_GIFT_CARD_ID;
export let STRIPE_YEARLY_GIFT_CARD_ID;

export const PUBLIC_PLAYER_API_KEY = process.env.ZYPE_PLAYER_API_KEY;
export const PLAYER_EMBED_URL = 'https://player.zype.com/embed/';

//Home page constants

if (process.env.ENVIRONMENT === 'production') {
  BREAKING_THE_SOD_SERIES_ID = '62f3fb243bbb36000163cd63';
  CATTLE_INDUSTRY_TODAY_SERIES_ID = '6304ef467c70ce000187ba45';
  COWBOY_MOMENTS_SERIES_ID = '6304f0587c70ce000187ba8f';
  FIRST_STEP_SERIES_ID = '6304f4a27c70ce000187bb9f';
  FLINT_UNEDITED_SERIES_ID = '6304f9a57c70ce000187bdf6';
  HOW_COUPLES_MET_SERIES_ID = '6304f82d5c39710001133bac';
  LIFE_IN_AMERICA_SERIES_ID = '63050cec7c70ce000187c4d6';
  PRESERVING_AMERICA_SERIES_ID = '630502135c39710001133e67';
  PURE_GRIT_SERIES_ID = '6304e2130a279c000113bbac';
  RODEO_SONS_SERIES_ID = '63050a1c5c3971000113412e';
  RAMBLIN_SERIES_ID = '630503037c70ce000187c03b';
  THE_REVEAL_SERIES_ID = '630507617c70ce000187c237';
  WESTERN_JUSTICE_SERIES_ID = '63050b687c70ce000187c445';
  WILD_RIDES_SERIES_ID = '62b9cc11dfdfc3000150a33d';
  WINNING_WAYS_SERIES_ID = '630506207c70ce000187c1be';
  HEADER_WATCH_NOW_EPISODE_ID = '62ceef5824e0c500015ea8bf';
  STRIPE_MONTHLY_GIFT_CARD_ID = 'price_1MWSrlKnC0I2MZpnEHYQOKk0';
  STRIPE_YEARLY_GIFT_CARD_ID = 'price_1MWSsNKnC0I2MZpn9kUNxRDG';
} else {
  BREAKING_THE_SOD_SERIES_ID = '63ab1580d072750001dad824';
  CATTLE_INDUSTRY_TODAY_SERIES_ID = '63ab1bbd5477560001b6324a';
  COWBOY_MOMENTS_SERIES_ID = '63ab1c128244b100015b0bac';
  FIRST_STEP_SERIES_ID = '63ab1c448244b100015b0bdb';
  FLINT_UNEDITED_SERIES_ID = '63b454dd80117d0001ca66bc';
  HOW_COUPLES_MET_SERIES_ID = '63b4557e80117d0001ca670b';
  LIFE_IN_AMERICA_SERIES_ID = '63b4592280117d0001ca676c';
  PRESERVING_AMERICA_SERIES_ID = '63ab1f38d072750001dadaa9';
  PURE_GRIT_SERIES_ID = '63ab1d4b5477560001b632a9';
  RODEO_SONS_SERIES_ID = '63ab1da05477560001b6330a';
  RAMBLIN_SERIES_ID = '63ab1da05477560001b6330a';
  THE_REVEAL_SERIES_ID = '63ab1dc0d072750001dada02';
  WESTERN_JUSTICE_SERIES_ID = '63ab1dd65477560001b63345';
  WILD_RIDES_SERIES_ID = '63ab1dee5477560001b63369';
  WINNING_WAYS_SERIES_ID = '63ab1e04d072750001dada74';
  HEADER_WATCH_NOW_EPISODE_ID = '628d11ca090d540001c4c8be';
  STRIPE_MONTHLY_GIFT_CARD_ID = 'price_1MOoO6KnC0I2MZpn8CSMV2yc';
  STRIPE_YEARLY_GIFT_CARD_ID = 'price_1MOoOmKnC0I2MZpnTjXtnDg7';
}

export const NOTIFICATION_PRIORITIES = {
  DANGER: 2,
  WARNING: 1,
};

export const NOTIFICATIONS = {
  EXPIRED: {
    id: 'EXPIRED',
    priority: NOTIFICATION_PRIORITIES.DANGER,
    message: `Your payment information is expired! Update your card information soon or you will lose your
    subscription!`,
  },
  EXPIRES_SOON: {
    id: 'EXPIRES_SOON',
    priority: NOTIFICATION_PRIORITIES.WARNING,
    message: `Your payment information will expire soon! Update your card information soon or you will lose
    your subscription!`,
  },
  PAYMENT_FAILED: {
    id: 'PAYMENT_FAILED',
    priority: NOTIFICATION_PRIORITIES.DANGER,
    message: `Your payment information has failed! Update your card to reactivate your subscription`,
  },
};

export const SLIDE_TYPES = {
  SERIES: 'SERIES',
};

export const CAROUSEL_LIST = [
  {
    type: SLIDE_TYPES.SERIES,
    data: {
      id: WILD_RIDES_SERIES_ID,
      position: 'right',
    },
  },
  {
    type: SLIDE_TYPES.SERIES,
    data: {
      id: PURE_GRIT_SERIES_ID,
      position: 'right',
    },
  },
  {
    type: SLIDE_TYPES.SERIES,
    data: {
      id: RODEO_SONS_SERIES_ID,
      position: 'right',
    },
  },
  {
    type: SLIDE_TYPES.SERIES,
    data: {
      id: WINNING_WAYS_SERIES_ID,
      position: 'right',
    },
  },
  {
    type: SLIDE_TYPES.SERIES,
    data: {
      id: FLINT_UNEDITED_SERIES_ID,
      position: 'right',
    },
  },
  {
    type: SLIDE_TYPES.SERIES,
    data: {
      id: COWBOY_MOMENTS_SERIES_ID,
      position: 'right',
    },
  },
  {
    type: SLIDE_TYPES.SERIES,
    data: {
      id: FIRST_STEP_SERIES_ID,
      position: 'right',
    },
  },
  {
    type: SLIDE_TYPES.SERIES,
    data: {
      id: HOW_COUPLES_MET_SERIES_ID,
      position: 'right',
    },
  },
  {
    type: SLIDE_TYPES.SERIES,
    data: {
      id: PRESERVING_AMERICA_SERIES_ID,
      position: 'right',
    },
  },
  {
    type: SLIDE_TYPES.SERIES,
    data: {
      id: THE_REVEAL_SERIES_ID,
      position: 'right',
    },
  },
  {
    type: SLIDE_TYPES.SERIES,
    data: {
      id: CATTLE_INDUSTRY_TODAY_SERIES_ID,
      position: 'right',
    },
  },
];
