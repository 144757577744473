import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import VideoBackground from '../../common/VideoBackground';
import { showLoader, hideLoader } from '../../../store/loaderSlice';
import { sendResetPassword } from '../../../utils/userHelper';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { apiRequest } from '../../../utils/general';

export default function ForgotPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const initialValues = {
    email: '',
  };
  const [backgroundVideoUrl, setBackgroundVideoUrl] = useState('');
  const isLoggedIn = useSelector(state => !!state.user?.token);

  useEffect(() => {
    const fetchBackgroundVideo = async () => {
      try {
        const backgroundVideoRes = await apiRequest('users/sign-up-background');
        setBackgroundVideoUrl(backgroundVideoRes.data.video_url);
      } catch (err) {
        console.error(err);
      }
    };
    fetchBackgroundVideo();
  }, []);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      email: Yup.string('Enter your email').email('Enter a valid email').required('Email is required'),
    }),
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleSubmit = async (formValue) => {
    try {
      dispatch(showLoader());
      await sendResetPassword(formValue.email);
      navigate('/profile');
    } catch (err) {
      console.error(err);
    }
    dispatch(hideLoader());
  };

  return (
    <>
      <VideoBackground videoSrc={backgroundVideoUrl} />
      <div className='info-page'>
        <div className='info-page__header'>
          <h1>{isLoggedIn ? 'Change Password' : 'Forgot Password'}</h1>
          <p>Enter the email address associated with the account.</p>
        </div>
        <form noValidate onSubmit={formik.handleSubmit} className='info-form format-errors'>
          <TextField
            style={{ minWidth: '100%' }}
            autoComplete='email'
            name='email'
            required
            fullWidth
            id='email'
            label='Email'
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            autoFocus
            variant='outlined'
          />

          <div className='text-center mt-5'>
            <button type='submit' className='wild-rides-button --half-width'>
              Submit
            </button>
          </div>
          {!isLoggedIn && (
            <div className='auth-bottom-text'>
              <span>
                New to Wild Rides Television? &nbsp;
                <Link href='/sign-up' variant='body2'>
                  {'Sign Up Now'}
                </Link>
              </span>
            </div>
          )}
          <div className='auth-bottom-text'>
            <Link href='/sign-in' variant='body1'>
              {'Cancel'}
            </Link>
          </div>
        </form>
      </div>
    </>
  );
}
