import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useStripe, useElements, CardNumberElement, CardCvcElement, CardExpiryElement } from '@stripe/react-stripe-js';
import { useFormik } from 'formik';
import { apiRequest } from '../../utils/general';
import { showLoader, hideLoader } from '../../store/loaderSlice';
import { useDispatch } from 'react-redux';
import { createAlert } from '../../store/alertSlice';
import horseImage from '../../../assets/images/gift-background.png';
import * as yup from 'yup';
import swal from 'sweetalert';
import { useNavigate } from 'react-router-dom';
import { STRIPE_MONTHLY_GIFT_CARD_ID, STRIPE_YEARLY_GIFT_CARD_ID } from '../../utils/constants';

const cardStyle = {
  style: {
    base: {
      color: 'black',
      fontFamily: 'Arial, sans-serif',
      fontSmoothing: 'antialiased',
      '::placeholder': {
        color: '#969696',
      },
    },
    invalid: {
      fontFamily: 'Arial, sans-serif',
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
};

const GiftPayment = (props) => {
  const {
    giftData: { plan_id, yourName, yourEmail, recipientName, recipientEmail, giftMessage },
    giftData,
  } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const [cardNumberError, setCardNumberError] = useState(null);
  const [cardExpiryError, setCardExpiryError] = useState(null);
  const [cardCvcError, setCardCvcError] = useState(null);
  const [cardNumberIsEmpty, setCardNumberIsEmpty] = useState(true);
  const [cardExpiryIsEmpty, setCardExpiryIsEmpty] = useState(true);
  const [cardCvcIsEmpty, setCardCvcIsEmpty] = useState(true);
  const [monthlyPlanId, setMonthlyPlanId] = useState('');
  const [yearlyPlanId, setYearlyPlanId] = useState('');

  const validationSchema = yup.object({
    name: yup.string('Enter your name').required("Cardholder's Name is required"),
  });
  useEffect(() => {
    const fetchSubscription = async () => {
      const subscriptionRes = await apiRequest('subscriptions/plans');
      const [planMonthly, planYearly] = subscriptionRes.data.response;
      console.log({ planMonthly: planMonthly.stripe_id, planYearly: planYearly.stripe_id });
      console.log({ plan_id });
      setMonthlyPlanId(planMonthly.stripe_id);
      setYearlyPlanId(planYearly.stripe_id);
    };
    fetchSubscription();
  }, []);

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema,
    onSubmit: (values) => {
      dispatch(showLoader('Processing..'));
      getToken().then((response) => {
        if (response.status) {
          const data = {
            ...values,
            gift_plan_id: plan_id,
            buyer_name: yourName,
            buyer_email: yourEmail,
            recipient_name: recipientName,
            recipient_email: recipientEmail,
            gift_message: giftMessage,
            stripe_token: response.token,
          };
          if (plan_id === STRIPE_MONTHLY_GIFT_CARD_ID) {
            data.purchased_plan_id = monthlyPlanId;
          } else if (plan_id === STRIPE_YEARLY_GIFT_CARD_ID) {
            data.purchased_plan_id = yearlyPlanId;
          }
          handlePayment(data);
        }
        dispatch(hideLoader());
      });
    },
  });

  const getToken = async () => {
    if (!stripe || !elements) {
      return;
    }
    const card = elements.getElement(CardNumberElement);
    const result = await stripe.createToken(card, { name: formik.values.name });
    if (result.error) {
      return { status: false, error: result.error.message };
    } else {
      return { status: true, token: result.token.id };
    }
  };

  const handlePayment = async (couponData) => {
    try {
      const chargeRes = await apiRequest('/cards/gift-payment', 'post', couponData);
      const {
        data: { coupon },
        status,
      } = chargeRes;
      if (status === 200) {
        dispatch(hideLoader());
        const continueClick = await swal({
          title: 'Gift Successful Sent!',
          text: 'A redemption code was successfully sent to your recipient’s email. Thank you for choosing wild rides network.',
          button: 'Continue',
          closeOnClickOutside: false,
          closeOnEsc: false,
        });
        if (continueClick) {
          navigate('/gifting-wild-rides');
        }
      }
    } catch (err) {
      dispatch(hideLoader());
      console.error(err.response.data.error);
      dispatch(createAlert({ message: 'Could not process payment', type: 'error' }));
      console.error(err);
    }
  };

  const checkForm = (event) => {
    if (!cardNumberError) {
      cardNumberIsEmpty ? setCardNumberError('Your card number is incomplete.') : setCardNumberError(false);
    }
    if (!cardExpiryError) {
      cardExpiryIsEmpty ? setCardExpiryError(`Your card's expiration date is incomplete.`) : setCardExpiryError(false);
    }
    if (!cardCvcError) {
      cardCvcIsEmpty ? setCardCvcError(`Your card's security code is incomplete.`) : setCardCvcError(false);
    }
    formik.handleSubmit(event);
  };

  const handleCardExpiryChange = (e) => {
    e.error ? setCardExpiryError(e.error.message) : setCardExpiryError(null);
    setCardExpiryIsEmpty(e.empty);
  };

  const handleCardCvcChange = (e) => {
    e.error ? setCardCvcError(e.error.message) : setCardCvcError(null);
    setCardCvcIsEmpty(e.empty);
  };

  const handleCardNumberChange = (e) => {
    e.error ? setCardNumberError(e.error.message) : setCardNumberError(null);
    setCardNumberIsEmpty(e.empty);
  };

  return (
    <>
      <div className='img-background'>
        <img src={horseImage} alt='horse-sunset' />
        <div className='fade-bottom' />
      </div>
      <div className='info-form signup-main-container'>
        <Container component='main' maxWidth='sm' className='signup-inner-container'>
          <Box className='text-center' sx={{ mb: 3 }}>
            <img className='signup-logo' src={props.logo} alt='signup logo' width='80%' />
            <Typography component='h1' variant='h5' className='text-white text-center shadow-text'>
              Almost done!
              <br />
              Confirm your payment information
            </Typography>
            <form noValidate onSubmit={checkForm} className='format-errors'>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    autoComplete='given-name'
                    name='name'
                    InputLabelProps={{ shrink: true }}
                    required
                    fullWidth
                    id='name'
                    label='Name'
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                    autoFocus
                    variant='outlined'
                    placeholder="Cardholder's Name (as it appears on credit card)"
                  />
                </Grid>
                <Grid item xs={12} className='position-relative'>
                  <div className='card-element-subtext'>Credit Card Number *</div>
                  <CardNumberElement
                    options={cardStyle}
                    className={`card-element ${
                      cardNumberError ? 'StripeElement StripeElement--empty --error' : 'StripeElement StripeElement'
                    }`}
                    onChange={handleCardNumberChange}
                  />
                  {cardNumberError && <div className='error-formatting'>{cardNumberError}</div>}
                </Grid>
                <Grid item xs={6} className='position-relative'>
                  <div className='card-element-subtext'>Expiration Date *</div>
                  <CardExpiryElement
                    options={cardStyle}
                    className={`card-element ${
                      cardExpiryError ? 'StripeElement StripeElement--empty --error' : 'StripeElement StripeElement'
                    }`}
                    onChange={handleCardExpiryChange}
                  />
                  {cardExpiryError && <div className='error-formatting'>{cardExpiryError}</div>}
                </Grid>
                <Grid item xs={6} className='position-relative'>
                  <div className='card-element-subtext'>CVC *</div>
                  <CardCvcElement
                    options={cardStyle}
                    className={`card-element ${
                      cardCvcError ? 'StripeElement StripeElement--empty --error' : 'StripeElement StripeElement'
                    }`}
                    onChange={handleCardCvcChange}
                  />
                  {cardCvcError && <div className='error-formatting'>{cardCvcError}</div>}
                </Grid>
                <Grid item xs={12}>
                  <button type='submit' className='wild-rides-button --half-width'>
                    Finish
                  </button>
                  <div className='auth-bottom-text'>
                    <a className='text-white' onClick={() => props.onCancel(plan_id, giftData)}>
                      Back
                    </a>
                  </div>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Container>
      </div>
    </>
  );
};
export default GiftPayment;
