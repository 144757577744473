import React from 'react';

const DisplayPrices = (props) => {
  let { monthlyAmount, monthlyName, yearlyAmount, yearlyName } = props;
  if (monthlyAmount && yearlyAmount) {
    monthlyAmount = monthlyAmount.split('.');
    yearlyAmount = yearlyAmount.split('.');
  }
  return (
    <>
      {monthlyAmount && yearlyAmount && (
        <div className='price-grid'>
          <div className='price-grid__price-flex'>
            <div>
              <span>${monthlyAmount[0] + '.'}</span>
              <span>{monthlyAmount[1]}</span>
            </div>

            <div>Per {monthlyName}</div>
          </div>
          <div className='vertical-line-grid'>
            <div>
              <div className='vertical-line' />
            </div>
            <div className='text-white flex-center'>or</div>
            <div>
              <div className='vertical-line' />
            </div>
          </div>
          <div className='price-grid__price-flex'>
            <div>
              <span>${yearlyAmount[0] + '.'}</span>
              <span>{yearlyAmount[1]}</span>
            </div>

            <div>Per {yearlyName}</div>
          </div>
        </div>
      )}
    </>
  );
};
export default DisplayPrices;
