import React, { useState, useEffect } from 'react';
import MobileNav from './MobileNav';
import hamburger from '../../../assets/images/mobile-menu.png';
import closeMenuIcon from '../../../assets/images/close-x.svg';
import { apiRequest } from '../../utils/general';

const MobileMenu = (props) => {
  const { pages, isLoggedIn } = props;
  const [showMenu, setShowMenu] = useState(false);
  const hamburgerMenu = <img src={hamburger} alt='mobile hamburger menu' onClick={() => setShowMenu(!showMenu)} />;
  const closeMenuX = <img src={closeMenuIcon} alt='close menu button' onClick={() => setShowMenu(!showMenu)} />;
  const [logoUrl, setLogoUrl] = useState('');

  useEffect(() => {
    const fetchLogo = async () => {
      try {
        const logoRes = await apiRequest('users/sign-up-logo');
        setLogoUrl(logoRes.data.logo_url);
      } catch (err) {
        console.error(err);
      }
    };
    fetchLogo();
  }, []);
  const handleMenuClose = () => {
    setShowMenu(false);
  };

  return (
    <>
      <div className={`${showMenu ? 'mobile-menu-veil' : ''}`}>
        <button className='veil-area' onClick={handleMenuClose} />
        <div className={`mobile-menu ${showMenu ? 'black-background' : 'nav-background-gradient'}`}>
          {!showMenu && <div className='mobile-menu__icon'>{hamburgerMenu}</div>}
          {showMenu && <div className='mobile-menu__icon'>{closeMenuX}</div>}
          {logoUrl && (
            <div className='mobile-menu__logo'>
              <a href='/'><img src={logoUrl} alt='signup logo' /></a>
            </div>
          )}
          {showMenu && <MobileNav pages={pages} isLoggedIn={isLoggedIn} onMenuClose={handleMenuClose} />}
        </div>
      </div>
    </>
  );
};
export default MobileMenu;
