import React from 'react';
import { useNavigate } from 'react-router-dom';
import giftImage from '../../../assets/images/gifting-wild-rides-placeholder.png';

const GiftingWildRides = () => {
  const navigate = useNavigate();

  return (
    <div className='gift-page'>
      <div className='gifting-wild-rides-container'>
        <div className='text-section'>
          <div className='info-page__header'>
            <h1 style={{ textAlign: 'left' }}>The Perfect Gift</h1>
          </div>
          <p>
            A Wild Rides TV membership makes the perfect gift for someone special. Experience all WRTV has to offer with
            a gift subscription today!
          </p>
          <div>
            <button className='wild-rides-button --gift-flow' onClick={() => navigate('/gift')}>
              Buy Today
            </button>
          </div>
        </div>
        <div className='right-section'>
          <img src={giftImage} alt='gift' className='gift-img' />
        </div>
      </div>
    </div>
  );
};

export default GiftingWildRides;
