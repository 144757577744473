import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { showLoader, hideLoader } from '../../store/loaderSlice';
import { apiRequest } from '../../utils/general';

const Header = (props) => {
  const navigate = useNavigate();
  const { headerPage, series } = props;

  const [headerTitle, setHeaderTitle] = useState('');
  const [headerDescription, setHeaderDescription] = useState('');
  const [headerLogo, setHeaderLogo] = useState('');
  const [backgroundVideoUrl, setBackgroundVideoUrl] = useState('');

  const dispatch = useDispatch();
  const description = `Wild Rides TV was created for two primary reasons: To gather rich western history for generations to
  share. And to produce TV series about real cowboys and cowgirls in real life. In other words, when we
  produce an episode about All-Around Cowboy Stetson Wright, we don’t tell his story, he tells it himself.
  Wild Rides TV also diligently adheres to the Cowboy Code. And every exciting episode of every series can
  be watched by any member of the family. And all of it for only $6.99 per month.`;

  useEffect(() => {
    const loadPageData = async () => {
      dispatch(showLoader());
      try {
        if (!series) {
          const results = await apiRequest(`assets/${headerPage}/header-video`);
          if (results) {
            setBackgroundVideoUrl(results.data.video_details.video_url);
            setHeaderTitle('Wild Rides TV - In Summary');
            setHeaderDescription(description);
          }
        } else {
          setHeaderTitle(series.title);
          setHeaderDescription(series.description);
          const logoArray = series.images.filter((image) => image.title === 'logo');
          setHeaderLogo(logoArray[0]?.url);
          setBackgroundVideoUrl(series?.promoVideos[0]?.video_url);
        }
      } catch (err) {
        console.error(err);
      }
      dispatch(hideLoader());
    };

    loadPageData().catch(console.error);
  }, []);

  return (
    <>
      {!series && (
        <div className='header-section'>
          <div className='gift-page'>
            <div className='home-video-container'>
              <div className='text-section'>
                <div className='info-page__header__home'>
                  <h1 style={{ textAlign: 'left' }}>{headerTitle}</h1>
                </div>
                <p>{headerDescription}</p>
                <div>
                  <button className='wild-rides-button --gift-flow --home-section' onClick={() => navigate('/pricing')}>
                    Try 7 - Days Free Trial
                  </button>
                </div>
              </div>
              <div className='right-section --header'>
                <div className='video-background-header'>
                  {backgroundVideoUrl && (
                    <iframe
                      id='backgroundVideoIFrame'
                      className='iframe'
                      muted
                      loading='lazy'
                      playsInline
                      title='Header Background Video'
                      src={`${backgroundVideoUrl}`}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {series && (
        <div className='header-section-series'>
          <div className='video-background-header-series'>
            {backgroundVideoUrl && (
              <iframe
                frameBorder='0'
                muted
                loading='lazy'
                playsInline
                title='Header Background Video'
                src={`${backgroundVideoUrl}`}
              />
            )}
          </div>

          <div className='header-overlay'>
            <div className='header-title-box'>
              {headerLogo && <img src={headerLogo} alt='header logo' className='header-logo' />}
              <h2>{headerTitle}</h2>
              <p>{headerDescription}</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
