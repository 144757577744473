import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import horseImage from '../../../assets/images/gift-background.png';
import { useFormik } from 'formik';
const GiftInfo = ({ onInfoSubmit, planId, onCancel, logo, giftData }) => {
  const [yourNameError, setYourNameError] = useState(null);
  const [yourEmailError, setYourEmailError] = useState(null);
  const [recipientNameError, setRecipientNameError] = useState(null);
  const [recipientEmailError, setRecipientEmailError] = useState(null);
  const [giftMessageError, setGiftMessageError] = useState(null);

  const validationSchema = yup.object({
    yourName: yup.string().required('Your name is required'),
    yourEmail: yup.string().email('Enter a valid email address').required('Your email address is required'),
    recipientName: yup.string().required('Recipient name is required'),
    recipientEmail: yup.string().email('Enter a valid email address').required('Recipient email address is required'),
    giftMessage: yup.string().required('A message is required'),
  });

  const formik = useFormik({
    initialValues: {
      yourName: '',
      yourEmail: '',
      recipientName: '',
      recipientEmail: '',
      giftMessage: '',
    },
    validationSchema,
    onSubmit: (values) => {
      onInfoSubmit(values, planId);
    },
  });

  useEffect(() => {
    if (giftData) {
      const { yourName, yourEmail, recipientName, recipientEmail, giftMessage } = giftData;
      formik.setValues({
        yourName,
        yourEmail,
        recipientName,
        recipientEmail,
        giftMessage,
      });
    }
  }, []);

  const checkForm = (e) => {
    const { errors, values } = formik;
    const fields = [
      {
        name: 'yourName',
        error: errors.yourName,
        message: 'Your name is required',
        setError: setYourNameError,
      },
      {
        name: 'yourEmail',
        error: errors.yourEmail,
        message: 'Your email address is required',
        setError: setYourEmailError,
      },
      {
        name: 'recipientName',
        error: errors.recipientName,
        message: 'Recipient name is required',
        setError: setRecipientNameError,
      },
      {
        name: 'recipientEmail',
        error: errors.recipientEmail,
        message: 'Recipient email address is required',
        setError: setRecipientEmailError,
      },
      {
        name: 'giftMessage',
        error: errors.giftMessage,
        message: 'A gift message is required',
        setError: setGiftMessageError,
      },
    ];

    fields.forEach(({ name, error, message, setError }) => {
      if (!values[name]) {
        setError(message);
      } else if (error) {
        setError(error);
      } else {
        setError(null);
      }
    });
    formik.handleSubmit(e);
  };

  return (
    <>
      <div className='img-background'>
        <img src={horseImage} alt='horse-sunset' />
        <div className='fade-bottom' />
      </div>
      <div className='gift-info-container'>
        <div className='flex-center'>
          <img className='signup-logo' src={logo} alt='signup logo' width='80%' />
        </div>
        <h1>Great! Enter the following information.</h1>
        <div className='gift-info-grid'>
          <h2 className='your-info-header'>Your Information</h2>
          <div className='textfield-container your-name'>
            <input
              className='textfield-input'
              name='yourName'
              value={formik.values.yourName}
              onChange={(e) => {
                setYourNameError(null);
                formik.handleChange(e);
              }}
              type='text'
              placeholder='Jane Doe'
            />
            <label className='textfield-label'>Your Name</label>
            {yourNameError && <div className='gift-error-formatting'>{yourNameError}</div>}
          </div>

          <div className='textfield-container your-email'>
            <input
              className='textfield-input'
              name='yourEmail'
              value={formik.values.yourEmail}
              onChange={(e) => {
                setYourEmailError(null);
                formik.handleChange(e);
              }}
              type='text'
              placeholder='jane.doe@gmail.com'
            />
            <label className='textfield-label'>Your Email</label>
            {yourEmailError && <div className='gift-error-formatting'>{yourEmailError}</div>}
          </div>
          <h2 className='recipient-info-header'>Your Recipient's Information</h2>

          <div className='textfield-container recipient-name'>
            <input
              className='textfield-input'
              name='recipientName'
              value={formik.values.recipientName}
              onChange={(e) => {
                setRecipientNameError(null);
                formik.handleChange(e);
              }}
              type='text'
              placeholder='John Smith'
            />
            <label className='textfield-label'>Your Recipient's Name</label>
            {recipientNameError && <div className='gift-error-formatting'>{recipientNameError}</div>}
          </div>

          <div className='textfield-container recipient-email'>
            <input
              className='textfield-input'
              type='text'
              name='recipientEmail'
              value={formik.values.recipientEmail}
              onChange={(e) => {
                setRecipientEmailError(null);
                formik.handleChange(e);
              }}
              placeholder='john.smith@gmail.com'
            />
            <label className='textfield-label'>Your Recipient's Email</label>
            {recipientEmailError && <div className='gift-error-formatting'>{recipientEmailError}</div>}
          </div>

          <div className='textfield-container gift-message'>
            <textarea
              rows='5'
              className='gift-message-input'
              name='giftMessage'
              value={formik.values.giftMessage}
              onChange={(e) => {
                setGiftMessageError(null);
                formik.handleChange(e);
              }}
              type='text'
              placeholder="You are a great friend! That's why I'm giving you Wild Rides TV!"
            />
            <label className='textfield-label'>Gift Message</label>
            {giftMessageError && <div className='gift-error-formatting --gift-message-mod'>{giftMessageError}</div>}
          </div>
          {/* </div> */}
        </div>
        <button type='submit' className='wild-rides-button --gift-flow' onClick={checkForm}>
          Next
        </button>
        <div className='auth-bottom-text text-white'>
          <a onClick={onCancel}>Back</a>
        </div>
      </div>
    </>
  );
};

export default GiftInfo;
