import { createSlice } from '@reduxjs/toolkit';

// TODO: Fix this to not have loader in initial state -GL
const loaderSlice = createSlice({
  name: 'loader',
  initialState: {
    loader: {
      show: false,
      message: '',
    },
  },
  reducers: {
    showLoader: (state, action) => {
      const { payload } = action;
      state.loader.show = true;
      state.loader.message = payload;
    },
    hideLoader: (state) => {
      state.loader.show = false;
      state.loader.message = '';
    },
  },
});

export const { showLoader, hideLoader } = loaderSlice.actions;
const { reducer } = loaderSlice;
export default reducer;
