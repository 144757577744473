import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import VideoBackground from '../../common/VideoBackground';
import { useFormik } from 'formik';
import { showLoader, hideLoader } from '../../../store/loaderSlice';
import { ERROR_500 } from '../../../utils/errors';
import { Link, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import { apiRequest } from '../../../utils/general';
import { createAlert } from '../../../store/alertSlice';
import { useDispatch } from 'react-redux';
import { login } from '../../../services/auth.service';
import { TextField } from '@material-ui/core';
import { checkForNotifications } from '../../../utils/userHelper';
 
const Signin = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const initialValues = {
    email: '',
    password: '',
  };
  const [backgroundVideoUrl, setBackgroundVideoUrl] = useState('');

  useEffect(() => {
    const fetchBackgroundVideo = async () => {
      try {
        const backgroundVideoRes = await apiRequest('users/sign-up-background');
        setBackgroundVideoUrl(backgroundVideoRes.data.video_url);
      } catch (err) {
        console.error(err);
      }
    };
    fetchBackgroundVideo();
  }, []);

  const handleLogin = (formValue) => {
    dispatch(showLoader());
    const data = formValue;
    login(data, dispatch)
      .then((loginResponse) => {
        const { subscription_expired, cards, payment_failed } = loginResponse;
        formik.resetForm();
        dispatch(hideLoader());
        checkForNotifications(payment_failed, cards);
        handleLoginSuccess(subscription_expired);
      })
      .catch((e) => {
        dispatch(hideLoader());
        console.error(e);
        if (typeof e === 'string') {
          e = JSON.parse(e);
        }
        if (e.status == 401) {
          dispatch(createAlert({ message: `${e.data.error}`, type: 'error' }));
        } else {
          dispatch(createAlert({ message: ERROR_500, type: 'error' }));
        }
      });
  };

  const handleLoginSuccess = (subscriptionExpired) => {
    if (subscriptionExpired) {
      navigate('/user/subscribe');
    } else if (location.state?.from) {
      navigate(location.state.from);
    } else {
      navigate('/');
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      email: Yup.string('Enter your email').email('Enter a valid email').required('Email is required'),
      password: Yup.string('Enter your password').required('Password is required'),
    }),
    onSubmit: (values) => {
      handleLogin(values);
    },
  });

  return (
    <>
      <VideoBackground videoSrc={backgroundVideoUrl} />
      <div className='info-page'>
        <div className='info-page__header'>
          <h1>Login</h1>
        </div>
        <form noValidate onSubmit={formik.handleSubmit} className='info-form format-errors'>
          <TextField
            autoComplete='email'
            name='email'
            required
            fullWidth
            id='email'
            label='Email'
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            autoFocus
            variant='outlined'
          />
          <TextField
            margin='normal'
            required
            fullWidth
            name='password'
            label='Password'
            type='password'
            id='password'
            autoComplete='current-password'
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            variant='outlined'
          />
          <div className='auth-container__mobile-flex'>
            <Link className='flex-center' to='/forgot-password' variant='body2'>
              Forgot Password?
            </Link>
          </div>
          <div className='text-center mt-5'>
            <button type='submit' className='wild-rides-button --half-width'>
              Login
            </button>
          </div>
          <div className='auth-bottom-text'>
            <span>
              New to Wild Rides Television? &nbsp;
              <Link to='/sign-up' variant='body2'>
                Sign Up Now
              </Link>
            </span>
          </div>
        </form>
      </div>
    </>
  );
};

export default Signin;
