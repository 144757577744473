import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@mui/material/Grid';
import SignupForm from './SignupForm';
import { apiRequest } from '../../utils/general';

export default function ComingSoonFooter(props) {
  const [logoUrl, setLogoUrl] = useState('');

  useEffect(() => {
    apiRequest('assets/footer-assets')
      .then((res) => {
        let data = res.data;
        setLogoUrl(data.logo_url);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return (
    <Box className="coming-soon-footer-div" component="footer">
      <div className="shadowVeil" />
      <Container maxWidth="lg">
        <Grid container spacing={2} direction="column" justifyContent="center" alignItems="center">
          <Grid item>
            <img className="coming-soon-footer-logo" alt="footer logo" src={logoUrl} />
          </Grid>
          <Grid item>
            <SignupForm idAddon="footer" />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
