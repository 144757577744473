import { createSlice } from '@reduxjs/toolkit';

const notification = createSlice({
  name: 'notification',
  initialState: {
    notifications: [],
    priority: '',
  },
  reducers: {
    createNotification: (state, action) => {
      const { payload } = action;
      state.notifications.push(payload);
    },
    clearNotifications: (state, action) => {
      state.notifications = [];
      state.priority = '';
    },
  },
});

export const { createNotification, clearNotifications } = notification.actions;
const { reducer } = notification;
export default reducer;
