import React, { useState, useEffect } from "react";

const ProgressiveImg = (props) => {
  // TODO: these names are turrible - GL
  const { placeholderSrc, src, width, height } = props;
  const [imgSrc, setImgSrc] = useState(placeholderSrc || src);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      setImgSrc(src);
    };
  }, [src]);

  const customClass = placeholderSrc && imgSrc && imgSrc === src ? "--loaded" : "";

  return placeholderSrc ? (
    <img
      src={imgSrc ?? placeholderSrc}
      alt={props.alt || "video background image"}
      loading="lazy"
      width={width}
      height={height}
      className={`progressive-image --banner-background ${customClass}`}
    />
  ) : null;
};
export default ProgressiveImg;
