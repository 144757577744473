import React from 'react';
import MobileUserMenu from './MobileUserMenu';

const MobileNav = (props) => {
  const { pages, isLoggedIn, onMenuClose } = props;
  return (
    <nav className='mobile-menu__nav black-background grid-item-full-width'>
      <ul>
        {pages.map((page) => (
          <li key={page.label}>
            <a href={page.url} className={window.location.pathname == page.url ? 'text-orange' : 'text-white'}>
              {page.label}
            </a>
          </li>
        ))}
        {!isLoggedIn && (
          <li>
            <a href='/sign-in' className='text-white'>
              Login
            </a>
          </li>
        )}
        {isLoggedIn && <MobileUserMenu onMenuClose={onMenuClose} />}
      </ul>
    </nav>
  );
};
export default MobileNav;
