import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PaymentModal from './PaymentModal';
import swal from 'sweetalert';
import { apiRequest } from '../../utils/general';
import { useDispatch } from 'react-redux';
import { createAlert } from '../../store/alertSlice';
import { showLoader, hideLoader } from '../../store/loaderSlice';

const PurchaseVideoWrapper = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const videoId = params.id;
  const [video, setVideo] = useState({});

  useEffect(() => {
    apiRequest(`videos/${videoId}`)
      .then((res) => {
        setVideo(res.data.data.video);
      })
      .catch((err) => {
        dispatch(hideLoader());
        if (err.response.status == 404) {
          dispatch(createAlert({ message: 'Video does not exist.', type: 'error' }));
        } else {
          dispatch(createAlert({ message: ERROR_500, type: 'error' }));
        }
      });
  }, videoId);

  const onModalClose = () => {
    navigate(-1);
  };

  const initiatePayment = (cardId) => {
    if (video.price <= 0) {
      dispatch(
        createAlert({
          message: 'This video does not have a price.',
          type: 'error',
        })
      );
      return;
    }

    if (cardId && videoId) {
      dispatch(showLoader('Processing..'));
      apiRequest(`videos/${videoId}/subscribe`, 'post', {
        card_id: cardId,
      })
        .then((res) => {
          dispatch(hideLoader());
          swal({
            title: 'Purchase Successful',
            text: "You can always find the purchased video under 'My Purchases' section.",
            icon: 'success',
            button: 'Continue!',
          });
          navigate(-1);
        })
        .catch((err) => {
          dispatch(hideLoader());
          if (err.response?.status && [422, 401, 404].includes(err.response.status)) {
            dispatch(createAlert({ message: err.response.data.error, type: 'error' }));
          } else {
            dispatch(createAlert({ message: ERROR_500, type: 'error' }));
          }
        });
    }
  };

  return (
    <PaymentModal
      isModalOpened={true}
      onModalClose={onModalClose}
      onSubmitPayment={initiatePayment}
      title='Purchase video'
      price={video.price}
    />
  );
};

export default PurchaseVideoWrapper;
